import React from 'react'
import './stepSecond.css'

const StepSecond = () => {
  return (
    <div className="form-header flex gap-3 my-4 text-xs text-center">
            <span className="second-stepIndicator1 flex-1 pb-8 relative text-[13px]">Event Info</span>
            <span className="second-stepIndicator2 flex-1 pb-8 relative text-[13px]">Create Ticket</span>
            <span className="second-stepIndicator3 flex-1 pb-8 relative text-[13px]">Additional info</span>
        </div>
  )
}

export default StepSecond