import React, { useContext, useEffect, useState } from "react";
import ChartCard from "../../Components/ChartCard";
import Ad from "../../Assets/AD.jpeg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import NothingHere from "../../Assets/nothing_here.json"
import AudioPlayer2 from "pages/Stereo/AudioPlayer2/AudioPlayer";
import { MusicPlayerContext } from "pages/Stereo/context/MusicPlayerContext";

export default function Charts() {
  const [activeTab, setActiveTab] = useState(0);
  const [topAlbums, setTopAlbums] = useState([])
  const [quickpicks, setQuickPicks] = useState([])
  const [bighit, setBigHit] = useState([])
  const [modalOpen1, setModalOpen1] = useState(false)
  const [clickedIndex1, setClickedIndex1] = useState(null); // Initialize with null
 const [currentSong, setCurrentSong] = useState(quickpicks[clickedIndex1])
//  const [modalOpen, setModalOpen] = useState(false)
  const [clickedIndex, setClickedIndex] = useState(null); // Initialize with null
//  const [currentSong1, setCurrentSong1] = useState(Afrobeats[clickedIndex])
 const [minimized, setMinimized] = useState(false)
 const [AlbumSongs, setAlbumSongs] = useState([]);
 const [Albums, setAlbums] = useState([])
 const [mode, setMode] = useState(false)
  const authToken = localStorage.getItem("authToken")
  const navigation = useNavigate()
  const { tracks, setTracks, index, setIndex, modalOpen, setModalOpen, minimized1, setMinimized1 } = useContext(MusicPlayerContext)

  const handleItemClick = (index, tracks) => {
   console.log('Clicked index:', index);
   // setCurrentSong(quickpicks[clickedIndex]);
   setIndex(index); // Set the clicked index to state
     setModalOpen(!modalOpen)
     setTracks(tracks)
 };

  const handleItemClick1 = (index) => {
    console.log('Clicked index:', index);
    // setCurrentSong(quickpicks[clickedIndex]);
    setClickedIndex(index); // Set the clicked index to state
  };
  

  const GetTopAlbums = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/albums/top-album/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setTopAlbums(res?.data?.data);
        console.log(topAlbums + "topAlbums state===");
        console.log(JSON.stringify(res.data) + "topAlbums====");
      }).catch(e=>console.log(e));
  };

  const GetTrendingSongs = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/songs/trending/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setQuickPicks(res?.data?.data);
        console.log(quickpicks + "quickpicks state===");
        console.log(JSON.stringify(res.data) + "quickpicks====");
      }).catch(e=>console.log(e));
  };

  const handleAlbumSongsList = async(id) => {
    // setAlbumId(id)
    await axios.get(`https://api.2geda.net/api/stereo/artist/albums/${id}/songs/`,  {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "X-CSRFToken": process.env.REACT_TOKEN,
      },
    }).then((res)=>{
      setAlbumSongs(res?.data?.data)
      setTracks(res?.data?.data)
      setModalOpen(!modalOpen)
      console.log(res?.data?.data, "album songs====")
    }).catch(e => console.log(e))
  }

  useEffect(()=>{
    GetTopAlbums()
    GetTrendingSongs()
    
  },[])
  return (
    <div>
      <div className="mx-3">
        <span className="text-xl font-medium">Popular Albums</span>
      </div>
      <div
        className={topAlbums.length>0?"flex gap-3 mx-3 mt-2 overflow-x-scroll align-middle items-center":null}
        style={{ scrollbarWidth: "none" }}>
          {topAlbums?.length>0?topAlbums?.map(res=>{
                  return (
                    <ChartCard
                    onClick={()=>handleAlbumSongsList(res.id)}
                    modalOpen2={mode}
                    setModalOpen2={setMode}

                    title={res.name}
                    // no={"100"}
                    img={res.cover_image}
                  />
                  )
                }):<div className="flex justify-center items-center"><Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
        {/* <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        />
        <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        />
        <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        />
        <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        />
        <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        /> */}
      </div>
      {/* <div className="mx-3 mt-4">
        <span className="text-xl font-medium">Popular Songs</span>
      </div>
      <div
        className={quickpicks.length>0?"flex mt-2 gap-3 mx-3 overflow-x-scroll align-middle items-center":null}
        style={{ scrollbarWidth: "none" }}>
          {quickpicks?.length>0?quickpicks?.map(res=>{
                  return (
                    <ChartCard
          title={res.title}
          // no={"100"}
          img={res.cover_image?`https://development.2geda.net${res.cover_image}`:null} artist={res.artist.artist_name} audio={res.audio_file?`https://development.2geda.net${res.audio_file}`:null}
        />
                  // <PicksCard title={res.title} img={res.cover_image?`https://development.2geda.net${res.cover_image}`:null} artist={res.artist.artist_name} audio={res.audio_file?`https://development.2geda.net${res.audio_file}`:null} id={res.id}/>
                  )
                }):<div className="flex justify-center items-center"><Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>} */}
        {/* <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        />
        <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        />
        <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        />
        <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        />
        <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        /> */}
      {/* </div> */}
      {/* ad */}
      <div className="mt-4" style={{}}>
        <img
          src={Ad}
          alt="Advertisement"
          style={{
            height: "80px",
            objectFit: "cover",
            width: "100%",
          }}
        />
      </div>
      <div className="mx-3 mt-4">
        <span className="text-xl font-medium">Popular Songs</span>
      </div>
      <div
        className={quickpicks.length>0?"flex mt-2 gap-3 mx-3 overflow-x-scroll align-middle items-center":null}
        style={{ scrollbarWidth: "none" }}>
          {quickpicks?.length>0?quickpicks?.map((res, index)=>{
                  return (
                    <ChartCard
          title={res.title}
          modalOpen2={modalOpen1} setModalOpen2={setModalOpen1}
          id={res.id}
          onClick={()=>handleItemClick(index, quickpicks)}
          // no={"100"}
          img={res.cover_image} 
          artist={res.artist.artist_name} 
        />
                  // <PicksCard title={res.title} img={res.cover_image?`https://development.2geda.net${res.cover_image}`:null} artist={res.artist.artist_name} audio={res.audio_file?`https://development.2geda.net${res.audio_file}`:null} id={res.id}/>
                  )
                }):<div className="flex justify-center items-center"><Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
      {/* <div
        className="flex mt-4 gap-3 mx-3 overflow-x-scroll align-middle items-center"
        style={{ scrollbarWidth: "none" }}>
        <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        />
        <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        />
        <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        />
        <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        />
        <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        />
      </div>
      <div
        className="flex mt-4 gap-3 mx-3 overflow-x-scroll align-middle items-center"
        style={{ scrollbarWidth: "none" }}>
        <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        />
        <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        />
        <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        />
        <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        />
        <ChartCard
          title={"Top 100 new release"}
          no={"100"}
          img={require("../../Assets/Component 14.png")}
        />*/}
      </div> 
      {/* {modalOpen1 && (
          <div
            id="modelConfirm"
            className={!minimized ? "fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4":""}
            // className={`flex items-center justify-center fixed inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full ${
            //   minimized ? "px-4" : ""
            // }`}
            >
            <div 
            className={!minimized ? "relative top-40 mx-auto shadow-xl bg-white max-w-[100%] lg:max-w-[30%] md:max-w-[30%] xl:max-w-[30%] rounded-xl overflow-x-hidden": "fixed bottom-0 lg:left-[30%] lg:right-[30%] xl:left-[30%] xl:right-[30%] left-0 right-0 z-50 w-full lg:w-[50%] xl:w-[50%] h-60 bg-white border-t border-gray-200"}
            // className={`${
            //   minimized ? "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" : "relative top-40 mx-auto"
            // } shadow-xl bg-white max-w-[100%] lg:max-w-[30%] md:max-w-[90%] xl:max-w-[30%] rounded-xl overflow-x-hidden`}
            >
             <main className="py-10 my-10"> */}
                {/* <MusicPlayer playerIndex={clickedIndex} songs={quickpicks} setSongs={setQuickPicks} currentSong={currentSong} setCurrentSong={setCurrentSong} src={currentSong.url} ref={audioElem} onTimeUpdate={onPlaying}/> */}
                {/* <audio ref={audioElem} onTimeUpdate={onPlaying}>
                <source src={currentSong?.audio_file?`https://development.2geda.net${currentSong?.audio_file}`:""} type="audio/mp3" />
                </audio> */}
                {/* {modalOpen1 && <AudioPlayer2 setModalOpen1={setModalOpen1} modalOpen={modalOpen1} minimized1={minimized} setMinimized1={setMinimized} tracks={quickpicks} index={clickedIndex1}/>} */}
      {/* <Player songs={quickpicks} setSongs={setSongs} isplaying={isplaying} setisplaying={setisplaying} audioElem={audioElem} currentSong={currentSong} setCurrentSong={setCurrentSong} /> */}
              {/* </main>
            </div>
          </div>
        )} */}
        {/* </div> */}

{/* {modalOpen && (
          <div
            id="modelConfirm"
            className={!minimized ? "fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4":""}>
            <div className={!minimized ? "relative top-40 mx-auto shadow-xl bg-white max-w-[100%] lg:max-w-[30%] md:max-w-[30%] xl:max-w-[30%] rounded-xl overflow-x-hidden": "fixed bottom-0 lg:left-[30%] lg:right-[30%] xl:left-[30%] xl:right-[30%] left-0 right-0 z-50 w-full lg:w-[50%] xl:w-[50%] h-60 bg-white border-t border-gray-200"}>
             <main className="py-10 my-10"> */}
                {/* <MusicPlayer playerIndex={clickedIndex} songs={quickpicks} setSongs={setQuickPicks} currentSong={currentSong} setCurrentSong={setCurrentSong} src={currentSong.url} ref={audioElem} onTimeUpdate={onPlaying}/> */}
                {/* <audio ref={audioElem} onTimeUpdate={onPlaying}>
                <source src={currentSong?.audio_file?`https://development.2geda.net${currentSong?.audio_file}`:""} type="audio/mp3" />
                </audio> */}
                {/* {modalOpen && <AudioPlayer2 minimized1={minimized} setMinimized1={setMinimized} setModalOpen1={setModalOpen} modalOpen={modalOpen} tracks={AlbumSongs} index={0}/>} */}
      {/* <Player songs={quickpicks} setSongs={setSongs} isplaying={isplaying} setisplaying={setisplaying} audioElem={audioElem} currentSong={currentSong} setCurrentSong={setCurrentSong} /> */}
              {/* </main>
            </div>
          </div>
        )} */}
    </div>
  );
}
