
const PaymentModalOne = () => {
  

  return <div className="report-summary-container">

  </div>;
};

export default PaymentModalOne;
