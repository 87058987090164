import { useQuery } from "@tanstack/react-query";
import { getSubscribers} from "api/services/tv";

export const useGetSubscribers = (id) => {
  const {
    isLoading: isSubscribersLoading,
    data: subscribers,
    error: subscribersError,
  } = useQuery({
    queryKey: ["subscribers", id],
    queryFn: () => getSubscribers(id),
    refetchOnWindowFocus: false,
    refetchOnMount: true, 
    refetchOnReconnect: false,
  });

  return {
    isSubscribersLoading,
    subscribers,
    subscribersError,
  };
};
