import { useEffect, useRef, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import TvCardFull from "./TvCardFull";
import TvRightSide from "./TvRightSide";
import Spin from "components/Spin/Spin";
import { useGetAllMovies } from "Hooks/tv/useGetAllMovies";
import { capWord } from "./Tv";

// Function to filter movies by tag name
const filterByTag = (movies, tagName) => {
  return movies?.filter((movie) =>
    movie.tags.some((tag) => tag.name === tagName)
  );
};

const TaggedMovies = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMovies, setFilteredMovies] = useState([]);
  const navigate = useNavigate();
  const { category } = useParams();
  const { allMovies, isAllMoviesLoading } = useGetAllMovies();
  const pageRef = useRef(null);

  // Effect to filter movies when allMovies or category changes
  useEffect(() => {
    if (allMovies?.data?.results) {
      const movies = filterByTag(allMovies.data.results, category);
      setFilteredMovies(movies);
    }
  }, [allMovies, category]);

  // Effect to scroll to the top of the page when the component mounts
  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  // Effect to filter movies based on the search term
  useEffect(() => {
    if (searchTerm === "") {
      setFilteredMovies(filterByTag(allMovies?.data?.results, category));
    } else {
      setFilteredMovies((prevMovies) =>
        filterByTag(allMovies?.data?.results, category).filter((movie) =>
          movie.title.toLowerCase().includes(searchTerm)
        )
      );
    }
  }, [searchTerm, allMovies, category]);

  // Handle input change for search
  const handleInputChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
  };

  // Navigate back to the home page
  const goToHome = () => {
    navigate("/tv");
  };

  return (
    <div className="lg:grid lg:grid-cols-12 gap-3 min-h-screen" ref={pageRef}>
      <div className="lg:col-span-8 bg-white mt-2 px-4">
        {isAllMoviesLoading ? (
          <Spin />
        ) : (
          <>
            <div className="flex w-full items-center gap-4 mt-4 px-2">
              <FaArrowLeftLong onClick={goToHome} className="text-[18px] cursor-pointer" />
              <h2 className="font-semibold text-[17px] mt-2">{capWord(category)}</h2>
            </div>
            <div className="w-full border-none bg-neutral-100 rounded flex items-center px-4 mt-4">
              <BiSearch className="text-black text-[16px]" />
              <input
                type="text"
                className="text-[14px] border-none outline-none w-full"
                placeholder="Search movie"
                value={searchTerm}
                onChange={handleInputChange}
              />
            </div>
            <div className="ticket-full-box mt-4">
              {filteredMovies.map((item, index) => (
                <TvCardFull data={item} key={index} />
              ))}
            </div>
          </>
        )}
      </div>
      <div className="lg:col-span-4 hidden lg:block bg-white mt-2 px-2 py-2">
        <TvRightSide />
      </div>
    </div>
  );
};

export default TaggedMovies;
