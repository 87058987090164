import React, { useState } from 'react'
import DashMessage from '../../components/Dashboard/DasMess'
import FirstSide from '../../components/Dashboard/FirstSide'
import Follower from '../../components/Dashboard/Follower'
import SelectCategory from '../../components/Dashboard/SelectCategory'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import './style.css'
import LifestyleStatus from '../../components/Home/Lifestyle-status/LifestyleStatus'
import FeedLocations from '../../components/Home/Feeds/Locations/Locations'
import Feedvideos from '../../components/Home/Feeds/Videos/Feedvideos'
import Feedimages from '../../components/Home/Feeds/Images/Feedimages'
import Feeds from 'components/Home/Feeds/All-feeds/Feeds'
import FeedsMusic from 'components/Home/Feeds/Music/FeedsMusic'
import Feedfiles from 'components/Home/Feeds/Files/Feedfiles'
import FeedsVoice from 'components/Home/Feeds/voice-notes/FeedsVoice'
import JobListing from 'components/Home/Feeds/Jobs/JobListing'
import { useNavigate } from 'react-router-dom'
import { useProfile } from 'Hooks/profile/useProfile'
import ProfileAds from 'components/ProfileComponents/ProfileAds'

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

const Home = ({ allMedia }) => {
  const [activeTab, setActiveTab] = useState(0)
  const { profileData, isProfileDataLoading } = useProfile()
  const navigate = useNavigate()

  const handleChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  return (
    <div
      //  className='main-containe !max-w-[99%]'
      className='lg:grid lg:grid-cols-12'
    >
      <div
        // className='left-side-container'
        className='lg:col-span-7 bg-white lg:overflow-y-auto lg:max-h-screen'
      >
        <FirstSide />
        <img
          src='images/jumia.png'
          alt=''
          className='ads-img w-full px-12 lg:px-0'
        />
        <LifestyleStatus />
        <Box className='feeds-tabs-main-container px-12 lg:px-0'>
          <Box
            sx={{
              //   maxWidth: { xs: 320, sm: 580 },
              bgcolor: 'background.paper',
              alignItems: 'center',
            }}
            className=' w-full md:w-full lg:max-w-[636px] '
          >
            <Tabs
              value={activeTab}
              onChange={handleChange}
              variant='scrollable'
              scrollButtons='auto'
              className='custom-tabs'
              aria-label='scrollable tabs'
            >
              <Tab label='All posts' />
              <Tab label='Images' />
              <Tab label='Videos' />
              <Tab label='Voice notes' />
              <Tab label='Location' />
              <Tab label='Music' />
              <Tab label='Files' />
              <Tab label='Jobs' />
            </Tabs>
          </Box>
          <CustomTabPanel value={activeTab} index={0}>
            <Feeds />
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={1}>
            <Feedimages />
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={2}>
            <Feedvideos />
          </CustomTabPanel>

          <CustomTabPanel value={activeTab} index={3}>
            <FeedsVoice />
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={4}>
            <FeedLocations />
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={5}>
            <FeedsMusic />
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={6}>
            <Feedfiles />
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={7}>
            <JobListing />
          </CustomTabPanel>
        </Box>
      </div>

      <div className='lg:col-span-2 hidden lg:block bg-white mx-3 lg:h-screen'>
        <ProfileAds />
      </div>

      <div className='lg:col-span-3 hidden lg:block lg:h-screen'>
        <Follower />
        <div className=''>
          <DashMessage />
        </div>
      </div>
    </div>
  )
}

export default Home
