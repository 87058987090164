import React, { useEffect, useState } from "react";
import CardImg from "../Assets/Image2.jpeg";
import Indicator from "../Assets/audioIndicator.svg"
import axios from "axios";

export default function TopAlbumCard({ name, artist, img, audio, id, activePlayer, setActivePlayer, onClick, setModalOpen }) {
  
  return (
    <div
    // onClick={()=>togglePlay()}
    onClick={onClick}
      style={{ minWidth: 168, minHeight: 192, maxWidth: 168, maxHeight: 192 }}>
      <div width={"100%"} className="relative" style={{ minHeight: 150, maxHeight: 150 }}>
        <img
          src={img ? img : CardImg}
          width={"100%"}
          style={{ minHeight: 150, maxHeight: 150 }}
          className="rounded-md"
        />
        <img src={Indicator} className="w-[44px] h-[22px] absolute top-[50%] left-[40%]"/>
      </div>
      <main className="flex flex-col justify-center items-center">
        <label style={{fontSize:"16px", fontWeight:"400", color:"black"}}>
          {name ? name : "Unknown Album"}
        </label>
        <label style={{fontSize:"12px", fontWeight:"400", color:"#403F3F"}}>
          {artist ? artist : "Unknown Artist"}
        </label>
      </main>
    </div>
  );
}
