import React from "react";
import { BiPause, BiPlay } from "react-icons/bi";
import { GrNext, GrPrevious } from "react-icons/gr";
import "./styles.css";
import "../Components/player.css"
import "../Components/style.css"
import { IoPauseOutline, IoPlayBackOutline, IoPlayForwardOutline, IoPlayOutline } from "react-icons/io5";
import { HiOutlineFastForward, HiOutlineRewind } from "react-icons/hi";
import { HiOutlinePause, HiOutlinePlay } from "react-icons/hi2";
import { ImLoop, ImShuffle } from "react-icons/im";
// import { ReactComponent as Play } from "./assets/play.svg";
// import { ReactComponent as Pause } from "./assets/pause.svg";
// import { ReactComponent as Next } from "./assets/next.svg";
// import { ReactComponent as Prev } from "./assets/prev.svg";

const AudioControls = ({
  isPlaying,
  onPlayPauseClick,
  onPrevClick,
  onNextClick,
  minimized,
  loopfunc
}) => (
  <div className="audio-controls">
    <button type="button"
      className="loop"
      aria-label="loop" onClick={loopfunc}><ImLoop size={14} color={minimized?"black": "white"} /></button>
    <button
      type="button"
      className="prev"
      aria-label="Previous"
      onClick={onPrevClick}
    >
      {/* <GrPrevious /> */}
      {/* <IoPlayBackOutline /> */}
      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path d="M9.195 18.44c1.25.714 2.805-.189 2.805-1.629v-2.34l6.945 3.968c1.25.715 2.805-.188 2.805-1.628V8.69c0-1.44-1.555-2.343-2.805-1.628L12 11.029v-2.34c0-1.44-1.555-2.343-2.805-1.628l-7.108 4.061c-1.26.72-1.26 2.536 0 3.256l7.108 4.061Z" />
</svg> */}
<HiOutlineRewind color={minimized?"black": "white"} />

    </button>
    {isPlaying ? (
      <button
        type="button"
        // style={{color: "black"}}
        className="pause"
        onClick={() => onPlayPauseClick(false)}
        aria-label="Pause"
      >
        {/* <BiPlay color="black" size={40} /> */}
        {/* <IoPauseOutline color="black" size={40} /> */}
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" class="w-6 h-6">
  <path fill-rule="evenodd" d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z" clip-rule="evenodd" />
</svg> */}
<HiOutlinePause color={!minimized?"white": "black"} size={40} />


      </button>
    ) : (
      <button
        type="button"
        className="play"
        onClick={() => onPlayPauseClick(true)}
        aria-label="Play"
      >
        {/* <BiPause color="black" size={40} /> */}
        {/* <IoPlayOutline color="black" size={40} /> */}
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path fill-rule="evenodd" d="M6.75 5.25a.75.75 0 0 1 .75-.75H9a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75V5.25Zm7.5 0A.75.75 0 0 1 15 4.5h1.5a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H15a.75.75 0 0 1-.75-.75V5.25Z" clip-rule="evenodd" />
</svg> */}
<HiOutlinePlay color={!minimized?"white": "black"} size={40} />

      </button>
    )}
    <button
      type="button"
      className="next"
      aria-label="Next"
      onClick={onNextClick}
    >
      {/* <GrNext /> */}
      {/* <IoPlayForwardOutline /> */}
      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path d="M5.055 7.06C3.805 6.347 2.25 7.25 2.25 8.69v8.122c0 1.44 1.555 2.343 2.805 1.628L12 14.471v2.34c0 1.44 1.555 2.343 2.805 1.628l7.108-4.061c1.26-.72 1.26-2.536 0-3.256l-7.108-4.061C13.555 6.346 12 7.249 12 8.689v2.34L5.055 7.061Z" />
</svg> */}
<HiOutlineFastForward color={!minimized?"white": "black"} />

    </button>
    <button type="button"
      className="shuf"
      aria-label="shuf">
      
    <ImShuffle size={15} color={!minimized?"white": "black"} />
    </button>
  </div>
);

export default AudioControls;
