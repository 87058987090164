import EventCard from './EventCard'
import { useNavigate } from 'react-router-dom'

const AllTickets = ({ allEventsData }) => {
  const navigate = useNavigate()

  const goToAllTickets = () => {
    navigate('/ticket/all-tickets')
  }

  return (
    <div className=''>
      <div className='flex w-full items-center justify-between py- mt-6'>
        <h2 className='font-semibold text-[17px]'>All events</h2>
        {allEventsData?.length ? (
          <button
            className='text-[13px] mt-2 border-none border-b text-[#FF8A15]'
            onClick={goToAllTickets}
          >
            View all
          </button>
        ) : null}
      </div>
      <div className='product-card-row'>
        {!allEventsData?.length ? (
          <p className='text-[13px] mt-2 w-fit cursor-auto text-[#FF8A15]'>
            No tickets to display
          </p>
        ) : (
          <EventCard data={allEventsData} />
        )}
      </div>
    </div>
  )
}

export default AllTickets
