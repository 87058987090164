import React from 'react'
import { FaMapMarkerAlt, FaStar } from 'react-icons/fa'
import image from "../../assets/images/hotel1.png"
import { Link } from 'react-router-dom'

const HotelCard = ({ data, type }) => {

  const handleClick = () => {
    localStorage.setItem('hotel', JSON.stringify(data))
  }

  
  return (
    <div className='hotel-card' style={{ minWidth: "300px" }} onClick={handleClick}>
      <Link to={{
        pathname: `/hotels/${data.id}`,
        state: { plan: data }
      }} className='no-link'>  <div>
          <img src={data?.image ? data?.image : image} alt="" className="w-full h-auto rounded-xl" />
        </div>
        <div className={`content ${type === 'mylisting' ? 'more-content' : ''}`}>
          <p className='flex items-center'><FaStar style={{ color: "gold" }} />&nbsp; <span>9.5 out of 256 reviews</span></p>
          <h3>{data?.name}</h3>

          <p className='flex items-center'><FaMapMarkerAlt /> &nbsp; <span>{data?.location}</span></p>

          <div className='hover-content'>
       
        <p className='flex items-center justify-between'><span className="purple">View Details</span> {type == "mylisting" && <button className="hotel-btn">Promote</button> } </p>
      </div>
        </div></Link>
    </div>
  )
}

export default HotelCard