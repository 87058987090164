import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import HitsCard from './HitsCard'
import Lottie from 'lottie-react'
import NothingHere from '../Assets/nothing_here.json'
import AudioPlayer2 from '../AudioPlayer2/AudioPlayer'
import { MusicPlayerContext } from '../context/MusicPlayerContext'

export default function FeedsStereoComp() {
  const [songs, setSongs] = useState([])
  const [clickedIndex2, setClickedIndex2] = useState(null) // Initialize with null
  const [currentSong2, setCurrentSong2] = useState(songs[clickedIndex2])
  const [modalOpen1, setModalOpen1] = useState(false)
  const [activePlayer, setActivePlayer] = useState(null)
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null)
  const [minimized, setMinimized] = useState(false)
  const { tracks, setTracks, index, setIndex, modalOpen, setModalOpen, minimized1, setMinimized1 } = useContext(MusicPlayerContext)

  const authToken = localStorage.getItem('authToken')
  const handleItemClick2 = (index, tracks) => {
    // console.log('Clicked index:', index)
    // setCurrentSong(quickpicks[clickedIndex]);
    setIndex(index); // Set the clicked index to state
    setModalOpen(!modalOpen)
    setTracks(tracks)
  }

  const handlePlayPause = (id) => {
    if (id === currentlyPlaying) {
      setCurrentlyPlaying(null)
    } else {
      setCurrentlyPlaying(id)
    }
  }
  const GetSongs = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/songs/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'X-CSRFToken': process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        console.log(res.data.data, 'conssssllllll')
        setSongs(res.data.data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }

  useEffect(() => {
    GetSongs()
  }, [])

  // console.log(songs, 'song  hhhy')

  return (
    <div>
      <div
        className={
          songs?.length > 0
            ? 'flex mx-3 gap-3 overflow-x-scroll justify-between h-auto'
            : null
        }
        style={{
          scrollbarWidth: 'none',
          height: '100%',
        }}
      >
        {songs?.length > 0 ? (
          songs?.map((res, index) => {
            return (
              <div key={res?.id} className=''>
                <HitsCard
                  onClick={() => handleItemClick2(index, songs)}
                  setModalOpen1={setModalOpen1}
                  modalOpen1={modalOpen1}
                  name={res?.title}
                  img={res?.cover_image ? res?.cover_image : null}
                  artist={res?.artist.artist_name}
                  audio={res?.audio_file ? res?.audio_file : null}
                  id={res?.id}
                  activePlayer={activePlayer}
                  setActivePlayer={setActivePlayer}
                  handlePlayPause={handlePlayPause}
                />
              </div>
            )
          })
        ) : (
          <div className='flex justify-center items-center'>
            <Lottie
              animationData={NothingHere}
              style={{
                width: '263.38px',
                height: '100%',
              }}
            />
          </div>
        )}
      </div>
      
    </div>
  )
}
