import "./style.css";
import EventCard from "./EventCard";
import { useNavigate } from "react-router-dom";

const PromoteTicket = ({ promotedEventsData }) => {
  const navigate = useNavigate();

  const goToAllPromotedTickets = () => {
    navigate("/ticket/all-promoted-tickets");
  };

  return (
    <div className="trending-product-container">
      <div className="flex w-full items-center justify-between py-1 mt-6">
        <h2 className="font-semibold text-[17px]">Promoted events</h2>
        {promotedEventsData?.length ? (
          <button
            className="text-[13px] mt-2 border-none border-b text-[#FF8A15]"
            onClick={goToAllPromotedTickets}
          >
            View all
          </button>
        ) : null}
      </div>
      <div className="product-card-row">
        {!promotedEventsData?.length ? (
          <p className="text-[13px] mt-2 w-fit cursor-auto text-[#FF8A15]">
            No promoted tickets to display
          </p>
        ) : (
          <EventCard data={promotedEventsData} />
        )}
      </div>
    </div>
  );
};

export default PromoteTicket;
