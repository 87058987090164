import { useQuery } from "@tanstack/react-query";
import { getAccountData } from "services/profile_business_API";

export const useAccountProfile = () => {
  const {
    status: accountStatus,
    data,
    error,
  } = useQuery({
    queryKey: ["account"],
    queryFn: getAccountData,
  });

  const accountProfile = data?.data

  return { accountStatus, accountProfile, error };
};
