import Description from "components/newCommerce/typography/txtDescription";
import { ButtonSide } from "components/newCommerce/shared/sideButton";
import { Modal, Box } from "@mui/material";
import Selectaddress from "components/newCommerce/shared/cart/selectaddress";
import Stacked from "components/newCommerce/shared/Stacked";
import { ModalContext } from "Context/ModalContext";
import { useContext, useEffect, useState } from "react";
import useLocalstorage from "api/hooks/commerce/uselocalStorage";
const AddressSelect = () => {
  const { nextCartPopup } = useContext(ModalContext);
  const [detailsBuyer, setdetails] = useState([]);
  const [disabled, setisdisable] = useState(true);
  const [selected, setSelected] = useState({});
  const [isopen, setisopen] = useState(false);
  const Addaddress = (payload) => {
    nextCartPopup(payload);
  };

  const handlemodalOpen = () => setisopen(true);

  const handlemodalClose = () => {
    setisopen(false);
  };

  const { getFormlist, setParsedField, fixedtoFirst } = useLocalstorage();

  useEffect(() => {
    getFormlist && setdetails(JSON.parse(getFormlist));
  }, [getFormlist]);

  //handle-merging-to-thefirst-as-selectedandmaking-default
  const handlePush = () => {
    if (Object.keys(selected).length > 0) {
      // automatically-setdefault-astrue
      const newitem = selected;
      newitem.isdefault = true;
      setParsedField(newitem);
      // intentional-delay-for-async-react-states
      setTimeout(() => {
        fixedtoFirst();
      }, 1000);
    } else {
      console.error("error Adding Try again");
    }
    handlemodalOpen();
  };

  const handleupdateSave = () => {
    fixedtoFirst();
    setTimeout(() => {
      handlemodalClose();
    }, 500);
  };

  return (
    <>
      <div className="flex flex-col gap-y-3">
        <Selectaddress
          show={(curr) => setisdisable(curr)}
          curr={(fill) => setSelected(fill)}
        />
      </div>

      <Description
        title={titleText}
        sx={{ textAlign: "left !important", visibility: "hidden !important" }}
      />

      <div className="flex flex-col gap-y-4 items-center justify-center pt-[4rem]">
        {detailsBuyer.length > 0 && (
          <ButtonSide
            title="Select address"
            bg="#4f0da3"
            cl="#ffff"
            jc="initial"
            bs="none"
            pi="7rem"
            isDisabled={disabled}
            click={handlePush}
          />
        )}

        <ButtonSide
          title="Add New Address"
          cl="#4f0da3"
          bg="#f5f5f5"
          jc="initial"
          bs="none"
          pi="7rem"
          click={() => Addaddress("buyerDetails")}
        />
      </div>
      <Modal
        open={isopen}
        onClose={handlemodalClose}
        aria-labelledby="Store Delete modal"
        aria-describedby="add your variations"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 350,
            bgcolor: "#ffff",
            paddingBlock: 4,
            paddingInline: 2.5,
            borderRadius: 2,
          }}
        >
          <Stacked d="column" g={4}>
            <>
              <Description
                align={"center"}
                fs={"1.6rem"}
                title={`Make this as your Default?`}
                cl="#000000"
              />
              <Stacked d="row" jc={"space-between"}>
                <ButtonSide
                  title="Cancel"
                  cl="rgba(34, 34, 34, 0.7)"
                  bg="transparent"
                  styles={{
                    boxShadow: "none !important",
                    fontSize: "1.5rem !important",
                  }}
                  click={handlemodalClose}
                  className="mark_sold_btns"
                />
                <ButtonSide
                  title={`Confirm`}
                  cl="rgba(255, 0, 0, 1)"
                  bg="transparent"
                  styles={{
                    boxShadow: "none !important",
                    fontSize: "1.5rem !important",
                  }}
                  click={handleupdateSave}
                  className="mark_sold_btns"
                />
              </Stacked>
            </>
          </Stacked>
        </Box>
      </Modal>
    </>
  );
};
let titleText =
  "Exceptional product! Impressive quality, versatile features, and user-friendly design. A must-have for anyone seeking top-notch performance.";
export default AddressSelect;
