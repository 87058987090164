import { AiOutlinePlus } from "react-icons/ai";
import { useState, useEffect, useRef } from "react";
import { GoFilter } from "react-icons/go";
import StepSecond from "./StepSecond/StepSecond";
import { useForm } from "react-hook-form";
import useGetEventDetails from "Hooks/useGetEventDetails";
import TicketSearchComp from "./TicketSearchComp";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast'

const CreateEventTictetFormTwo = () => {
  const {
    handleSetSellTicketTwo,
    isCreateTicketOpen,
    isCreateTicketOneOpen,
    isCreateTicketTwoOpen,
    sellTicketTwo,
    isEditing,
    editDetail,
  } = useGetEventDetails();


  const [isFree, setIsFree] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ticketName:
        !sellTicketTwo && editDetail?.ticket_name
          ? editDetail?.ticket_name
          : sellTicketTwo?.ticketName
          ? sellTicketTwo?.ticketName
          : "",
      ticketCategory: sellTicketTwo?.ticketCategory
        ? sellTicketTwo.ticketCategory
        : "",
      vip_ticket_quantity:
        !sellTicketTwo && editDetail?.vip_ticket_quantity
          ? editDetail?.vip_ticket_quantity
          : sellTicketTwo?.vip_ticket_quantity
          ? sellTicketTwo.vip_ticket_quantity
          : "",
      regular_ticket_quantity:
        !sellTicketTwo && editDetail?.regular_ticket_quantity
          ? editDetail?.regular_ticket_quantity
          : sellTicketTwo?.regular_ticket_quantity
          ? sellTicketTwo.regular_ticket_quantity
          : "",
      vip_ticket_price:
        !sellTicketTwo && editDetail?.vip_ticket_price
          ? editDetail?.vip_ticket_price
          : sellTicketTwo?.vip_ticket_price
          ? sellTicketTwo.vip_ticket_price
          : "",
      regular_ticket_price:
        !sellTicketTwo && editDetail?.regular_ticket_price
          ? editDetail?.regular_ticket_price
          : sellTicketTwo?.regular_ticket_price
          ? sellTicketTwo.regular_ticket_price
          : "",
    },
  });

  const navigate = useNavigate();

  const pageRef = useRef(null);

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    handleGetRef();
  }, [isCreateTicketOpen, isCreateTicketOneOpen, isCreateTicketTwoOpen]);

  const onSubmit = (data) => {
    const { regular_ticket_price, regular_ticket_quantity, vip_ticket_price, vip_ticket_quantity } = data;

    if (
      (regular_ticket_price && isNaN(Number(regular_ticket_price))) ||
      (regular_ticket_quantity && isNaN(Number(regular_ticket_quantity))) ||
      (vip_ticket_price && isNaN(Number(vip_ticket_price))) ||
      (vip_ticket_quantity && isNaN(Number(vip_ticket_quantity)))
    ) {
      toast.error("Enter valid numbers for prices and quantities");
      return;
    }

    const currentTicketPrice = isFree ? "FREE TICKET" : data.ticketPrice;
    handleSetSellTicketTwo({ ...data, ticketPrice: currentTicketPrice });

    navigate("/ticket/sell-ticket/create-event-three");
  };


  const goBack = () => {
    navigate('/ticket/sell-ticket/create-event-one')
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-4">
      <div ref={pageRef}>
        <TicketSearchComp
          label={isEditing ? "Edit event" : "Create event"}
          handleCloseContainerClick={goBack}
          add={"add"}
        />
      </div>
      <StepSecond />
      <div className="event-ead">Create ticket</div>
      <div className="event-txt">
        Create ticket type(s) you want for this event
      </div>

      <div className="mt-4 border rounded-full h-[55px] w-full grid grid-cols-2">
        <div
          className={`flex flex-col gap-2 justify-center items-center rounded-l-full cursor-pointer
        ${isFree && "bg-neutral-200"}
        `}
          onClick={() => setIsFree(true)}
        >
          <AiOutlinePlus className="text-black mt-3" />
          <p>Free ticket</p>
        </div>
        <div
          className={`flex flex-col gap-2 justify-center items-center rounded-r-full cursor-pointer
        ${!isFree && "bg-neutral-200"}
        
        `}
          onClick={() => setIsFree(false)}
        >
          <AiOutlinePlus className="text-black mt-3" />
          <p>Paid ticket</p>
        </div>
      </div>
      <div className="p-4 mt-4 border">
        <div className="mt-4 grid gap-3 lg:grid-cols-1">
          <div className="mt-2">
            <div className="border h-[60px] rounded relative px-3 flex">
              <label className="absolute -top-4 left-4 z-10 bg-white">
                REGULAR TICKET QUANTITY
              </label>
              <div className="">
                <input
                  type="text"
                  id="vip_ticket_quantity"
                  {...register("regular_ticket_quantity", {
                    required: "regular ticket quantity is required",
                  })}
                  className="border-none outline-none text-[15px] absolute top-3 left-0"
                  placeholder="Enter regular ticket quantity"
                />
              </div>
              <div className="flex justify-between items-center self-end w-full absolute left-0 top-[40px] px-3">
                {errors.regular_ticket_quantity ? (
                  <p className="text-red-500 text-[12px]">
                    {errors?.regular_ticket_quantity?.message}
                  </p>
                ) : (
                  <div> </div>
                )}
              </div>
            </div>
          </div>
          <div className="mt-2">
            <div className="border h-[60px] rounded relative px-3 flex">
              <label className="absolute -top-4 left-4 z-10 bg-white">
                VIP TICKET QUANTITY
              </label>
              <div className="">
                <input
                  type="text"
                  id="vip_ticket_quantity"
                  {...register("vip_ticket_quantity", {
                    required: "vip ticket quantity is required",
                  })}
                  className="border-none outline-none text-[15px] absolute top-3 left-0"
                  placeholder="Enter vip ticket quantity"
                />
              </div>
              <div className="flex justify-between items-center self-end w-full absolute left-0 top-[40px] px-3">
                {errors.vip_ticket_quantity ? (
                  <p className="text-red-500 text-[12px]">
                    {errors?.vip_ticket_quantity?.message}
                  </p>
                ) : (
                  <div> </div>
                )}
              </div>
            </div>
          </div>

          {/* PAID TICKET */}
          {!isFree && (
            <>
              <div className="mt-2">
                <div className="border h-[60px] rounded relative px-3 flex">
                  <label className="absolute -top-4 left-4 z-10 bg-white">
                    REGULAR TICKET PRICE
                  </label>
                  <div className="">
                    <input
                      type="text"
                      id="regular_ticket_price"
                      {...register("regular_ticket_price", {
                        required: !isFree && "regular ticket price is required",
                      })}
                      className="border-none outline-none text-[15px] absolute top-3 left-0"
                      placeholder="Enter price for regular tickets"
                    />
                  </div>
                  <div className="flex justify-between items-center self-end w-full absolute left-0 top-[40px] px-3">
                    {errors.regular_ticket_price ? (
                      <p className="text-red-500 text-[12px]">
                        {errors?.regular_ticket_price?.message}
                      </p>
                    ) : (
                      <div> </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <div className="border h-[60px] rounded relative px-3 flex">
                  <label className="absolute -top-4 left-4 z-10 bg-white">
                    VIP TICKET PRICE
                  </label>
                  <div className="">
                    <input
                      type="text"
                      id="ticketPrice"
                      {...register("vip_ticket_price", {
                        required: !isFree && "vip ticket price is required",
                      })}
                      className="border-none outline-none text-[15px] absolute top-3 left-0"
                      placeholder="Enter price of vip tickets"
                    />
                  </div>
                  <div className="flex justify-between items-center self-end w-full absolute left-0 top-[40px] px-3">
                    {errors.vip_ticket_price ? (
                      <p className="text-red-500 text-[12px]">
                        {errors?.vip_ticket_price?.message}
                      </p>
                    ) : (
                      <div> </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* FOURTH */}
      <div className="mt-4">
        <h4>Event Category</h4>
        <div className="flex items-center cursor-pointer py-2 px-4 text-neutral-400 border">
          <GoFilter className="text-[20px] text-neutral-500 rounded" />
          <select
            id="ticketCategory"
            {...register("ticketCategory", {
              required: "Ticket category is required",
            })}
            className="border-none bg-white cursor-pointer text-neutral-400 text-[14px] outline-none"
          >
            <option value="">Select Category</option>
            <option value="festival">festival</option>
            <option value="concerts">concerts</option>
            <option value="fashion">fashion</option>
            <option value="others">others</option>
          </select>
        </div>
        {errors.ticketCategory ? (
          <p className="text-red-500 text-[12px]">
            {errors?.ticketCategory?.message}
          </p>
        ) : (
          <div> </div>
        )}
        <button
          type="submit"
          className="w-full mt-3 bg-[#4f0da3] text-[#fff] py-3 text-[14px] hover:opacity-90 rounded"
        >
          Continue
        </button>
      </div>
    </form>
  );
};

export default CreateEventTictetFormTwo;
