import React, { useContext, useEffect, useState } from "react";
import Arrow from "../Assets/arrowback.svg";
import Logo from "../Assets/NEW full color-1 1.svg";
import TopSongsCard from "../Components/TopSongsCard";
import RightIcon from "../Assets/icon-park_right.svg";
import SpecialPicksCard from "../Components/SpecialPicksCard";
import axios from "axios";
import Lottie from "lottie-react";
import NothingHere from "../Assets/nothing_here.json"
import AudioPlayer2 from "../AudioPlayer2/AudioPlayer";
import toast from "react-hot-toast";
import { MusicPlayerContext } from "../context/MusicPlayerContext";

const ArtistProfile = ({ handleArtistProClose, onClick, id, Albums, songs }) => {
  const [artists, setArtists] = useState({})
  const [minimized, setMinimized] = useState(false)
  // const [songs,setSongs] = useState([])
  const [artistSongs, setArtistSongs] = useState([])
  // const [Albums, setAlbums] = useState([])
  const [artistAlbums, setArtistAlbums] = useState([])
  // const [modalOpen, setModalOpen] = useState(false)
  const [clickedIndex, setClickedIndex] = useState(0)
  const [artistData, setArtistData] = useState([])
  const [found, setFound] = useState(false)
  const { tracks, setTracks, index, setIndex, modalOpen, setModalOpen, minimized1, setMinimized1 } = useContext(MusicPlayerContext)
  // var artists = {}

  const handleItemClick = (index, tracks) => {
    console.log('Clicked index:', index);
    // setCurrentSong(quickpicks[clickedIndex]);
    setIndex(index); // Set the clicked index to state
    setModalOpen(!modalOpen)
    setTracks(tracks)
  };

  const authToken = localStorage.getItem("authToken")
  const GetArtistProfile = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/artists/${id}/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        console.log(id,"gtuvertyiokdhfgtrvc")
        setArtists(res.data.data);
        console.log(JSON.stringify(artists) + "artistprofile state===");
        console.log(JSON.stringify(res.data.data) + "artistsprofile====");
      }).catch(e=>console.log(e));
  };

  const GetArtistData = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/artists/profile/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setArtistData(res?.data?.data);
        console.log(artistData + "artistData state===&&&");
        console.log(JSON.stringify(res.data) + "artistData====");
      });
  };

  const StickArtist = async(artistId) => {
    await axios.get(`https://api.2geda.net/api/stereo/artists/${artistId}/stick/`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "X-CSRFToken": process.env.REACT_TOKEN,
      },
    }).then(res=>{
      console.log(res)
      toast.success(res.data.message)
      setFound(true); // Update state to reflect sticking
    }).catch(err=>{
      toast.error("Something went wrong")
      console.log(err)
    })
  }

  const UnStickArtist = async(artistId) => {
    await axios.get(`https://api.2geda.net/api/stereo/artists/${artistId}/unstick/`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "X-CSRFToken": process.env.REACT_TOKEN,
      },
    }).then(res=>{
      console.log(res)
      toast.success(res.data.message)
      setFound(false); // Update state to reflect unsticking
    }).catch(err=>{
      toast.error("Something went wrong")
      console.log(err)
    })
  }


  const GetSongs = () => {
    
        const Songs = songs?.filter(song => song.artist.artist_name === artists?.artist_name);
        setArtistSongs(Songs)
  };

  const GetAlbums = () => {
    // axios
    //   .get(`https://development.2geda.net/api/stereo/albums/`, {
    //     headers: {
    //       Authorization: `Bearer ${authToken}`,
    //       "X-CSRFToken": process.env.REACT_TOKEN,
    //     },
    //   })
    //   .then((res) => {
    //     setAlbums(res?.data?.data);
        const albums = Albums?.filter(album => album.artist.artist_name === artists?.artist_name);
        setArtistAlbums(albums)
      //   console.log(Albums + "Albums state===");
      //   console.log(JSON.stringify(res.data) + "Albums====");
      // }).catch(e=>console.log(e));
  };

  const SortArtistId = () => {
    console.log("artists.profile.stickers:", artists?.profile?.stickers);
    console.log("artistData.id:", artistData?.profile?.id);
    if (artists?.profile && artists.profile.stickers && artistData?.profile?.id) {
      const item = artists?.profile?.stickers?.find((obj) => obj.id === artistData?.profile?.id);
      const found = item !== undefined;
      setFound(found);
      console.log("Item:", item);
      console.log("Found:", found);
    }
  };

  useEffect(() => {
    GetArtistProfile();
  }, [id]);

  useEffect(() => {
    if (artists.artist_name) {
      const Songs = songs?.filter((song) => song.artist.artist_name === artists.artist_name);
      setArtistSongs(Songs);

      const albums = Albums?.filter((album) => album.artist.artist_name === artists.artist_name);
      setArtistAlbums(albums);
      SortArtistId(); // Ensure sorting happens after the artists state is updated
    }
  }, [artists, songs, Albums]);

  useEffect(() => {
    window.scrollTo(0, 0);
    GetArtistData();
  }, []);

  useEffect(() => {
    SortArtistId();
  }, [artistData, artists]);

  return (
    <div className="xl:pt-4 md:pt-2 pt-2 mt-2 sm:mt-0">
      {/* header */}
    
      <header className="hidden sm:block">
        <div className="flex justify-between items-center py-5 px-5 container">
          <button onClick={onClick}>
            <img src={Arrow} />
          </button>
          <p className="font-medium text-4xl">Artist profile</p>
          <div></div>
        </div>
        <div className="border-b border-[rgba(0, 0, 0, 0.2)]" />
      </header>
      <header className="block sm:hidden">
        <div className="flex justify-between items-center py-5 px-5 container">
          <button onClick={onClick}>
            <img src={Arrow} />
          </button>
          <p className="font-bold text-xl">Profile</p>
          <img src={Logo} height={40} width={40} />
        </div>
        <div className="border-b border-[rgba(0, 0, 0, 0.2)]" />
      </header>

      <section
        className="bg-gradient-to-b flex flex-col md:flex-row justify-between px-5 py-10 items-center"
        style={{
          background:
            "linear-gradient(180deg, rgba(79, 13, 163, 0) 26.04%,rgba(79, 13, 163, 0.2) 100%",
        }}>
        <div className="w-full  md:max-w-[759.4px]">
          <p className="font-bold text-2xl mb-4">{artists.artist_name}</p>
          <p className="text-xl font-normal">
            {artists.about}
          </p>
          {/* <p>{found?"true":"false"}</p> */}
          <button className="bg-[#4F0DA3] hover:bg-[#4F0DC3] rounded-2xl pl-4 pr-4 pt-2 pb-2 text-white text-2xl" onClick={!found ? ()=>StickArtist(artists.id):()=>UnStickArtist(artists.id)}>{!found?"Stick":"Unstick"}</button>
        </div>
        <div className="w-full h-208 md:max-w-[337px] md:max-h-[250px] rounded-3xl mt-10">
          <img
            src={artists.brand_image}
            style={{ objectFit: "cover" }}
            className="w-full h-208  md:w-[337px] md:max-h-[250px] rounded-3xl"
          />
        </div>
      </section>

      <section className="px-5 mt-10">
        <div className="flex items-center justify-between mb-5">
          <p className="font-medium text-base">Top songs</p>
          {/* <a
            href="#"
            className="flex items-center text-xs font-normal text-[#4F0DA3]">
            View all songs
            <img src={RightIcon} />
          </a> */}
        </div>
        {/* <TopSongsCard /> */}
        <ol
          className="list-decimal space-y-4 align-middle items-center LINE-H"
          style={{ listStyleType: "decimal", listStyle: "none" }}>
            {artistSongs.length>0?artistSongs.map((res, id)=>{
              return(
              <li style={{ alignItems: "center" }}>
              <TopSongsCard onClick={()=>handleItemClick(id, artistSongs)} modalOpen={modalOpen} setModalOpen={setModalOpen} id={id+1} title={res.title} img={res.cover_image} />
            </li>
              )
            }):<div className="flex justify-center items-center"><Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
          {/* <li style={{ alignItems: "center" }}>
            <TopSongsCard id={1} />
          </li>
          <li>
            <TopSongsCard id={2} />
          </li>
          <li>
            <TopSongsCard id={3} />
          </li>
          <li>
            <TopSongsCard id={4} />
          </li>
          <li>
            <TopSongsCard id={5} />
          </li> */}
        </ol>
      </section>

      <section className="px-5 mt-10">
        <p className="font-medium text-base">Albums</p>
        
        <div
          className={artistAlbums.length>0?"flex flex-row my-[10px] gap-[10px] mt-5 overflow-x-scroll":null}
          style={{ scrollbarWidth: "none" }}>
            {artistAlbums.length>0?artistAlbums.map((res)=>{
              return (
              <SpecialPicksCard
              img={res.cover_image}
              category={res.name}
            />
              )
            }):<div className="flex justify-center items-center"><Lottie
            animationData={NothingHere}
            style={{
              width: "263.38px",
              height: "100%",
            }}
          /></div>}
          {/* <SpecialPicksCard />
          <SpecialPicksCard
            img={require("../Assets/Image6.jpeg")}
            category={"Life of the party"}
          />
          <SpecialPicksCard
            img={require("../Assets/Image3.jpeg")}
            category={"Afrojams"}
          />
          <SpecialPicksCard />
          <SpecialPicksCard
            img={require("../Assets/Image6.jpeg")}
            category={"Life of the party"}
          />
          <SpecialPicksCard
            img={require("../Assets/Image3.jpeg")}
            category={"Afrojams"}
          />
          <SpecialPicksCard /> */}
        </div>
      </section>
      {/* {modalOpen && (
          <div
            id="modelConfirm"
            className="fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4">
            <div className="relative top-40 mx-auto shadow-xl bg-white max-w-[100%] lg:max-w-[80%] md:max-w-[80%] xl:max-w-[80%] rounded-xl overflow-x-hidden">
             <main className="py-10 my-10"> */}
                {/* <MusicPlayer playerIndex={clickedIndex} songs={quickpicks} setSongs={setQuickPicks} currentSong={currentSong} setCurrentSong={setCurrentSong} src={currentSong.url} ref={audioElem} onTimeUpdate={onPlaying}/> */}
                {/* <audio ref={audioElem} onTimeUpdate={onPlaying}>
                <source src={currentSong?.audio_file?`https://development.2geda.net${currentSong?.audio_file}`:""} type="audio/mp3" />
                </audio> */}
                {/* {modalOpen && <AudioPlayer2 setModalOpen1={setModalOpen} modalOpen={modalOpen} tracks={artistSongs} index={clickedIndex} minimized1={minimized} setMinimized1={setMinimized}/>} */}
      {/* <Player songs={quickpicks} setSongs={setSongs} isplaying={isplaying} setisplaying={setisplaying} audioElem={audioElem} currentSong={currentSong} setCurrentSong={setCurrentSong} /> */}
              {/* </main>
            </div>
          </div>
        )} */}
    </div>
  );
};

export default ArtistProfile;
