import moment from "moment";
import { BiSolidUpArrow } from "react-icons/bi";

const HistoryCol = ({date, amount, status}) => {
  const getCurrentDate = () => {
    
    const currentDate = moment(date).format("DD MMM, YYYY");
    return `${currentDate}`;
  };
  return (
    <div className="colhistory-container flex">
      <div className="left-col-roy flex">
        <div className="with-cont flex">
          <BiSolidUpArrow />
        </div>
        <div className="wit-txt-bx">
          <div className="with-bx">Withdrawal</div>
          <div className="wit-dat">{getCurrentDate()}</div>
        </div>
      </div>
      <div className="rit-side-col flex">
        <div className="neg-amt">-# {amount}</div>
        <div className={`${status === "FAILED" ? 'text-rose-500' : status === "PENDING" ? "text-blue-500" : "text-green-500"}`}>{status}</div>
      </div>
    </div>
  );
};

export default HistoryCol;
