import EventCard from "./EventCard";
import { useNavigate } from "react-router-dom";

const PopularTicket = ({ popularEventsData }) => {
  const navigate = useNavigate();

  const goToAllPopularTickets = () => {
    navigate("/ticket/all-popular-tickets");
  };

  return (
    <div className="">
      <div className="flex w-full items-center justify-between py- mt-6">
        <h2 className="font-semibold text-[17px]">Popular events</h2>
        {popularEventsData?.length ? (
          <button
            className="text-[13px] mt-2 border-none border-b text-[#FF8A15]"
            onClick={goToAllPopularTickets}
          >
            View all
          </button>
        ) : null}
      </div>
      <div className="product-card-row">
        {!popularEventsData?.length ? (
          <p className="text-[13px] mt-2 w-fit cursor-auto text-[#FF8A15]">
            No popular tickets to display
          </p>
        ) : (
          <EventCard data={popularEventsData} />
        )}
      </div>
    </div>
  );
};

export default PopularTicket;
