import React from "react";
import Description from "components/newCommerce/typography/txtDescription";
import Header from "components/newCommerce/typography/txtHeader";
import { ModalContext } from "Context/ModalContext";
import { useContext, useEffect, useState } from "react";
import { Fab } from "@mui/material";
import { FaWallet } from "react-icons/fa6";
import Stacked from "components/newCommerce/shared/Stacked";
import { ButtonSide } from "components/newCommerce/shared/sideButton";
import { FormatwithComma } from "utils/commerceUtils";
import {
  Userdefaultaddress,
  User,
  createCheckout,
} from "api/commerce/Apiactions";
import useLocalstorage from "api/hooks/commerce/uselocalStorage";
import toast from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Buyconfirmcheckout = () => {
  const [loadingorder, setLoadingorder] = useState(false);

  const { nextCartPopup, itemParam, detailsBuyerEdit } =
    useContext(ModalContext);
  // address-change/edit
  const changeAddress = (payload) => {
    nextCartPopup(payload);
  };
  const { getFormlist } = useLocalstorage();
  // ifa-user-detail-exist-in-local-storage-then-filter-vy-default
  const [firstDefaultUser, setFirsdtDefault] = useState([]);
  const [firstUser, setFirstuser] = useState([]);

  useEffect(() => {
    const defaultuser = JSON.parse(getFormlist)?.find(
      (user) => user.isdefault === true
    );

    defaultuser ? setFirsdtDefault([defaultuser]) : setFirsdtDefault(null);

    // get-first-user-if-no-default-exist
    const firstUserdetail = JSON.parse(getFormlist);
    let userfirst = [];
    if (!firstUserdetail) {
      userfirst = [];
    } else {
      userfirst = firstUserdetail.find((val, index) => index === 0);
      setFirstuser([userfirst]);
    }
  }, [getFormlist]);

  // send-request-tocreate-checkout-API
  // form-datas-andshipping-details-tobe included

  // convert arrayusers to objects
  // const [firstuserobject, setfirstuserobject] = useState({});
  // const [firstdfuserobject, setfirstdfuserobject] = useState({});

  // useEffect(() => {
  //   if (firstUser.length > 0 && !firstDefaultUser) {
  //     setfirstuserobject({ ...[firstUser] });
  //   } else if (firstDefaultUser && firstDefaultUser.length > 0) {
  //     setfirstdfuserobject({ ...[firstDefaultUser] });
  //   }
  // }, [firstDefaultUser, firstUser]);

  // useEffect(() => {
  //   console.log(firstuserobject + "----", firstdfuserobject);
  // }, [firstdfuserobject, firstuserobject]);

  //  confrim order

  const confirmOrder = async (payload) => {
    // check-ifuserneeds-to-change-location
    if (!JSON.parse(getFormlist) || JSON.parse(getFormlist).length < 1) {
      window.alert("Please Change your default address!");
      return;
    }
    const formDatas = {
      shipping_address: {
        address: firstDefaultUser[0]?.del_address || firstUser[0]?.del_address,
        city: firstDefaultUser[0]?.city || firstUser[0]?.city,
        postal_code: firstDefaultUser[0]?.postal_code || "",
        country: firstDefaultUser[0]?.country || "Nigeria",
      },
      payment_method: "default",
      shipping_fee: "0",
    };

    setLoadingorder(true);
    try {
      const postcheckout = await createCheckout(formDatas);
      postcheckout && toast.success(`Order Successfull`);
    } catch (err) {
      toast.error(`Order Failed`);
    } finally {
      setLoadingorder(false);
    }
  };

  return (
    <>
      <div className="flex flex-col w-full gap-y-3">
        {itemParam.map((item, index) => {
          return (
            <div
              className="bg-[#ffff] py-2 px-2 flex justify-between items-center rounded-xl"
              key={index}
            >
              <div className="flex flex-row gap-3">
                <div className="w-[95px] h-[95px]">
                  <img
                    className="w-full h-full rounded-lg"
                    src={item.product.product_image}
                    alt="cart-product"
                  />
                </div>
                <div className="flex flex-col justify-between items-start">
                  <Header
                    title={item.product.name}
                    fw="500"
                    fz="18px"
                    cl="black"
                  />
                  <Header
                    title={FormatwithComma(item?.product.price)
                      .toString()
                      .replace(/^/, "₦")}
                    sx={{ fontSize: "15px", fontWeight: "600" }}
                  />
                  <div className="flex items-center gap-x-2">
                    <Description
                      title={`QTY: ${item.quantity}`}
                      fw="500"
                      fz="14px"
                    />
                    {/* <Header
                      title={item.Inventory}
                      fw="500"
                      fz="18px"
                      cl="black"
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {/* payment-method */}
        <Header
          title="PAYMENT METHOD"
          fw="400"
          fz="18px"
          sx={{ textAlign: "left !important" }}
        />
        {/* wallet-payment or fund-transaction */}
        <div className="flex justify-between items-center p-2 rounded-lg bg-[#ffff]">
          <Stacked d="row" g=".5rem" ai="center">
            <Fab
              sx={{
                borderRadius: "5px",
                boxShadow: "none",
                background: "#4f0da3",
                padding: "2px !important",
                width: "35px",
                height: "35px",
                zIndex: "9",
              }}
            >
              <FaWallet fontSize="15px" fill="white" />
            </Fab>
            <Stacked d="column" g=".4rem" ai="start">
              <Header
                title="Pay with wallet"
                sx={{ fontSize: "15px" }}
                fw="400"
              />
              <Description title="₦0.000" sx={{ fontSize: "13px" }} />
            </Stacked>
          </Stacked>
          {/* button-fund */}
          <button className="bg-[#4f0da3] py-2 px-2 text-white rounded-lg text-[13px]">
            Fund wallet
          </button>
        </div>
        {/* DELIVERY ADDRESS */}

        <div className="flex justify-between items-center">
          <Header
            title="DELIVERY ADDRESS"
            fw="400"
            fz="18px"
            sx={{ textAlign: "left !important" }}
          />
          <button
            className="bg-[#f5f5f5] py-2 px-2 rounded-lg text-[#4f0da3] text-[13px]"
            onClick={() => changeAddress("addressSelection")}
          >
            Change
          </button>
        </div>

        {/* customer-details */}
        <div className="bg-[#ffff] flex flex-col gap-y-2 p-2 rounded-lg items-start">
          <>
            {!JSON.parse(getFormlist) || JSON.parse(getFormlist).length < 1 ? (
              <>
                <Header
                  title={`${User[0].toUpperCase()}${User.slice(1)}`}
                  cl="black"
                />
                <Description
                  fz="13px"
                  title={`${Userdefaultaddress.city || ""} ${
                    Userdefaultaddress.country
                  } `}
                />
              </>
            ) : firstUser.length > 0 && !firstDefaultUser ? (
              firstUser?.map((details, index) => {
                return (
                  <div key={index}>
                    <Header
                      title={`${details.fname[0].toUpperCase()}${details.fname.slice(
                        1
                      )}  ${details.secname[0].toUpperCase()}${details.secname.slice(
                        1
                      )}`}
                      cl="black"
                    />
                    <Description
                      fz="13px"
                      title={`${details.del_address || ""} ${details.city} ${
                        details.phone_number
                      }`}
                    />
                  </div>
                );
              })
            ) : (
              <>
                {firstDefaultUser && firstDefaultUser.length > 0
                  ? firstDefaultUser?.map((details, index) => {
                      return (
                        <div key={index}>
                          <Header
                            title={`${details?.fname[0].toUpperCase()}${details.fname.slice(
                              1
                            )}  ${details.secname[0].toUpperCase()}${details.secname.slice(
                              1
                            )}`}
                            cl="black"
                          />
                          <Description
                            fz="13px"
                            title={`${details.del_address || ""} ${
                              details.city
                            } ${details.phone_number}`}
                          />
                        </div>
                      );
                    })
                  : null}
              </>
            )}
          </>
        </div>
        {/* fee-settings */}
        <Header
          title="FEE SETTINGS"
          cl="black"
          sx={{ textAlign: "left !important" }}
        />

        {/* item-fee-settings-andcheckout-total-payment */}
        <div className="bg-[#ffff] rounded-lg flex flex-col gap-y-3 items-start p-2">
          {/* flex-item-total */}
          {itemParam.map((fees, index) => {
            return (
              <React.Fragment key={index}>
                <div className="flex justify-between items-center w-full">
                  <Description
                    title="Item total"
                    fz="13px"
                    fw="500"
                    cl="#000000"
                  />
                  <Description
                    title={FormatwithComma(fees?.product.price)
                      .toString()
                      .replace(/^/, "₦")}
                    fz="13px"
                    cl={"rgba(0, 0, 0, 0.6)"}
                  />
                </div>

                {/* flex-item-delivery-fee */}
                <div className="flex justify-between items-center w-full">
                  <Description
                    title="Delivery fee"
                    fz="13px"
                    fw="500"
                    cl="#000000"
                  />
                  <Description
                    title={fees.delivery_fee || "₦0.00"}
                    fz="13px"
                    cl={"rgba(0, 0, 0, 0.6)"}
                  />
                </div>

                {/* line-full-bg */}
                <div className="w-full bg-[#d9d9d9] h-[.15rem]"></div>
                {/* total-fee-inc-delivery-fee */}

                <div className="flex justify-between items-center w-full">
                  <Description title="Total" fz="13px" fw="500" cl="#000000" />
                  <Description
                    title={FormatwithComma(fees?.total_price)
                      .toString()
                      .replace(/^/, "₦")}
                    fz="13px"
                    cl={"rgba(0, 0, 0, 0.6)"}
                  />
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>

      <Description
        title={titleText}
        sx={{ textAlign: "left !important", visibility: "hidden !important" }}
      />
      {/* padding-top-buttn */}
      <div className="flex items-center justify-center pt-[4rem]">
        <ButtonSide
          title={"Confirm Order"}
          bg="#4f0da3"
          cl="#ffff"
          jc="initial"
          styles={{ paddingInline: "5rem" }}
          pb={".8rem"}
          start={
            loadingorder ? (
              <AiOutlineLoading3Quarters className="animate-spin mt-[0.2rem] text-[20px] mr-4 fill-[#4f0da3] stroke-[10px]" />
            ) : null
          }
          isDisabled={loadingorder ? true : false}
          click={() => confirmOrder("ordercompleted")}
        />
      </div>
    </>
  );
};

let titleText =
  "Exceptional product! Impressive quality, versatile features, and user-friendly design. A must-have for anyone seeking top-notch performance.";
export default Buyconfirmcheckout;
