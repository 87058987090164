import { useChatStore } from "../../zustand/store";

const useSetActiveFriends = () => {
  const isActiveFriends = useChatStore((state) => state.isActiveFriends);
  const handleOnActiveFriends = useChatStore((state) => state.handleOnActiveFriends);
  const handleOffActiveFriends = useChatStore((state) => state.handleOffActiveFriends);
  
  return {
    isActiveFriends,
    handleOnActiveFriends,
    handleOffActiveFriends
  };
};

export default useSetActiveFriends;
