import { useEffect, useState } from "react";
import "./style.css";
import ConnectSearch from "../../components/ConnectComp/ConnectSearch";
import ProfileStick from "../../components/Commons/ProfileStick";
import BusinessStick from "../../components/Commons/BusinessStick";
import ClamBuss from "../BussinessDirectory/ClamBuss";
import logo from "../../assets/2gedalogo.svg";
import AD1 from "assets/images/AD1.png";
import AD2 from "assets/images/AD2.png";
import user1 from "assets/userconnect.svg";
import avatar from "assets/profile_images/USER.png";
import { default as AD3, default as AD4 } from "assets/images/AD3.png";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import RightBar from "components/RightBar";
import {
  BusinessesNearbyApi,
  NearbyUsersApi,
  StickUserApi,
  UnStickUserApi,
} from "api/services/connect";
import Spin from "components/Spin/Spin";
import toast from "react-hot-toast";
import Businesses from "pages/BussinessDirectory/Businesses";
import { AccountUpdateApi } from "api/services/connect";
import { GoogleDistanceAPI } from "api/services/connectNearbyUsers";
import { getProfileData } from "api/services/profile";

const Data = [
  {
    text: "People nearby",
  },
  {
    text: "Businesses nearby",
  },
];

const Connect = () => {
  const [activeTab, setActiveTab] = useState("People nearby");
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isBussinessOpen, setIsBussinessOpen] = useState(false);
  const [isClaimModalOpen, setIsClaimModalOpen] = useState(false);
  const [isClaimModalOpenOne, setIsClaimModalOpenOne] = useState(false);
  const [isClaimModalOpenTwo, setIsClaimModalOpenTwo] = useState(false);
  const [isClaimModalOpenThree, setIsClaimModalOpenThree] = useState(false);
  const [isClaimModalOpenDone, setIsClaimModalOpenDone] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentUserIndex, setCurrentUserIndex] = useState(0);

  const [loading, setLoading] = useState(true);
  const [NearbyUsers, setNearbyUsers] = useState([]);

  const [userDetails, setUserDetails] = useState([]);
  console.log("userDetails", userDetails);

  const [bizloading, setBizLoading] = useState(true);
  const [NearbyBiz, setNearbyBiz] = useState([]);

  const [distanceLoading, setDistanceLoading] = useState(false);

  const images = [AD1, AD2, AD3, AD4];

  const [currentUserLocation, setCurrentUserLocation] = useState(null);

  const handleClaimClickDone = (e) => {
    e.preventDefault();
    setIsClaimModalOpenDone(true);
  };

  const handleClaimClickCloseDone = () => {
    setIsClaimModalOpenDone(false);
    setIsClaimModalOpenThree(false);
    setIsClaimModalOpenTwo(false);
    setIsClaimModalOpenOne(false);
    setIsClaimModalOpen(false);
  };

  const handleClaimClickThree = (e) => {
    e.preventDefault();
    setIsClaimModalOpenThree(true);
  };

  const handleClaimClickCloseThree = () => {
    setIsClaimModalOpenThree(false);
  };

  const handleClaimClickTwo = (e) => {
    e.preventDefault();
    setIsClaimModalOpenTwo(true);
  };

  const handleClaimClickCloseTwo = () => {
    setIsClaimModalOpenTwo(false);
  };

  const handleClaimClickOne = (e) => {
    e.preventDefault();
    setIsClaimModalOpenOne(true);
  };

  const handleClaimClickCloseOne = () => {
    setIsClaimModalOpenOne(false);
  };

  const handleClaimClick = () => {
    setIsClaimModalOpen(true);
  };

  const handleClaimClickClose = () => {
    setIsClaimModalOpen(false);
  };

  const handleBussinessClose = () => {
    setIsBussinessOpen(false);
  };

  const handleBussinessClick = () => {
    setIsBussinessOpen(true);
  };

  const handleProfileClose = () => {
    setIsProfileOpen(false);
  };

  const handleTabClick = (text) => {
    setActiveTab(text);
  };

  const [originalNearbyUser, setoriginalNearbyUser] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [originalNearbyBiz, setOriginalNearbyBiz] = useState([]);
  const [searchQueryBiz, setSearchQueryBiz] = useState("");

  const nearbyUsers = async () => {
    try {
      const res = await NearbyUsersApi();
      if (res?.data?.status) {
        setNearbyUsers(res?.data?.data);
        setoriginalNearbyUser(res?.data?.data);
      }
    } catch (error) {
      console.log("nearbyUsers", error);
      toast.error(
        error.message || error.response.message || "Something went wrong!"
      );
    } finally {
      setLoading(false);
    }
  };

  const nearbyBusinesses = async () => {
    try {
      const res = await BusinessesNearbyApi();

      if (res?.data?.status) {
        setNearbyBiz(res?.data?.data);
        setOriginalNearbyBiz(res?.data?.data);
      }
    } catch (error) {
      console.log("nearbyBusinesses", error);
      toast.error(
        error.message || error.response.message || "Something went wrong!"
      );
    } finally {
      setBizLoading(false);
    }
  };

  const NextImage = () => {
    setCurrentUserIndex((prevIndex) =>
      prevIndex === NearbyUsers.length - 1 ? 0 : prevIndex + 1
    );
  };

  const PrevImage = () => {
    setCurrentUserIndex((prevIndex) =>
      prevIndex === 0 ? NearbyUsers.length - 1 : prevIndex - 1
    );
  };

  const getCurrentUserLocation = () => {
    // Request to switch-on location to get latitude and longitude
    const watchCurrentUserLiveLocation = async () => {
      if (navigator.geolocation) {
        // Continuous Watch for changes in user's location
        const watchId = navigator.geolocation.watchPosition(
          (position) => {
            const { latitude, longitude } = position.coords;

            // Create FormData object and append latitude and longitude
            const formData = new FormData();
            formData.append("latitude", latitude);
            formData.append("longitude", longitude);

            setCurrentUserLocation({ latitude, longitude });
            // Pass formData to updateCurrentUserLocationToBackend
            updateCurrentUserLocationToBackend(formData);
          },
          (error) => {
            console.error("Error getting location:", error.message);
          }
        );

        // Clear the watchPosition when component unmounts (i.e if the user logs out,it stop watching to avoid unnecessary consumption of resources and battery power.)
        return () => {
          navigator.geolocation.clearWatch(watchId);
        };
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    };
    watchCurrentUserLiveLocation();
  };

  const updateCurrentUserLocationToBackend = async (formData) => {
    try {
      const res = await AccountUpdateApi(formData);
      console.log("updated location:", res);
      if (res.status) {
        // calculateDistance();
      }
    } catch (error) {
      console.error("Error sending location to backend:", error);
      toast.error(
        error.message || error.response.message || "Something went wrong!"
      );
    }
  };

  const calculateDistance = async () => {
    const updatedNearbyUsers = await Promise.all(
      NearbyUsers.map(async (nearbyUserDetails) => {
        if (
          nearbyUserDetails?.location?.latitude === "" ||
          nearbyUserDetails?.location?.latitude == null ||
          nearbyUserDetails?.location?.longitude === "" ||
          nearbyUserDetails?.location?.longitude == null
        ) {
          return nearbyUserDetails;
        }

        const newCurrentUserLoc = {
          lat: Number(currentUserLocation?.latitude),
          lng: Number(currentUserLocation?.longitude),
        };
        const newNearbyUserLoc = {
          lat: Number(nearbyUserDetails?.location?.latitude),
          lng: Number(nearbyUserDetails?.location?.longitude),
        };

        try {
          setDistanceLoading(true);
          const res = await GoogleDistanceAPI(
            newCurrentUserLoc,
            newNearbyUserLoc
          );
          console.log("res", res);

          return {
            ...nearbyUserDetails,
            distance: res?.rows[0]?.elements[0]?.distance?.text,
          };
        } catch (error) {
          console.log("distance error: ", error);
          return nearbyUserDetails;
        } finally {
          setDistanceLoading(false);
        }
      })
    );
    console.log("updatedNearbyUserss", updatedNearbyUsers);
    setUserDetails(updatedNearbyUsers);
  };

  const [stickStates, setStickStates] = useState({});

  useEffect(() => {
    const storedStickStates = localStorage.getItem("stickStates");
    if (storedStickStates) {
      setStickStates(JSON.parse(storedStickStates));
    }
  }, []);

  const saveStickStatesToLocalStorage = (states) => {
    localStorage.setItem("stickStates", JSON.stringify(states));
  };

  const handleStick = async (singleUserId) => {
    try {
      const res = await StickUserApi(singleUserId);
      if (res?.data?.status) {
        toast.success(res?.data?.message);
        setStickStates((prevState) => {
          const newState = {
            ...prevState,
            [singleUserId]: true,
          };
          saveStickStatesToLocalStorage(newState);
          return newState;
        });
      }
    } catch (error) {
      toast.error(
        error.response.data.message || error.message || "Something went wrong!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUnstick = async (singleUserId) => {
    try {
      const res = await UnStickUserApi(singleUserId);
      if (res?.data?.status) {
        toast.success(res?.data?.message);
        setStickStates((prevState) => {
          const newState = {
            ...prevState,
            [singleUserId]: false,
          };
          saveStickStatesToLocalStorage(newState);
          return newState;
        });
      }
    } catch (error) {
      toast.error(
        error.response.data.message || error.message || "Something went wrong!"
      );
    } finally {
      setLoading(false);
    }
  };

  const Users =
    userDetails.length === 0 ? (
      <p className="text-center">No people nearby</p>
    ) : (
      userDetails.map((user, index) => (
        <div
          key={user.id}
          className="relative flex flex-col gap-[10px] px-[8px] lg:px-[24px]"
        >
          <div className="absolute inset-0 bg-black opacity-40 mb-3  rounded-b-[30px]"></div>
          <div className="relative z-10 flex flex-col items-start gap-[10px]">
            <div className="bg-white h-[50px] w-[50px] rounded-full mt-4">
              <img
                src={user?.profile_picture || avatar}
                alt="avatar"
                className="object-cover bg-no-repeat h-[50px] w-[50px] rounded-full"
              />
            </div>
            <div className="text-[16px] lg:text-[20px] text-white">
              {user?.user?.first_name || "First name"}{" "}
              {user?.user?.last_name || "Last name"}
            </div>
            <div className="font-thin text-[14px] lg:text-[16px] text-white">
              @{user?.user?.username.slice(0, 20) || "username"}
            </div>
            <div className="font-thin text-[14px] lg:text-[16px] text-white">
              {user?.address?.state || "No location yet"}
              {user?.distance && ", " + user?.distance + " away from you."}
            </div>
            <div className="font-thin text-[14px] lg:text-[16px] text-white">
              {user?.bio || "No bio yet"}
            </div>
            <div className="flex items-center gap-3 lg:gap-6 w-full justify-center">
              <div className="bg-[#4F0DA3] text-white w-[140px] h-[40px] rounded-[30px] flex items-center justify-center cursor-pointer text-[16px]">
                Chat
              </div>
              <div
                onClick={() =>
                  stickStates[user?.id]
                    ? handleUnstick(user?.id)
                    : handleStick(user?.id)
                }
                className="bg-secondaryColor text-white w-[140px] h-[40px] rounded-[30px] flex items-center justify-center cursor-pointer text-[16px]"
              >
                {stickStates[user.id] ? "Unstick" : "Stick"}
              </div>
            </div>
          </div>
        </div>
      ))
    );



  useEffect(() => {
    getCurrentUserLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    nearbyUsers();
    nearbyBusinesses();
  
  }, []);

  useEffect(() => {
    calculateDistance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [NearbyUsers]);

  const handleClearSearch = () => {
    setSearchQuery("");
    setUserDetails(originalNearbyUser);
  };

  const handleClearSearch_Biz = () => {
    setSearchQueryBiz("");
    setNearbyBiz(originalNearbyBiz);
  };

  return (
    <>
      <ClamBuss
        handleClaimClickDone={handleClaimClickDone}
        handleClaimClickCloseDone={handleClaimClickCloseDone}
        handleClaimClickThree={handleClaimClickThree}
        handleClaimClickCloseThree={handleClaimClickCloseThree}
        handleClaimClickTwo={handleClaimClickTwo}
        handleClaimClickCloseTwo={handleClaimClickCloseTwo}
        handleClaimClickOne={handleClaimClickOne}
        handleClaimClickCloseOne={handleClaimClickCloseOne}
        handleClaimClickClose={handleClaimClickClose}
        handleClaimClick={handleClaimClick}
        isClaimModalOpen={isClaimModalOpen}
        isClaimModalOpenOne={isClaimModalOpenOne}
        isClaimModalOpenTwo={isClaimModalOpenTwo}
        isClaimModalOpenThree={isClaimModalOpenThree}
        isClaimModalOpenDone={isClaimModalOpenDone}
      />
      <div className="main-containe ">
        <div className="left-side-container buss-all-container">
          {isProfileOpen && (
            <ProfileStick handleProfileClose={handleProfileClose} />
          )}

          {isBussinessOpen && (
            <BusinessStick handleBussinessClose={handleBussinessClose} />
          )}

          {!isProfileOpen && !isBussinessOpen && (
            <>
              <div className="w-[90%] lg:w-full mx-auto">
                <div className="head-line bus-dir flex justify-between w-full items-center mb-[16px] lg:hidden">
                  <div className="flex items-center gap-2">
                    <img src={logo} alt="logo" />
                    <div>Connect with other users</div>
                  </div>
                </div>

                <div className="">
                  {activeTab === "People nearby" ? (
                    <ConnectSearch
                      activeTab={activeTab}
                      userDetails={userDetails}
                      setUserDetails={setUserDetails}
                      searchQuery={searchQuery}
                      setSearchQuery={setSearchQuery}
                      handleClearSearch={handleClearSearch}
                    />
                  ) : (
                    <ConnectSearch
                      activeTab={activeTab}
                      NearbyBiz={NearbyBiz}
                      setNearbyBiz={setNearbyBiz}
                      searchQueryBiz={searchQueryBiz}
                      setSearchQueryBiz={setSearchQueryBiz}
                      handleClearSearch_Biz={handleClearSearch_Biz}
                    />
                  )}
                </div>

                <div className="mt-[16px] w-full">
                  <img
                    src={images[currentIndex]}
                    alt="banner"
                    className="w-full "
                  />
                </div>
              </div>
              <div className="select-what-display w-dis w-[95%] lg:w-[100%] mx-auto">
                {Data.map((item, index) => (
                  <div
                    key={index}
                    className={`tab-item ${
                      item.text === activeTab
                        ? "sel-act connect-tab"
                        : "anot-wid add-bor  connect-tab "
                    }`}
                    // style={{
                    //   border: "1px solid #000"
                    // }}
                    onClick={() => handleTabClick(item.text)}
                  >
                    <div className="dis-sel-name conn-t-txt">{item.text}</div>
                  </div>
                ))}
              </div>

              {activeTab === "People nearby" && loading ? (
                <Spin />
              ) : activeTab === "People nearby" && !loading ? (
                <>
                  {NearbyUsers.length === 0 ? (
                    <p className="text-center">No people nearby</p>
                  ) : (
                    <div className="bg-[#00000099] relative ">
                      <div className="flex  w-full justify-center">
                        {NearbyUsers.length !== 0 && (
                          <div className="text-[40px] absolute top-[45%] right-0 lg:right-[50px] z-[999]">
                            <button
                              disabled={
                                currentUserIndex === NearbyUsers.length - 1 &&
                                true
                              }
                            >
                              <IoIosArrowDroprightCircle
                                size={40}
                                color={
                                  currentUserIndex === NearbyUsers.length - 1
                                    ? "#b0b0b0"
                                    : "#fff"
                                }
                                onClick={NextImage}
                                className={
                                  currentUserIndex === NearbyUsers.length - 1
                                    ? "cursor-not-allowed"
                                    : "cursor-pointer"
                                }
                              />
                            </button>
                          </div>
                        )}
                        <div className=" lg:mx-[100px]">
                          <div className="relative">
                            <img
                              src={
                                NearbyUsers[currentUserIndex]
                                  ?.profile_picture || avatar
                              }
                              alt="connect-user-images"
                              className={`w-[100%]  my-[13px] h-[400px]   rounded-[30px] ${
                                avatar ? "object-cover" : "object-cover"
                              }`}
                            />
                            <div className="absolute bottom-0 text-left -mb-[10px] w-full flex flex-col justify-center">
                              {distanceLoading ? (
                                <div className="w-full flex justify-center items-center text-[#fff] text-[18px] text-center">
                                  Loading
                                  <span className="loading-dots">...</span>
                                </div>
                              ) : (
                                Users[currentUserIndex]
                              )}
                            </div>
                          </div>
                        </div>
                        {NearbyUsers.length !== 0 && (
                          <div className="text-[40px] absolute top-[45%] left-0 lg:left-[50px] z-[999] ">
                            <button disabled={currentUserIndex === 0 && true}>
                              <IoIosArrowDropleftCircle
                                size={40}
                                color={
                                  currentUserIndex === 0 ? "#b0b0b0" : "#fff"
                                }
                                onClick={PrevImage}
                                className={
                                  currentUserIndex === 0
                                    ? "cursor-not-allowed"
                                    : "cursor-pointer"
                                }
                              />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : null}

              {activeTab === "Businesses nearby" && bizloading ? (
                <Spin />
              ) : activeTab === "Businesses nearby" && !bizloading ? (
                <div className="csss w-[90%] lg:w-full mx-auto">
                  <div className=" you-may-know-bo">
                    <div className="may-know-box">
                      {NearbyBiz.length === 0 ? (
                        <p className="text-center">No businesses nearby</p>
                      ) : (
                        NearbyBiz.map((biz, index) => (
                          <Businesses
                            key={index}
                            biz={biz}
                            handleClaimClick={handleClaimClick}
                            handleBussinessClick={handleBussinessClick}
                          />
                        ))
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          )}
        </div>

        <div className="w-[70%]">
          <RightBar />
        </div>
      </div>
    </>
  );
};

export default Connect;
