import React, { useContext, useEffect, useState } from "react";
import SpecialPicksCard from "../../Components/SpecialPicksCard";
import Ad from "../../Assets/AD.jpeg";
import axios from "axios";
import Lottie from "lottie-react";
import NothingHere from "../../Assets/nothing_here.json"
import AudioPlayer2 from "pages/Stereo/AudioPlayer2/AudioPlayer";
import { MusicPlayerContext } from "pages/Stereo/context/MusicPlayerContext";

export default function Explore() {
  const [songs, setSongs] = useState([])
  const [Afrobeats, setAfrobeats] = useState([])
  const [modalOpen1, setModalOpen1] = useState(false)
  const [clickedIndex1, setClickedIndex1] = useState(null); // Initialize with null
 const [currentSong, setCurrentSong] = useState(songs[clickedIndex1])
//  const [modalOpen, setModalOpen] = useState(false)
  const [clickedIndex, setClickedIndex] = useState(null); // Initialize with null
 const [currentSong1, setCurrentSong1] = useState(Afrobeats[clickedIndex])
 const [minimized, setMinimized] = useState(false)

 
  const authToken = localStorage.getItem("authToken")
  const { tracks, setTracks, index, setIndex, modalOpen, setModalOpen, minimized1, setMinimized1 } = useContext(MusicPlayerContext)

 const handleItemClick = (index, tracks) => {
  console.log('Clicked index:', index);
  // setCurrentSong(quickpicks[clickedIndex]);
  setIndex(index); // Set the clicked index to state
    setModalOpen(!modalOpen)
    setTracks(tracks)
};

  const handleItemClick1 = (index) => {
    console.log('Clicked index:', index);
    // setCurrentSong(quickpicks[clickedIndex]);
    setClickedIndex(index); // Set the clicked index to state
  };

  const GetSongs = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/songs/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setSongs(res?.data?.data);
        console.log(songs + "songs state===");
        console.log(JSON.stringify(res.data) + "songs====");

        // Afrobeats

        // Filter the items where plays < 3
        const afroHits = res?.data?.data.filter((item) => item.category.name === "Afro beats");
        // Assuming setBigHit is a function to update state
        setAfrobeats(afroHits);
        console.log(afroHits,"afrohits");
      }).catch(e=>console.log(e));
  };

  useEffect(()=>{
    GetSongs()
  },[])
  return (
    <div>
      <div className="mx-3">
        <span className="text-xl font-medium">African Vibe</span>
      </div>
      <div
        className={songs.length>0?"flex overflow-x-scroll gap-1 sm:gap-5 mx-3 mt-3":null}
        style={{ scrollbarWidth: "none" }}>

{songs?.length>0?songs?.map((res, index)=>{
                  return (
                    <SpecialPicksCard onClick={()=>handleItemClick(index, songs)} modalOpen2={modalOpen1} setModalOpen2={setModalOpen1} img={res.cover_image} category={res.title} artist={res?.artist.artist_name} plays={res?.plays.toString()} likes={res?.likes.toString()} downloads={res?.downloads.toString()} entries={res?.entries} />
                  )
                }):<div className="flex justify-center items-center"><Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}

        {/* <SpecialPicksCard />
        <SpecialPicksCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <SpecialPicksCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        />
        <SpecialPicksCard />
        <SpecialPicksCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <SpecialPicksCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        />
        <SpecialPicksCard />
        <SpecialPicksCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <SpecialPicksCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        />
        <SpecialPicksCard />
        <SpecialPicksCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <SpecialPicksCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        /> */}
      </div>

      <div className="mx-3 mt-5">
        <span className="text-xl font-medium">Best of Afrobeats</span>
      </div>
      <div
        className={Afrobeats.length>0?"flex overflow-x-scroll gap-1 sm:gap-5 mx-3 mt-3":null}
        style={{ scrollbarWidth: "none" }}>
          {Afrobeats?.length>0?Afrobeats?.map((res,index)=>{
                  return (
                    <SpecialPicksCard onClick={()=>handleItemClick(index, Afrobeats)} modalOpen2={modalOpen} setModalOpen2={setModalOpen} img={res.cover_image} category={res.title} artist={res?.artist.artist_name} plays={res?.plays.toString()} likes={res?.likes.toString()} downloads={res?.downloads.toString()} entries={res?.entries} />
                  )
                }):<div className="flex justify-center items-center"><Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
        {/* <SpecialPicksCard />
        <SpecialPicksCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <SpecialPicksCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        />
        <SpecialPicksCard />
        <SpecialPicksCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <SpecialPicksCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        />
        <SpecialPicksCard />
        <SpecialPicksCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <SpecialPicksCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        />
        <SpecialPicksCard />
        <SpecialPicksCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <SpecialPicksCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        /> */}
      </div>

      {/* ad */}
      <div style={{ margin: "10px 10px" }}>
        <img
          src={Ad}
          alt="Advertisement"
          style={{
            height: "80px",
            objectFit: "cover",
            width: "100%",
          }}
        />
      </div>
      <div className="mx-3 mt-5">
        <span className="text-xl font-medium">Recommended</span>
      </div>
      <div
        className={songs.length>0?"flex overflow-x-scroll gap-1 sm:gap-5 mx-3 mt-3":null}
        style={{ scrollbarWidth: "none" }}>
          {songs?.length>0?songs?.map((res,index)=>{
                  return (
                    <SpecialPicksCard onClick={()=>handleItemClick(index, songs)} modalOpen2={modalOpen1} setModalOpen2={setModalOpen1} img={res.cover_image} category={res.title} artist={res?.artist.artist_name} plays={res?.plays.toString()} likes={res?.likes.toString()} downloads={res?.downloads.toString()} entries={res?.entries} />
                  )
                }).reverse():<div className="flex justify-center items-center"><Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
        {/* <SpecialPicksCard />
        <SpecialPicksCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <SpecialPicksCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        />
        <SpecialPicksCard />
        <SpecialPicksCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <SpecialPicksCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        />
        <SpecialPicksCard />
        <SpecialPicksCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <SpecialPicksCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        />
        <SpecialPicksCard />
        <SpecialPicksCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <SpecialPicksCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        /> */}
      </div>
      <div className="mx-3 mt-5">
        <span className="text-xl font-medium">Pop for you</span>
      </div>
      <div
        className={songs.length>0?"flex overflow-x-scroll gap-1 sm:gap-5 mx-3 mt-3":null}
        style={{ scrollbarWidth: "none" }}>
          {songs?.length>0?songs?.map((res, index)=>{
                  return (
                    <SpecialPicksCard onClick={()=>handleItemClick(index,songs)} modalOpen2={modalOpen1} setModalOpen2={setModalOpen1} img={res.cover_image} category={res.title} artist={res?.artist.artist_name} plays={res?.plays.toString()} likes={res?.likes.toString()} downloads={res?.downloads.toString()} entries={res?.entries} />
                  )
                }):<div className="flex justify-center items-center"><Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
        {/* <SpecialPicksCard />
        <SpecialPicksCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <SpecialPicksCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        />
        <SpecialPicksCard />
        <SpecialPicksCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <SpecialPicksCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        />
        <SpecialPicksCard />
        <SpecialPicksCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <SpecialPicksCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        />
        <SpecialPicksCard />
        <SpecialPicksCard
          img={require("../../Assets/Image6.jpeg")}
          category={"Life of the party"}
        />
        <SpecialPicksCard
          img={require("../../Assets/Image3.jpeg")}
          category={"Afrojams"}
        /> */}
      </div>
      {/* <div className="flex justify-center"> */}
      {/* {modalOpen1 && (
          <div
            id="modelConfirm"
            className={!minimized ? "fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4":""}
            // className={`flex items-center justify-center fixed inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full ${
            //   minimized ? "px-4" : ""
            // }`}
            >
            <div 
            className={!minimized ? "relative top-40 mx-auto shadow-xl bg-white max-w-[100%] lg:max-w-[30%] md:max-w-[30%] xl:max-w-[30%] rounded-xl overflow-x-hidden": "fixed bottom-0 lg:left-[30%] lg:right-[30%] xl:left-[30%] xl:right-[30%] left-0 right-0 z-50 w-full lg:w-[50%] xl:w-[50%] h-60 bg-white border-t border-gray-200"}
            // className={`${
            //   minimized ? "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" : "relative top-40 mx-auto"
            // } shadow-xl bg-white max-w-[100%] lg:max-w-[30%] md:max-w-[90%] xl:max-w-[30%] rounded-xl overflow-x-hidden`}
            >
             <main className="py-10 my-10"> */}
                {/* <MusicPlayer playerIndex={clickedIndex} songs={quickpicks} setSongs={setQuickPicks} currentSong={currentSong} setCurrentSong={setCurrentSong} src={currentSong.url} ref={audioElem} onTimeUpdate={onPlaying}/> */}
                {/* <audio ref={audioElem} onTimeUpdate={onPlaying}>
                <source src={currentSong?.audio_file?`https://development.2geda.net${currentSong?.audio_file}`:""} type="audio/mp3" />
                </audio> */}
                {/* {modalOpen1 && <AudioPlayer2 setModalOpen1={setModalOpen1} modalOpen={modalOpen1} minimized1={minimized} setMinimized1={setMinimized} tracks={songs} index={clickedIndex1}/>} */}
      {/* <Player songs={quickpicks} setSongs={setSongs} isplaying={isplaying} setisplaying={setisplaying} audioElem={audioElem} currentSong={currentSong} setCurrentSong={setCurrentSong} /> */}
              {/* </main>
            </div>
          </div>
        )} */}
        {/* </div> */}

{/* {modalOpen && (
          <div
            id="modelConfirm"
            className={!minimized ? "fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4":""}>
            <div className={!minimized ? "relative top-40 mx-auto shadow-xl bg-white max-w-[100%] lg:max-w-[30%] md:max-w-[30%] xl:max-w-[30%] rounded-xl overflow-x-hidden": "fixed bottom-0 lg:left-[30%] lg:right-[30%] xl:left-[30%] xl:right-[30%] left-0 right-0 z-50 w-full lg:w-[50%] xl:w-[50%] h-60 bg-white border-t border-gray-200"}>
             <main className="py-10 my-10"> */}
                {/* <MusicPlayer playerIndex={clickedIndex} songs={quickpicks} setSongs={setQuickPicks} currentSong={currentSong} setCurrentSong={setCurrentSong} src={currentSong.url} ref={audioElem} onTimeUpdate={onPlaying}/> */}
                {/* <audio ref={audioElem} onTimeUpdate={onPlaying}>
                <source src={currentSong?.audio_file?`https://development.2geda.net${currentSong?.audio_file}`:""} type="audio/mp3" />
                </audio> */}
                {/* {modalOpen &&<AudioPlayer2 minimized1={minimized} setMinimized1={setMinimized} setModalOpen1={setModalOpen} modalOpen={modalOpen} tracks={Afrobeats} index={clickedIndex}/>} */}
      {/* <Player songs={quickpicks} setSongs={setSongs} isplaying={isplaying} setisplaying={setisplaying} audioElem={audioElem} currentSong={currentSong} setCurrentSong={setCurrentSong} /> */}
              {/* </main>
            </div>
          </div>
        )} */}
    </div>
  );
}
