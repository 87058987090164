import { FaTimes } from "react-icons/fa";

const tags = [
  "Action",
  "Comedy",
  "Drama",
  "Horror",
  "Fantasy",
  "Romance",
  "Science-fiction",
  "Thriller",
  "Western",
  "Animation",
  "Documentary",
  "Musical",
];

const TvTagsModal = ({
  setIsModalOpen,
  selectedTags,
  setSelectedTags,
  setSelectedTagsString,
  selectedTagsString,
}) => {
  const handleTagClick = (tag) => {
    let updatedTags;

    if (selectedTags.includes(tag)) {
      updatedTags = selectedTags.filter((t) => t !== tag);
    } else {
      updatedTags = [...selectedTags, tag];
    }

    setSelectedTags(updatedTags);
    setSelectedTagsString(updatedTags.join(","));
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full md:w-1/2 lg:w-1/3 rounded-lg shadow-lg p-8 m-4 bg-white ">
        <div className="flex justify-between items-center text-[15px]">
          <div className="font-semibold">Select Movie tag</div>
          <FaTimes
            className="text-[18px]"
            onClick={() => setIsModalOpen(false)}
          />
        </div>
        <div className="w-full grid grid-cols-2 lg:grid-cols-3  lg:text-[13px] md:text-[12px] text-[12px] gap-4 mt-4">
          {tags.map((tag, index) => (
            <button
              style={{
                border: "1px solid",
                borderRadius: "4px",
                borderColor: "#b4b1b1",
              }}
              key={index}
              className={`rounded p-2 ${
                selectedTags.includes(tag)
                  ? "bg-gray-800 text-white"
                  : "bg-white text-black"
              }`}
              onClick={() => handleTagClick(tag)}
            >
              {tag}
            </button>
          ))}
        </div>
        <div className="mt-4">
          <button
            className="bg-[#4f0da3] text-white lg:text-[13px] text-[12px] px-8 py-3 rounded w-full"
            onClick={() => setIsModalOpen(false)}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default TvTagsModal;
