import { useEffect, useState } from "react";
import TickectCardFull from "../../components/TicketComp/TickectCardFull";
import { BiSearch } from "react-icons/bi";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import useGetTickets from "Hooks/useGetTickets";

const PopularTicketsAll = () => {
  const navigate = useNavigate();
  const { allEvents, allEventsRefetch } = useGetTickets();
  const popularEvents = allEvents?.data?.data?.popular_events;

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEvents, setFilteredEvents] = useState(popularEvents);

  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filtered = popularEvents?.filter((event) =>
      event.title.toLowerCase().includes(term.toLowerCase())
    );

    setFilteredEvents(filtered);
  };

  const goToHome = () => {
    navigate("/ticket");
  };

  useEffect(() => {
    allEventsRefetch();
  }, [allEventsRefetch]);

  useEffect(() => {
    setFilteredEvents(popularEvents);
  }, [popularEvents]);


  

  return (
    <div className="p-4 bg-white">
      <div className="flex w-full items-center gap-4 mt-4">
        <FaArrowLeftLong onClick={goToHome} className="text-[18px]" />
        <h2 className="font-semibold text-[17px] mt-2">Popular events</h2>
      </div>

      <div className="w-full border-none bg-neutral-100 rounded flex items-center px-4 mt-4 ">
        <BiSearch className="text-black text-[16px]" />
        <input
          type="text"
          className=" text-[14px] border-none outline-none "
          placeholder="Find event"
          value={searchTerm}
          onChange={handleInputChange}
        />
      </div>

      {filteredEvents?.length === 0 && searchTerm.length ? (
        <p className="text-[16px] mt-4">No events</p>
      ) : (
        <div className="ticket-full-box">
          {filteredEvents?.map((item, index) => (
            <TickectCardFull data={item} key={index} />
          ))}
        </div>
      )}
    </div>
  );
};

export default PopularTicketsAll;
