
import { FaArrowLeftLong } from "react-icons/fa6";
import { BiSearch } from "react-icons/bi";

const TicketSearchComp = ({ label, add, handleCloseContainerClick }) => {

  return (
    <div className="p-2">
      <div className="flex gap-4 items-center ml-2 mt-10 lg:mt-0">
        
          <FaArrowLeftLong className="text-[16px] mb-2 hover:text-[#4f0da3]" onClick={() => handleCloseContainerClick()}/>
        
        <h2 className="text-[19px] font-semibold">{label}</h2>
      </div>
      <div className={`search-product ticccc ${add} `}>
        <div className="sear-input-pro">
          <input type="text" className="inp-pro" placeholder="Search Events" />
        </div>
        <BiSearch className="seah-con" />
      </div>
    </div>
  );
};

export default TicketSearchComp;
