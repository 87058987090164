import { useEffect, useRef, useState, useMemo } from "react";
import TickectCardFull from "components/TicketComp/TickectCardFull";
import { BiSearch } from "react-icons/bi";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import useGetTickets from "Hooks/useGetTickets";

const Festival = () => {
  const navigate = useNavigate();

  const {
    allTickets,
    allTicketsRefetch,
  } = useGetTickets();

  const events = useMemo(() => allTickets?.data?.message ?? [], [allTickets]);
  const tickets = useMemo(() => events.filter(event => event.event_category === "festival"), [events]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEvents, setFilteredEvents] = useState(tickets);

  const pageRef = useRef(null);

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    allTicketsRefetch();
  }, [allTicketsRefetch]);

  useEffect(() => {
    const filtered = tickets.filter(event =>
      event.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredEvents(filtered);
  }, [searchTerm, tickets]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const goToHome = () => {
    navigate("/ticket");
  };

  return (
    <div className="p-4 bg-white" ref={pageRef}>
      <div className="flex w-full items-center gap-4 mt-4">
        <FaArrowLeftLong onClick={goToHome} className="text-[18px]" />
        <h2 className="font-semibold text-[17px] mt-2">Festival</h2>
      </div>

      <div className="w-full border-none bg-neutral-100 rounded flex items-center px-4 mt-4">
        <BiSearch className="text-black text-[16px]" />
        <input
          type="text"
          className="text-[14px] border-none outline-none"
          placeholder="Find event"
          value={searchTerm}
          onChange={handleInputChange}
        />
      </div>

      {filteredEvents.length === 0 && searchTerm ? (
        <p className="text-[16px] mt-4">No events</p>
      ) : (
        <div className="ticket-full-box">
          {filteredEvents.map((item, index) => (
            <TickectCardFull data={item} key={index} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Festival;
