/* eslint-disable eqeqeq */
import moment from "moment";

function convertTo12HourFormat(time) {
  let [hours, minutes] = time?.split(":").map(Number);

  let period = hours >= 12 ? "PM" : "AM";

  hours = hours % 12 || 12;

  let formattedHours = hours?.toString().padStart(2, "0");
  let formattedMinutes = minutes?.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes} ${period}`;
}

const TicketInfoCalender = ({ eventDetail }) => {
  const getCurrentDate = () => {
    const weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDate = moment(eventDetail?.start_date).format("DD MMM, YYYY");

    const day = moment(eventDetail?.start_date).weekday();

    return `${weekDays[day]} ${currentDate}`;
  };

 
  return (
    <div className="lg:grid lg:grid-cols-2 gap-4  my-4">
      <div className="bg-[#fff] p-4">
        <h4 className="text-[17px]">Ticket Information</h4>
        <div className="border h-[150px] flex flex-col">
          <div className="h-[50%] flex justify-between w-full p-6 items-center">
            <div className="flex-1">
              <p className="text-[16px] text-neutral-400">VIP</p>
            </div>
            <div className="mt-2 w-full flex-1">
              <p className="text-[16px] font-semibold text-center">
                {eventDetail?.vip_ticket_price?.toLocaleString()} Naira
              </p>
              <p
                className={`
                ${
                  typeof eventDetail?.vip_ticket_available === "string"
                    ? "text-red-500"
                    : "text-green-500"
                }
               text-[12px] text-center`}
              >
                {typeof eventDetail?.vip_ticket_available === "string"
                  ? "Out of stock"
                  : "Selling"}
              </p>
            </div>
          </div>
          <div
            className="h-[50%] flex justify-between p-6 items-center"
            style={{ borderTop: "1px solid #ece7e7" }}
          >
            <div className="flex-1">
              <p className="text-[16px] text-neutral-400">Regular</p>
            </div>
            <div className="mt-2 w-full flex-1">
              <p className="text-[16px] font-semibold text-center">
                {eventDetail?.regular_ticket_price?.toLocaleString()} Naira
              </p>
              <p className={`
                ${
                  typeof eventDetail?.regular_ticket_available === "string"
                    ? "text-rose-500"
                    : "text-green-500"
                }
               text-[12px] text-center`}>
                {typeof eventDetail?.regular_ticket_available === "string"
                  ? "Out of stock"
                  : "Selling"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#fff] p-4">
        <h4 className="text-[17px]">Event calendar</h4>
        <div className="border h-[150px] p-4 flex flex-col justify-center">
          <div className="">
            <div>
              <h4 className="text-black">{getCurrentDate()}</h4>
            </div>

            <div>
              <p className="text-neutral-400">
                {convertTo12HourFormat(eventDetail?.start_time)}
              </p>
            </div>

            <div>
              <div className="border-2 border-purple-500 text-black py-1 px-2 w-fit rounded">
                {typeof(eventDetail?.vip_ticket_available) === 'string' ? 0 : eventDetail?.vip_ticket_available} vip{" "}
                {eventDetail?.vip_ticket_available == 1 ? "ticket" : "tickets"}{" "}
                available
              </div>
              <div className="border-2 border-purple-500 text-black py-1 px-2 mt-3 w-fit rounded">
              {typeof(eventDetail?.regular_ticket_available) === 'string' ? 0 : eventDetail?.regular_ticket_available} regular{" "}
                {eventDetail?.regular_ticket_available == 1
                  ? "ticket"
                  : "tickets"}{" "}
                available
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketInfoCalender;
