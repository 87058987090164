import { useMutation, useQueryClient } from "@tanstack/react-query";
import { subscribeFn} from "api/services/tv";
import { useNavigate } from "react-router-dom";

const useSubscribe = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    status: subscribeStatus,
    mutate: subscribe,
    error: subscribeError
  } = useMutation({
    mutationFn: (formData) => subscribeFn(formData),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["subscribers"],
      });

    },
  });

  return {
    subscribe,
    subscribeStatus,
    subscribeError
  };
};

export default useSubscribe;
