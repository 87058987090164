import { AiOutlinePlus } from "react-icons/ai";
import IMELPhone from "components/Modals/IMELPhone";
import { useModal } from "Hooks/useModal";
import { useOpenModal } from "Hooks/useOpenModal";
import { useGetGadgets } from "Hooks/profile/useGetGadjets";
import NewEditProfileHeader from "components/PollsComp/ProfilleComp/NewEditProfileHeader";
import { Spinner } from "@nextui-org/react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useProfile } from "Hooks/profile/useProfile";

const NewPhoneImei = () => {
  const { modal } = useModal();
  const { handleClick } = useOpenModal();
  const { gadgetStatus, gadgets } = useGetGadgets();

  console.log(gadgets);

  const navigate = useNavigate();

  const title = "Phone IMEI";
  const type = "Imei";

  const imeiGadgets = gadgets?.data.filter((item) => item.category === "imei");

  const {profileData} = useProfile()
  const username = profileData?.data?.data?.user?.username

  //title="Phone IMEI" type="Imei" gadgets={imeiGadgets}

  console.log("imeiGadgets", imeiGadgets);

  return (
    <>
      <NewEditProfileHeader header="Phone IMEI" type />
      <div className="px-[15px] mb-0 py-[10px] lg:px-[10px] lg:mb-[1.5rem]">
        <div className="flex justify-between items-center">
          <div></div>
          <div
            className="w-[40px] h-[40px] flex items-center justify-center rounded bg-[#4f0da3] cursor-pointer"
            onClick={() => navigate(`/${username}/gadgets/phone-imei/new`)}
          >
            <AiOutlinePlus color="white" className="text-[18px] lg-text-[20px] "/>
          </div>
        </div>
        <div className="flex flex-col gap-[15px] py-[20px]">
          {gadgetStatus === "pending" ? (
            <Spinner />
          ) : !imeiGadgets?.length ? (
            <p className="text-[16px] text-center">You have no Phone IMEI saved</p>
          ) : (
            imeiGadgets?.map((gadget) => (
              <IMELPhone
                key={gadget?.id}
                name={gadget?.phone_name}
                id_number={gadget?.id_number}
                id={gadget?.id}
                type={type}
                category={gadget?.category}
              />
            ))
          )}
        </div>

        {/* ADDITIONAL BUTTON */}
      </div>
    </>
  );
};

export default NewPhoneImei;
