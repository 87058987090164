import { useRef, useState, useEffect } from "react";

import TvRightSide from "./TvRightSide";
import EditMovieProfileHeader from "./EditMovieProfileHeader";
import { FiCamera } from "react-icons/fi";
import { useTv } from "Hooks/tv/useTv";
import useUpdateChannel from "Hooks/tv/useUpdateChannel";
import Spinner from "components/Spinner";

const EditChannel = () => {
  const { userTvData } = useTv();

  const userTv = userTvData?.data?.data[0];

  const [cover, setCover] = useState(null);
  const [image, setImage] = useState(null);
  const [tempCover, setTempCover] = useState("");
  const [tempImage, setTempImage] = useState("");
  const [data, setData] = useState({
    name: userTv?.name ?? "",
    description: userTv?.description ?? "",
    cover_photo: userTv?.cover_photo ?? "",
    profile_photo: userTv?.profile_photo ?? "",
  });

  const { updateChannel, updateChannelStatus } = useUpdateChannel(userTv?.id);

  const fileRef = useRef(null);

  const pageRef = useRef(null);

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    handleGetRef();
  }, []);

  const handleCoverChange = (event) => {
    const file = event.target.files[0];
    setCover(file);
    setTempCover(URL.createObjectURL(file));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);
    setTempImage(URL.createObjectURL(file));
  };

  const handleChange = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const userData = {
      ...data,
      profile_photo: image,
      cover_photo: cover,
    };

    updateChannel(userData);
  };

  return (
    <div className="lg:grid lg:grid-cols-12 gap-3 min-h-screen" ref={pageRef}>
      <div className="lg:col-span-8 bg-white min-h-screen mt-2 py-2 px-4">
        <EditMovieProfileHeader header="Edit Channel" />
        <form className="px-[20px] py-[10px]" onSubmit={handleSubmit}>
          <div className="flex flex-col">
            <div className="relative w-full h-[200px] shadow-md">
              <div className="w-full h-full relative">
                {/* Image circle start */}
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[100px] h-[100px]">
                  <div className="w-full h-full relative">
                    {tempImage || data.profile_photo ? (
                      <img
                        src={tempImage || data.profile_photo}
                        alt="Main"
                        className="absolute w-full h-full object-cover rounded-full"
                      />
                    ) : (
                      <div className="w-full h-full rounded-full bg-[#4f0da3] opacity-70 flex items-center justify-center"></div>
                    )}
                    <input
                      type="file"
                      id="profile"
                      accept="image/*"
                      className="hidden"
                      ref={fileRef}
                      onChange={handleImageChange}
                    />
                    <label
                      htmlFor="profile"
                      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                    >
                      <FiCamera className="text-[30px]" />
                    </label>
                    <div className="absolute text-[40px] text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer -z-10">
                      K
                    </div>
                  </div>
                </div>
                {/*  Image circle end */}

                {tempCover || data.cover_photo ? (
                  <img
                    src={tempCover || data.cover_photo}
                    alt="User"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="bg-slate-300 h-full w-full flex justify-center items-center"></div>
                )}
              </div>
              <input
                type="file"
                id="cover"
                accept="image/*"
                className="hidden"
                ref={fileRef}
                onChange={handleCoverChange}
              />
              <label
                htmlFor="cover"
                className="absolute right-6 top-6 cursor-pointer text-[18px]"
              >
                <FiCamera className="text-[30px]" />
              </label>
            </div>
          </div>
          <div className="flex flex-col gap-[10px]">
            <div className=" mt-6">
              <div
                className="mt-4"
                style={{
                  border: "none",
                  borderBottom: "1px solid",
                  borderRadius: "0",
                  borderColor: "#c9c4c4",
                }}
              >
                <label className="text-[18px] ml-[5px]">Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={data?.name}
                  onChange={handleChange}
                  required
                  className="px-2 py-2 pb-3 text-[14px] outline-none border-none"
                />
              </div>

              <div>
                <label className="text-[18px] ml-[5px] mt-10">
                  Description
                </label>
                <div
                  className="w-full grid grid-cols-8 items-center border-neutral-300 "
                  style={{
                    border: "none",
                    borderBottom: "1px solid",
                    borderRadius: "0",
                    borderColor: "#c9c4c4",
                  }}
                >
                  <input
                    type="text"
                    name="description"
                    placeholder="Channel description"
                    value={data?.description}
                    onChange={handleChange}
                    required
                    className=" px-2 py-2 pb-3 text-[14px] col-span-7 outline-none border-none"
                  />
                  {/* <div className="col-span-1 text-[14px] flex justify-end px-2">
                    
                  </div> */}
                </div>
              </div>
            </div>

            <button className="w-[60%] py-4 px-2 mt-4 mx-auto lg:text-[16px] text-[15px] bg-[#4f0da3] rounded text-white">
              {updateChannelStatus === "pending" ? <Spinner /> : "Edit"}
            </button>
          </div>
        </form>
      </div>
      <div className="lg:col-span-4 hidden lg:block bg-white mt-2 px-4 py-2 ">
        <TvRightSide />
      </div>
    </div>
  );
};

export default EditChannel;
