import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import TopAlbumCard from '../Components/TopAlbumCard';
import Lottie from "lottie-react";
import NothingHere from "../Assets/nothing_here.json"
import AudioPlayer2 from '../AudioPlayer2/AudioPlayer';
import { MusicPlayerContext } from '../context/MusicPlayerContext';

export default function TopAlbumsSection() {
    const [topAlbums, setTopAlbums] = useState([])
    const [AlbumSongs, setAlbumSongs] = useState([]);
 const [Albums, setAlbums] = useState([])
    const authToken = localStorage.getItem("authToken")
  const [minimized,setMinimized] = useState(false)
  const [modalOpen3, setModalOpen3] = useState(false)
  const navigation = useNavigate()
  const { tracks, setTracks, index, setIndex, modalOpen, setModalOpen, minimized1, setMinimized1 } = useContext(MusicPlayerContext)

  const GetTopAlbums = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/albums/top-album/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setTopAlbums(res?.data?.data);
        console.log(topAlbums + "topAlbums state===");
        console.log(JSON.stringify(res.data) + "topAlbums====");
      }).catch(e=>console.log(e));
  };


  const handleAlbumSongsList = async(id) => {
    // setAlbumId(id)
    await axios.get(`https://api.2geda.net/api/stereo/artist/albums/${id}/songs/`,  {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "X-CSRFToken": process.env.REACT_TOKEN,
      },
    }).then((res)=>{
      setAlbumSongs(res?.data?.data)
      setTracks(res?.data?.data)
      setModalOpen(!modalOpen)
      console.log(res?.data?.data, "album songs====")
    }).catch(e => console.log(e))
  }

  useEffect(()=>{
    GetTopAlbums()
  },[])
  return (
    <div>
      
      <div
              className={topAlbums?.length>0?"flex mx-3 gap-3 overflow-x-scroll justify-between h-full":null}
                style={{
                  // display: "flex",
                  // marginLeft: 10,
                  // marginRight: 10,
                  // gap: 10,
                  // overflowX: "scroll",
                  scrollbarWidth: "none",
                  // justifyContent: "space-between",
                  // height: "100%",
                }}>
                                      {topAlbums?.length>0?topAlbums?.map(res=>{
                  return (
                  <TopAlbumCard onClick={()=>handleAlbumSongsList(res.id)} name={res.name} img={res.cover_image} artist={res.artist.artist_name}  />
                  )
                }):<div className="flex justify-center items-center"><Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
                {/* <HitsCard name={"When we fall asleep"} />
                <HitsCard
                  img={require("../Assets/Image3.jpeg")}
                  name={"I told them"}
                  artist={"Burna Boy"}
                />
                <HitsCard img={require("../Assets/Image4.jpeg")} />
                <HitsCard />
                <HitsCard img={require("../Assets/Image4.jpeg")} />
                <HitsCard />
                <HitsCard img={require("../Assets/Image4.jpeg")} />
                <HitsCard /> */}
              </div>

              {/* {modalOpen3 && (
          <div
          id="modelConfirm"
          className={!minimized ? "fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4":""}>
          <div className={!minimized ? "relative top-40 mx-auto shadow-xl bg-white max-w-[100%] lg:max-w-[30%] md:max-w-[30%] xl:max-w-[30%] rounded-xl overflow-x-hidden": "fixed bottom-0 lg:left-[30%] lg:right-[30%] xl:left-[30%] xl:right-[30%] left-0 right-0 z-50 w-full lg:w-[50%] xl:w-[50%] h-60 bg-white border-t border-gray-200"}>
             <main className="py-10 my-10"> */}
                {/* <MusicPlayer playerIndex={clickedIndex} songs={quickpicks} setSongs={setQuickPicks} currentSong={currentSong} setCurrentSong={setCurrentSong} src={currentSong.url} ref={audioElem} onTimeUpdate={onPlaying}/> */}
                {/* <audio ref={audioElem} onTimeUpdate={onPlaying}>
                <source src={currentSong?.audio_file?`https://development.2geda.net${currentSong?.audio_file}`:""} type="audio/mp3" />
                </audio> */}
                {/* {modalOpen3 && <AudioPlayer2 minimized1={minimized} setMinimized1={setMinimized} setModalOpen1={setModalOpen3} modalOpen={modalOpen3} tracks={AlbumSongs} index={0}/>} */}
      {/* <Player songs={quickpicks} setSongs={setSongs} isplaying={isplaying} setisplaying={setisplaying} audioElem={audioElem} currentSong={currentSong} setCurrentSong={setCurrentSong} /> */}
              {/* </main>
            </div>
          </div>
        )} */}

    </div>
  )
}
