import React from 'react'
import './feed-file.css'
import { useFetchFiles } from 'api/services/feeds'
import {
  FaFilePdf,
  FaFileWord,
  FaFileExcel,
  FaFileImage,
  FaFileVideo,
  FaFileAlt,
} from 'react-icons/fa'
import EmptyImage from '../../../../assets/2gede_nothing_here.svg'


const Feedfiles = () => {
  const { data: getFiles, isPending } = useFetchFiles()

  const fileBaseUrl = `https://2geda-backend.s3.amazonaws.com`

  const getFileIcon = (filename) => {
    const extension = filename.split('.').pop().toLowerCase()
    switch (extension) {
      case 'pdf':
        return <FaFilePdf size={22} color='red' />
      case 'doc':
      case 'docx':
        return <FaFileWord size={22} color='blue' />
      case 'xls':
      case 'xlsx':
        return <FaFileExcel size={22} color='green' />
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
        return <FaFileImage size={22} color='grey' />
      case 'mp4':
      case 'avi':
      case 'mov':
        return <FaFileVideo size={22} color='orange' />
      default:
        return <FaFileAlt size={22} color='grey' />
    }
  }

  if (isPending) {
    return (
      <div className='feed-images-container'>
        <div className='feed-images-loader'>
          <div className='feed-images-loader-inner'>
            <div className='feed-images-loader-text'>Loading...</div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
        gap: '1rem',
      }}
    >
      <div className='pb-8'>
        {getFiles?.length === 0 && (
          <div className='text-3xl'>
            <h1>No image at the moment, please come back later</h1>
            <img src={EmptyImage} alt='nothing here' />
          </div>
        )}
      </div>
      {getFiles?.map((item) => (
        <>
          <div key={item?.file_id} className='feed-files-card'>
            <a
              href={item?.file}
              target='_blank'
              rel='noopener noreferrer'
              style={{ textDecoration: 'none' }}
            >
              <div className='file-icon'>{getFileIcon(item.file)}</div>
              <p>complex tuto...</p>
            </a>
          </div>
        </>
      ))}
    </div>
  )
}

export default Feedfiles
