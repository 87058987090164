import { FaArrowLeftLong } from "react-icons/fa6";
import logo from "../../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { useProfile } from "Hooks/profile/useProfile";

const NewEditProfileHeader = ({ header, type, back }) => {

  const {profileData} = useProfile()
  const username = profileData?.data?.data?.user?.username

  const goBack = () => {
    navigate(`/${username}`);
  };

  const goToGadgets = () => {
    navigate(`/${username}/gadgets`);
  };

  const goPrevious = () => {
    navigate(-1)
  }

  const navigate = useNavigate();
  return (
    <div className="px-[15px] py-[10px] flex justify-between items-center !border-solid !border-b-2 border-t-0 border-l-0 border-r-0 !border-[#e8e8e8ad]">
      <button onClick={back ? goPrevious : type ? goToGadgets : goBack}>
        <FaArrowLeftLong className="text-[#222222] text-[20px]" />
      </button>

      <h2 className="title text-[#000] lg:text-[17px] text-[16px]">{header}</h2>

      <img
        src={logo}
        alt="2geda"
        className="w-[45px] h-[45px] object-contain"
      />
    </div>
  );
};

export default NewEditProfileHeader;
