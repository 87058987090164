import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import TvRightSide from "./TvRightSide";
import { FaDownload, FaHeart, FaPlay, FaStop } from "react-icons/fa";
import SingleMovieCard from "./SingleMovieCard";
import ads from "../../assets/profile_images/Jumia-Celebrate-the-Game.png";
import { useGetAllMovies } from "Hooks/tv/useGetAllMovies";
import Spin from "components/Spin/Spin";
import useLikeMovie from "Hooks/tv/useLikeMovie";
import useUnlikeMovie from "Hooks/tv/useUnlikeMovie";
import { useGetLikedMovies } from "Hooks/tv/useGetLikedMovies";
import Spinner from "components/Spinner";
import useIncrementPlays from "Hooks/tv/useIncrementPlays";
import { useGetMovieStats } from "Hooks/tv/useGetVideoStats";
import useWatchMovie from "Hooks/tv/useWatchMovie";
import girl from "../../assets/live_images/light-girl.jpg";
import { useGetComments } from "Hooks/tv/useGetComments";
import usePostComment from "Hooks/tv/usePostComment";
import useDownloadMovie from "Hooks/tv/useDownloadMovie";

const SingleMovie = () => {
  const { id } = useParams();
  const [isPlay, setIsPlay] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [content, setContent] = useState("");

  const navigate = useNavigate();
  const { allMovies, isAllMoviesLoading } = useGetAllMovies();
  const { likeMutate, likeStatus } = useLikeMovie();
  const { unlikeMutate, unlikeStatus } = useUnlikeMovie(id);
  const { likedMovies } = useGetLikedMovies();

  /* const { movieStats, isMovieStatsLoading, movieStatsError } =
  useGetMovieStats(id); */

  const { watchMovie, watchMovieStatus } = useWatchMovie();
  const { downloadMovie, downloadMovieStatus, downloadMovieError } =
    useDownloadMovie();
  const { incrementPlays, incrementPlaysStatus, incrementPlaysError } =
    useIncrementPlays();
  const { comments, isGetCommentsLoading, commentsError } = useGetComments(id);
  const { postComment, postCommentStatus } = usePostComment();

  // eslint-disable-next-line eqeqeq
  const movie = allMovies?.data?.results?.find((item) => item.id == id);
  const similarMovies = allMovies?.data?.results?.filter(
    (item) => item.id !== movie?.id
  );

  const pageRef = useRef(null);
  const videoRef = useRef(null);

  const handlePostComment = () => {
    if (content) {
      const formData = { video: id, content: content };
      postComment(formData);
      setContent("");
    }
  };

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
    }
  }, [id]);

  useEffect(() => {
    setIsPlay(false);
  }, [id]);

  useEffect(() => {
    const allLiked = likedMovies?.data?.results?.map(
      (likedItem) => likedItem?.video?.id
    );
    setIsClicked(allLiked?.includes(parseInt(id)));
  }, [likedMovies, id]);

  const goToHome = () => {
    navigate(-1);
  };

  const handleLikeMovie = () => {
    const formData = { video: id };
    likeMutate(formData);
    setIsClicked(true);
  };

  const handleUnlikeMovie = () => {
    unlikeMutate();
    setIsClicked(false);
  };

  const handleDownloadMovie = () => {
    const formData = { video: id };
    downloadMovie(formData);
  };

  const playVideo = () => {
    if (videoRef.current) {
      if (isPlay) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
        incrementPlays({ video: id });
        watchMovie({ video: id });
      }
      setIsPlay(!isPlay);
    }
  };

  return (
    <div className="lg:grid lg:grid-cols-12 gap-3 min-h-screen" ref={pageRef}>
      <div className="lg:col-span-8 bg-white mt-2">
        {isAllMoviesLoading ? (
          <Spin />
        ) : (
          <div className="w-full h-[400px] relative bg-black">
            <video
              ref={videoRef}
              src={movie?.file}
              className="w-[100%] h-[100%] object-cover"
              controls={isPlay}
            />
            <div className="h-12 w-12 bg-neutral-400 rounded-full cursor-pointer left-4 top-4 flex items-center absolute justify-center">
              <IoArrowBackOutline
                className="text-[20px] text-white"
                onClick={goToHome}
              />
            </div>
            {!isPlay && (
              <div className="h-[70px] w-full bg-black opacity-60 absolute bottom-0 flex flex-col gap-2 p-2">
                <div className="text-[18px] text-white">{movie?.title}</div>
                <div className="flex items-center gap-2 text-[12px]">
                  {movie?.tags
                    ?.map((tag) => tag.name)
                    ?.map((item, index) => (
                      <div
                        className="bg-[#FF8A15] py-2 px-4 rounded text-white"
                        key={index}
                      >
                        {item}
                      </div>
                    ))}
                </div>
              </div>
            )}
            <button
              className="flex items-center justify-center rounded gap-2 mt-4 p-3 w-full bg-[#4f0da3] text-white"
              onClick={playVideo}
            >
              {isPlay ? <FaStop /> : <FaPlay className="text-[13px]" />}
              <span className="text-[13px]">
                {isPlay ? "Stop video" : "Play video"}
              </span>
            </button>
            <div className="grid grid-cols-2 gap-2 mt-2">
              <button
                className="bg-slate-300 flex justify-center gap-2 items-center p-3 rounded"
                onClick={isClicked ? handleUnlikeMovie : handleLikeMovie}
              >
                {likeStatus === "pending" ||
                unlikeStatus === "pending" ? null : (
                  <FaHeart
                    className={`text-[13px] mb-1 ${
                      isClicked ? "text-[#4f0da3]" : "text-black"
                    }`}
                  />
                )}
                <span className="text-[13px]">
                  {likeStatus === "pending" || unlikeStatus === "pending" ? (
                    <Spinner />
                  ) : isClicked ? (
                    "Unlike"
                  ) : (
                    "Like"
                  )}
                </span>
              </button>
              <button
                className="bg-slate-300 flex justify-center gap-2 items-center p-3 rounded"
                onClick={handleDownloadMovie}
              >
                <FaDownload className="text-[13px] mb-1" />
                <span className="text-[13px]">Download</span>
              </button>
            </div>
            <div className="flex flex-col gap-2 mt-4 p-2">
              <span className="text-[15px] font-semibold">
                About this movie
              </span>
              <span className="text-[13px]">{movie?.description}</span>
            </div>
            <div className="mt-4">
              <span className="text-[15px] font-semibold p-2">
                People also viewed
              </span>
              <SingleMovieCard data={similarMovies} />
              <div className="">
                <div className="hidden lg:block">
                  <img
                    src={ads}
                    alt="Ads"
                    className="w-full h-full object-contain"
                  />
                </div>
                <div className=" mb-4 bg-white px-2 pb-4 pt-8">
                  <div className="flex items-center gap-2">
                    <h4 className="mt-2">Comments</h4>
                    <div className="p-2 bg-[#4f0da3] text-white w-[30px] h-[30px] rounded flex items-center justify-center">
                      {comments?.data?.data?.length}
                    </div>
                  </div>

                  <div className="mb-4">
                    {comments?.data?.data?.map((item, index) => (
                      <div className="bg-white rounded" key={index}>
                        <div className="bg-neutral-100 h-[1px] mt-2"></div>
                        <div className="flex items-center gap-2 px-2">
                          <div className="h-10 w-10 rounded-full">
                            <img
                              src={item.profile?.profile_picture}
                              alt=""
                              className="w-[100%] h-[100%] object-cover rounded-full"
                            />
                          </div>
                          <p className="mt-3 font-semibold text-[13px]">
                            {item?.profile?.first_name}{" "}
                            {item?.profile?.last_name}
                          </p>
                        </div>
                        <div className="px-2">
                          <p className="text-neutral-500 text-[14px]">
                            {item?.content}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="h-[100px] w-full bg-neutral-100 my-6 py-4 px-4 rounded">
                    <p className="ml-1">Comment</p>

                    <div className=" flex items-center gap-2 w-full">
                      <input
                        type="text"
                        className="bg-white border-none text-[14px] outline-none w-[85%]"
                        onChange={(e) => setContent(e.target.value)}
                        value={content}
                      />
                      <button
                        className="text-white bg-[#4f0da3] text-[13px] rounded py-3 px-3 w-[80px]"
                        onClick={handlePostComment}
                      >
                        {postCommentStatus === "pending" ? <Spinner /> : "Post"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="lg:col-span-4 hidden lg:block bg-white mt-2 px-2 py-2">
        <TvRightSide />
      </div>
    </div>
  );
};

export default SingleMovie;
