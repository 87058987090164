import React from "react";
import More from "../Assets/codicon_kebab-vertical.svg";
import Indicator from "../Assets/audioIndicator.svg"
import { BiPlay } from "react-icons/bi";

export default function TopSongsCard({ id, title, img, onClick, modalOpen, setModalOpen }) {
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-2">
        <p className="font-normal text-base">{id}.</p>
        
        <img
          src={img?img:require("../Assets/Image4.jpeg")}
          className="min-w-[50px] max-w-[50px] min-h-[50px] max-h-[50px] rounded-md"
        />
        <p className="text-base font-normal">{title?title: "UNKNOWN SONG"}</p>
      </div>
      <button className="w-[24] h-[24]" onClick={()=>{onClick(); setModalOpen(!modalOpen)}}>
        {/* <img src={More} /> */}
        <BiPlay size={20}/>
      </button>
    </div>
  );
}
