import React, { useEffect, useState } from "react";
import ArtistTabCard from "../../Components/ArtistTabCard";
import Ad from "../../Assets/AD.jpeg";
import Modal from "../../Components/Modals/ModalWrapper1";
import ArtistProfile from "../ArtistProfile";
import axios from "axios";
import Lottie from "lottie-react";
import NothingHere from "../../Assets/nothing_here.json"
import ModalWrapper2 from "pages/Stereo/Components/Modals/ModalWrapper2";
import {Modal as Modal2} from "react-responsive-modal"

export default function Artists() {
  const [isOpen, setIsOpen] = useState(false);
  const [artists, setArtists] = useState([]);
  const [selectedArtistId, setSelectedArtistId] = useState(null);
  const [Albums, setAlbums] = useState([])
  const [songs, setSongs] = useState([])


  

  const authToken = localStorage.getItem("authToken")

  const handleOpenModal = (artistId) =>{setIsOpen(true); setSelectedArtistId(artistId)};
  const handleCloseModal = () => setIsOpen(false);
  const GetArtists = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/artists/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setArtists(res.data.data);
        console.log(JSON.stringify(artists) + "artist state===");
        console.log(JSON.stringify(res.data.data) + "artists====");
      }).catch(e=>console.log(e));
  };

  const GetAlbums = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/albums/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setAlbums(res?.data?.data);
        //const albums = Albums.filter(album => album.artist.artist_name === artists?.artist_name);
        //setAlbums(albums)
        console.log(JSON.stringify(Albums) + "Albums artist state===");
        console.log(JSON.stringify(res.data) + "Albums artist====");
      }).catch(e=>console.log(e));
  };

  const GetBigHits = () => {
    axios
       .get(`https://api.2geda.net/api/stereo/songs/`, {
        headers: {
         Authorization: `Bearer ${authToken}`,
         "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
     .then((res) => {
        // Filter the items where plays < 3
   setSongs(res.data.data)
  //       const filteredHits = res?.data?.data.filter((item) => item.plays > 0);
  //       // Assuming setBigHit is a function to update state
  //       setBigHit(filteredHits);
  //       console.log(filteredHits,"bighits==="); // This will log the filtered items
   })
     .catch((error) => {
       console.error('Error fetching data:', error);
       });
   };

  useEffect(() => {
    GetArtists();
    GetAlbums();
    GetBigHits();
  }, []);
  return (
    <div className="mx-4">
      <div className={artists.length>0?`grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6`:null}>
        {artists.length>0?artists.map(artist =>{
          return(<div><ArtistTabCard name={artist.artist_name} image={artist.brand_image} onClick={()=>handleOpenModal(artist.id)} />
          
          </div>)
        }):<div className="flex justify-center items-center">
          <Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
        {/* <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} />
        <ArtistTabCard onClick={handleOpenModal} /> */}
      </div>
      {/* ad */}
      <div className="mt-4" style={{}}>
        <img
          src={Ad}
          alt="Advertisement"
          style={{
            height: "80px",
            objectFit: "cover",
            width: "100%",
          }}
        />
      </div>
        <Modal2 open={isOpen} onClose={handleCloseModal} center styles={{zIndex: 3000}} isOpen={isOpen}>
          <ArtistProfile Albums={Albums} songs={songs} onClick={handleCloseModal} id={selectedArtistId} />
        </Modal2>
    </div>
  );
}
