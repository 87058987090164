import React, { useEffect, useState } from "react";
import CardImg from "../Assets/Image2.jpeg";
import Indicator from "../Assets/audioIndicator.svg"
import axios from "axios";

export default function HitsCard({ name, artist, img, audio, id, activePlayer, setActivePlayer, onClick, setModalOpen1, modalOpen1 }) {
  const [isPlaying, setIsPlaying] = useState(false)
  const [plays, setPlays] = useState()
  // const [song] = useState(new Audio(audio))
  const authToken = localStorage.getItem("authToken")

  const GetPlays = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/songs/${id}/play/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setPlays(res?.data?.data);
        console.log(plays + "plays state===");
        console.log(JSON.stringify(res.data) + "plays====");
      }).catch(e=>console.log(e));
  };

  const handlePlaySong = () => {
    GetPlays()
    setIsPlaying(true)
    // song.play()
  }
  const handlePauseSong = () => {
    setIsPlaying(false)
    // song.pause()
  }

  // const togglePlay = () => {
  //   // if (activePlayer !== null && activePlayer !== song) {
  //     // activePlayer.pause();
  //     // setIsPlaying(!isPlaying)
  //   }
  //   if (isPlaying) {
  //     // song.pause();
  //     // setIsPlaying(!isPlaying)
  //   } else {
  //     // song.play();
  //     // setIsPlaying(!isPlaying)
  //     // setActivePlayer(song);
  //   }
  //   setIsPlaying(!isPlaying);
  // };
  useEffect(()=>{
    console.log(audio, "molly===")
  },[])
  return (
    <div
    onClick={()=>{onClick(); setModalOpen1(!modalOpen1); GetPlays()}}
      style={{ minWidth: 168, minHeight: 192, maxWidth: 168, maxHeight: 192 }}>
      <div width={"100%"} className="relative" style={{ minHeight: 150, maxHeight: 150 }}>
        <img
          src={img ? img : CardImg}
          width={"100%"}
          style={{ minHeight: 150, maxHeight: 150 }}
          className="rounded-md"
          alt=""
        />
        <img src={Indicator} alt="" className="w-[44px] h-[22px] absolute top-[50%] left-[40%]"/>
      </div>
      <main className="flex flex-col justify-center items-center">
        <label style={{fontSize:"16px", fontWeight:"400", color:"black"}}>
          {name ? name : "Unknown song"}
        </label>
        <label style={{fontSize:"12px", fontWeight:"400", color:"#403F3F"}}>
          {artist ? artist : "Unknown Artist"}
        </label>
      </main>
    </div>
  );
}
