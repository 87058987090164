import CommentPerPost from '../../components/Dashboard/CommentPerPost'
import { FaArrowLeftLong } from 'react-icons/fa6'
import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Quickdial from './Quickdial'
import './feed-detail.css'
import Follower from 'components/Dashboard/Follower'
import DashMessage from 'components/Dashboard/DasMess'
import SelectCategory from 'components/Dashboard/SelectCategory'
import {
  useGetComments,
  useGetGoogleLocation,
  useGetPostById,
} from 'api/hooks/feeds'
import DisplayMedia from 'components/Home/Displaymedia/Displaymedia'
import LocationMap from 'components/Modals/Post-form-modals/LocationMap'
import { MdLocationOn } from 'react-icons/md'
import Box from '@mui/material/Box'
import imgAds1 from '../../assets/images/ads1.png'
import imgAds2 from '../../assets/images/ads2.png'
import imgAds3 from '../../assets/images/ads3.png'
import { convertPostTime } from 'utils/helper'
import ProfileAds from 'components/ProfileComponents/ProfileAds'

const FeedDetail = () => {
  const { feedId } = useParams()
  const { data } = useGetPostById(feedId)
  const getComments = useGetComments(feedId)
  const navigate = useNavigate()

  const coordinates = data?.location?.split(',')
  const getLocation = useGetGoogleLocation({
    latitude: coordinates?.[0],
    longitude: coordinates?.[1],
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div
      //  className='main-containe'
      className='lg:grid lg:grid-cols-12'
    >
      <Box
        className='lg:col-span-7 bg-white lg:overflow-y-auto lg:max-h-screen'
        //  className='md:w-full !h-full lg:max-w-[636px]'
      >
        <div className='left-side-container'>
          <div className='feed-detail-container'>
            <div className='feed-top'>
              <FaArrowLeftLong
                size={20}
                color='#000'
                onClick={() => {
                  navigate(-1)
                }}
                style={{ cursor: 'pointer' }}
              />
              <h3>Full preview</h3>
            </div>
            <hr className='feed_hr' />
            <div className='profile-time'>
              <div
                className='post-profile !cursor-pointer lg:px-4'
                onClick={() => {
                  navigate(`/${data?.user?.username}`)
                }}
              >
                {data?.user && <img src={data?.user?.profile_image} alt='' />}
                <div className='post-profile-details'>
                  {data?.user && data?.user?.username && (
                    <div className=''>
                      <div className='post-profile-name'>
                        {data?.user.username}
                      </div>
                      <div className='grid gap-1'>
                        <span>{data?.user?.occupation || 'Unemployed'}</span>
                        <span>
                          {data?.user?.location?.state || 'Address unknown'}
                        </span>
                      </div>
                    </div>
                  )}

                  {/* {creator && creator.address && (
								<div className="autor-location">
									{creator.address.current_city},{creator.address.country}
								</div>
							)} */}
                </div>
              </div>
              {data && (
                <div className='time-posted'>
                  {convertPostTime(data?.created_at)}
                </div>
              )}
            </div>
            <div className='post-preview-container px-12 lg:px-4'>
              <div className='post-display-images'>
                <div className='post-display-content'>
                  <p>
                    {data?.text_content === 'null' ? '' : data?.text_content}
                  </p>
                </div>
                {data?.files?.map((file) => (
                  <DisplayMedia mediaFile={file} key={file?.file_id} />
                ))}
              </div>
              {data?.location != null && (
                <div className='location-container'>
                  <div className='location-address'>
                    <MdLocationOn /> {getLocation?.data?.[0]?.formatted_address}
                  </div>
                  <div className='location-wrapper'>
                    {coordinates?.[0] && coordinates?.[1] && (
                      <LocationMap
                        lat={Number(coordinates?.[0])}
                        lng={Number(coordinates?.[1])}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className='comm-bx-pos'>
              {getComments.data?.comments?.map((comment) => (
                <CommentPerPost
                  comment={comment}
                  key={comment?.id}
                  postId={feedId}
                />
              ))}
            </div>
            <div className='quick-dial-container'>
              <Quickdial postId={feedId} />
            </div>
          </div>
        </div>
      </Box>
      <div className='lg:col-span-2 hidden lg:block bg-white mx-3 lg:h-screen'>
        <ProfileAds />
      </div>
      <div className='lg:col-span-3 hidden lg:block lg:h-screen'>
        <Follower />
        <div className=''>
          <DashMessage />
        </div>
      </div>
    </div>
  )
}

export default FeedDetail
