import React, { useContext, useEffect, useState } from "react";
import MoreCard from "../../Components/MoreCard";
import Ad from "../../Assets/AD.jpeg";
import Lottie from "lottie-react";
import NothingHere from "../../Assets/nothing_here.json"
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AudioPlayer2 from "pages/Stereo/AudioPlayer2/AudioPlayer";
import { MusicPlayerContext } from "pages/Stereo/context/MusicPlayerContext";

export default function TopTracks() {
  const [songs, setSongs] = useState([])
  const authToken = localStorage.getItem("authToken")
  const [modalOpen1, setModalOpen1] = useState(false)
  const [clickedIndex1, setClickedIndex1] = useState(null); // Initialize with null
 const [currentSong, setCurrentSong] = useState(songs[clickedIndex1])
 const [minimized, setMinimized] = useState(false)
  const navigation = useNavigate()
  const { tracks, setTracks, index, setIndex, modalOpen, setModalOpen, minimized1, setMinimized1 } = useContext(MusicPlayerContext)

  const handleItemClick = (index, tracks) => {
    console.log('Clicked index:', index);
    // setCurrentSong(quickpicks[clickedIndex]);
    setIndex(index); // Set the clicked index to state
    setModalOpen(!modalOpen)
    setTracks(tracks)
  };
  

  const GetSongs = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/songs/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setSongs(res?.data?.data);
        console.log(songs + "songs state===");
        console.log(JSON.stringify(res.data) + "songs====");

        
      }).catch(e=>console.log(e));
  };

  useEffect(()=>{
    GetSongs()
  },[])

  return (
    <div>
      <div>
        <section style={{ marginTop: 10 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: 10,
              marginLeft: 10,
              gap: 20,
            }}>
            {songs?.length>0?songs?.map((res, index)=>{
                  return (
                    <MoreCard onClick={()=>handleItemClick(index,songs)} modalOpen={modalOpen1} setModalOpen={setModalOpen1} img={res.cover_image} title={res.title} artist={res?.artist.artist_name} plays={res?.plays.toString()} likes={res?.likes.toString()} downloads={res?.downloads.toString()} entries={res?.entries} />
                  )
                }).reverse():<div className="flex justify-center items-center"><Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
            {/* ad */}
            <div style={{}}>
              <img
                src={Ad}
                alt="Advertisement"
                style={{
                  height: "80px",
                  objectFit: "cover",
                  width: "100%",
                }}
              />
            </div>
            {/* <MoreCard title={"Take me home"} />
            <MoreCard title={"Take me home"} />
            <MoreCard title={"Take me home"} />
            <MoreCard title={"Take me home"} />
            <MoreCard title={"Take me home"} /> */}
            {/* ad */}
            {/* <div style={{}}>
              <img
                src={Ad}
                alt="Advertisement"
                style={{
                  height: "80px",
                  objectFit: "cover",
                  width: "100%",
                }}
              />
            </div> */}
          </div>
        </section>
      </div>
      {/* {modalOpen1 && (
          <div
            id="modelConfirm"
            className={!minimized ? "fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4":""}>
            <div className={!minimized ? "relative top-40 mx-auto shadow-xl bg-white max-w-[100%] lg:max-w-[30%] md:max-w-[30%] xl:max-w-[30%] rounded-xl overflow-x-hidden": "fixed bottom-0 lg:left-[30%] lg:right-[30%] xl:left-[30%] xl:right-[30%] left-0 right-0 z-50 w-full lg:w-[50%] xl:w-[50%] h-60 bg-white border-t border-gray-200"}>
             <main className="py-10 my-10"> */}
                {/* <MusicPlayer playerIndex={clickedIndex} songs={quickpicks} setSongs={setQuickPicks} currentSong={currentSong} setCurrentSong={setCurrentSong} src={currentSong.url} ref={audioElem} onTimeUpdate={onPlaying}/> */}
                {/* <audio ref={audioElem} onTimeUpdate={onPlaying}>
                <source src={currentSong?.audio_file?`https://development.2geda.net${currentSong?.audio_file}`:""} type="audio/mp3" />
                </audio> */}
                {/* {modalOpen1 && <AudioPlayer2 minimized1={minimized} setMinimized1={setMinimized} setModalOpen1={setModalOpen1} modalOpen={modalOpen1} tracks={songs} index={clickedIndex1}/>} */}
      {/* <Player songs={quickpicks} setSongs={setSongs} isplaying={isplaying} setisplaying={setisplaying} audioElem={audioElem} currentSong={currentSong} setCurrentSong={setCurrentSong} /> */}
              {/* </main>
            </div>
          </div>
        )} */}
    </div>
  );
}
