import { useEffect, useRef, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import TvCardFull from "./TvCardFull";
import { libraryMovies } from "./Tv";
import TvRightSide from "./TvRightSide";
import { useGetAllMovies } from "Hooks/tv/useGetAllMovies";

const PlayedMovies = () => {
  const navigate = useNavigate();

  const {allMovies,isAllMoviesLoading, allMoviesError} = useGetAllMovies() 

  const allPlayedMovies = allMovies?.data?.results

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMovies, setFilteredMovies] = useState(allPlayedMovies);

  const pageRef = useRef(null);

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    handleGetRef();
  }, []);


  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filtered = allPlayedMovies?.filter((event) =>
      event.title.toLowerCase().includes(term.toLowerCase())
    );

    setFilteredMovies(filtered);
  };

  const goToHome = () => {
    navigate(-1);
  };

  useEffect(() => {
    setFilteredMovies(allPlayedMovies);
  }, [allPlayedMovies]);

  return (
    <div className="lg:grid lg:grid-cols-12 gap-3 min-h-screen" ref={pageRef}>
      <div className="lg:col-span-8 bg-white mt-2 px-4">
        <div className="flex w-full items-center gap-4 mt-4 px-2">
          <FaArrowLeftLong onClick={goToHome} className="text-[18px]" />
          <h2 className="font-semibold text-[17px] mt-2">Played Movies</h2>
        </div>

        <div className="w-full border-none bg-neutral-100 rounded flex items-center px-4 mt-4 ">
          <BiSearch className="text-black text-[16px]" />
          <input
            type="text"
            className=" text-[14px] border-none outline-none "
            placeholder="search movie"
            value={searchTerm}
            onChange={handleInputChange}
          />
        </div>

        <div className="ticket-full-box">
          {filteredMovies?.map((item, index) => (
            <TvCardFull data={item} key={index} />
          ))}
        </div>
      </div>

      <div className="lg:col-span-4 hidden lg:block bg-white mt-2 px-2 py-2 ">
        <TvRightSide />
      </div>
    </div>
  );
};

export default PlayedMovies;
