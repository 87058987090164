/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import { Card, CardHeader, Link } from '@nextui-org/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { IoMdArrowBack } from 'react-icons/io'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { Autoplay, Pagination, Mousewheel, Keyboard } from 'swiper/modules'
import { useEffect, useState } from 'react'
import './Post.css'
import NdaLogo from './assets/Nigerian_Defence_Academy_logo-removebg-preview 1.svg'
import JambLogo from './assets/JAMB.svg'
import WeacLogo from './assets/waecdirect.org.svg'
import NecoLogo from './assets/neco.svg'
import FifiImg from './assets/fifa_img.svg'
import NabteebLogo from './assets/Untitled-removebg-preview 1.svg'
import NimasaLogo from './assets/nimasa.svg'
import TrncLogo from './assets/trcn.svg'
import Weac2 from './assets/waecdirect2.svg'
import Swipper_img from './assets/swipper_img.jpeg'

const PostFeeds = () => {
  const popularExaminations = [
    {
      id: 1,
      acronomy: 'JAMB',
      examBody: 'Joint Admission and Matriculation Board',
      img: JambLogo,
      link: 'https://www.jamb.gov.ng/',
    },

    {
      id: 3,
      acronomy: 'WAEC',
      examBody: 'West African Examination Council',
      img: WeacLogo,
      link: 'https://www.waecnigeria.org/',
    },
    {
      id: 4,
      acronomy: 'NECO',
      examBody: 'National Examinational Council',
      img: NecoLogo,
      link: 'https://neco.gov.ng/',
    },
  ]

  const [showPagination, setShowPagination] = useState(true)

  useEffect(() => {
    const handleResize = () => {
      setShowPagination(window.innerWidth < 900)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const otherExaminations = [
    {
      id: 1,
      acronomy: 'NDA',
      examBody: 'Nigeria Defence Academy',
      link: 'https://nda.edu.ng/',
      img: NdaLogo,
    },
    {
      id: 2,
      acronomy: 'NABTEB',
      examBody: 'National Business and Technical Examinations Board',
      img: NabteebLogo,
      link: 'https://nabteb.gov.ng/',
    },
    {
      id: 3,
      acronomy: 'NIMASA',
      examBody: 'Nigerian Maritime Administration and Safety Agency',
      img: NimasaLogo,
      link: 'https://nimasa.gov.ng/',
    },
    {
      id: 4,
      acronomy: 'TRCN PQE',
      examBody:
        'Teachers Registration Council of Nigeria Professional Qualifying Examination',
      img: TrncLogo,
      link: 'https://trcn.gov.ng/',
    },
  ]

  return (
    <div className='space-y-5 px-6 w-full min-h-screen h-full bg-white '>
      <div className='py-6'>
        <div className='flex gap-2 items-center mb-8'>
          <button>
            <IoMdArrowBack size={24} />{' '}
          </button>
          <h1 className='text-center lg:text-start'>Education</h1>
        </div>
        <Swiper
          cssMode={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
            el: showPagination ? '.swiper-pagination' : undefined, // Conditionally show pagination based on screen size
          }}
          style={{
            '--swiper-pagination-color': '#4F0DA3',
          }}
          mousewheel={true}
          keyboard={true}
          modules={[Pagination, Autoplay, Mousewheel, Keyboard]}
          className=' relative w-full h-full rounded-xl'
          // className='mySwiper relative w-full h-full rounded-xl'
        >
          {[1, 2, 3].map((key) => (
            <SwiperSlide key={key} className='text-center w-full'>
              <img alt='llo' className='w-full h-full ' src={Swipper_img} />
              {/* <div className='absolute flex justify-end px-0 top-10 w-16 md:w-24 md:px-4 md:top-10 right-0 md:right-8 z-10'> */}
              {/* <div className='absolute px-0 top-10 w-16 md:w-24 md:px-4 md:top-20 right-28 md:right-8 z-10'> */}
              <div className='absolute px-0 top-10 w-16 md:w-24 md:px4 md:top-20 right-8 md:right-8 z-10'>
                <img alt='' src={Weac2} />
              </div>
              <div className='absolute text-start px-4 bottom-0 md:bottom-10 z-10'>
                {/* <div className='absolute bg-blue-500 text-start px-4 bottom-0 md:bottom-10 z-10'> */}
                <h1 className='text-white text-lg md:text-[24px] md:pb-10 md:my4'>
                  WAEC results are out
                </h1>
                <p className='text-white  capitalize text-lg md:text-[20px] md:pb-8 md:py2'>
                  2023 WAEC results are out. Check yours now!
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className='py-6'>
        <div className='flex flex-col space-y-6 relative text-black'>
          <h2 className='font-[500]'>Popular examinations</h2>
        </div>

        <div className='flex flex-col gap-y-5 text-gray-500  w-full text-sm'>
          {popularExaminations?.map?.((chatH) => (
            <Card key={chatH?.id} className='w-full rounded-xl bg-gray-100'>
              <Link isExternal href={chatH?.link}>
                <CardHeader className='flex items-center gap-2'>
                  <img
                    alt='logo'
                    height={50}
                    radius='sm'
                    src={chatH?.img}
                    width={50}
                  />
                  <div className='flex pt-t gap-2 flex-col'>
                    <p className='text-[16px] lg:text-[24px] uppercase font-[500] text-black'>
                      {chatH?.acronomy}
                    </p>
                    <p className='text-[10px] lg:text-[18px] text-black'>
                      {chatH?.examBody}
                    </p>
                  </div>
                </CardHeader>
              </Link>
            </Card>
          ))}
        </div>
      </div>

      <div className='hidden lg:grid w-full md:h-72 '>
        <img src={FifiImg} alt='imgg' className='w-full h-full rounded ' />
      </div>
      <div className='py-6'>
        <div className='flex flex-col space-y-6 text-black'>
          <h2 className='text-[24px] font-[500]'>Other examinations</h2>
        </div>

        <div className='grid grid-cols-2 h-full gap-6 text-gray-500  w-full'>
          {otherExaminations?.map?.((chatH) => (
            <Card
              key={chatH?.id}
              className='w-full bg-gray-100  rounded-xl h-full'
            >
              <Link isExternal href={chatH?.link}>
                <CardHeader className='flex flex-col h-full justify-center items-center text-center  gap-2'>
                  <img
                    alt='logo'
                    height={50}
                    radius='sm'
                    src={chatH?.img}
                    width={50}
                  />
                  <div className='flex flex-col'>
                    <p className='text-[16px] lg:text-[24px] uppercase font-[500] text-black'>
                      {chatH?.acronomy}
                    </p>
                    <p className='text-[10px] lg:text-[18px] text-black'>
                      {chatH?.examBody}
                    </p>
                  </div>
                </CardHeader>
              </Link>
            </Card>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PostFeeds
