import { Link, useNavigate } from "react-router-dom";
import { TbDotsVertical } from "react-icons/tb";
import { FaPlay } from "react-icons/fa";
import TvOptionsModal from "./TvOptionsModal";
import { useState } from "react";
import PromoteMovieModal from "./PromoteMovieModal";
import { useTv } from "Hooks/tv/useTv";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import moment from "moment";

function limitString(str, limit = 20) {
  if (str?.length > limit) {
    return `${str?.substring(0, limit)}...`;
  }
  return str;
}

TimeAgo.addDefaultLocale(en);

const TvCardFull = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPromoteModalOpen, setIsPromoteModalOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState(null);

  const navigate = useNavigate();

  const timeAgo = new TimeAgo("en-US");

  const { userTvData, userTvDataError, userTvDataStatus, isUserTvDataLoading } =
    useTv();

  // eslint-disable-next-line eqeqeq
  const isUser = userTvData?.data?.data[0]?.id == data?.channel?.id;

  return (
    <div className="flex justify-between mt-6 items-center relative">
      {isPromoteModalOpen && (
        <PromoteMovieModal
          setSelectedTags={setSelectedTags}
          selectedTags={selectedTags}
          setIsPromoteModalOpen={setIsPromoteModalOpen}
          data={data}
        />
      )}
      {isModalOpen && (
        <TvOptionsModal
          setIsModalOpen={setIsModalOpen}
          setIsPromoteModalOpen={setIsPromoteModalOpen}
          movie={data}
          isUser={isUser}
        />
      )}

      <div className="flex gap-2 items-center">
        <div className="lg:h-[100px] lg:w-[100px] h-[100px] w-[80px] relative">
          <img
            src={data?.cover_photo}
            alt=""
            className="w-full h-full object-cover rounded hover:text-[#4f0da3] transition duration-300"
          />
          <div
            className="bg-neutral-100 h-[40px] w-[40px] opacity-50 rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center"
            onClick={() => navigate(`/tv/movie/${data?.id}`)}
          >
            <FaPlay className="text-neutral-800 text-[14px]" />
          </div>
        </div>
        <div className="flex flex-col gap-1 text-[11px] lg:text-[12px]">
          <div className="font-semibold text-[12px] lg:text-[13px]">
            {limitString(data?.title)}
          </div>
          <Link
            className="text-[#FF8A15] no-underline"
            to={`/tv/${data?.channel?.id}`}
          >
            {data?.channel?.name}
          </Link>
          <div className="text-neutral-500 text-[11px] lg:text-[12px]">
            {timeAgo.format(moment(data?.schedule).toDate())}
          </div>
          <div className="flex items-center gap-2">
            {data?.tags
              ?.map((tag) => tag.name)
              ?.map((item, index) => (
                <div
                  className="bg-[#FF8A15] py-2 px-4 rounded text-white"
                  key={index}
                >
                  {item}
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="">
        <TbDotsVertical
          className="text-[16px]"
          onClick={() => setIsModalOpen(true)}
        />
      </div>
    </div>
  );
};

export default TvCardFull;
