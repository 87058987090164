import { MdLocationOn, MdOutlineCalendarMonth } from "react-icons/md";
import moment from "moment";

const EventDetailDetailsComp = ({ handleGetTicketClick, eventDetail }) => {
  const currentDate = moment(eventDetail?.start_date).format("DD MMM, YYYY");


  return (
    <div className="bg-white m-1 p-4">
      <div className="">
        <div className="font-semibold text-[16px]">{eventDetail?.title}</div>
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between mt-4">
          <div>
            <div className="flex items-center gap-2">
              <MdLocationOn className="text-[16px] text-neutral-400 mb-3" />
              <p className="text-neutral-400 font-semibold">
                {eventDetail?.venue_address}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <MdOutlineCalendarMonth className="text-[16px] text-neutral-400 mb-3" />
              <p className="text-neutral-400 font-semibold">{currentDate}</p>
            </div>
          </div>
          <div className="mb-6 border border-blue">
            <button
              className="p-3 w-full lg:w-[150px] bg-[#4f0da3] rounded text-[14px] text-[#fff]"
              onClick={handleGetTicketClick}
            >
              Get ticket
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetailDetailsComp;
