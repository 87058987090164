import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useProfile } from "Hooks/profile/useProfile";
import Stick from "components/Dashboard/Stick";
import ContainerLoading from "components/ContainerLoading";
import { BiSearch } from "react-icons/bi";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useGetAllAccounts } from "Hooks/profile/useGetAllAccounts";

const Stickers = () => {
  const navigate = useNavigate();
  const { id: usernameID } = useParams();

  const {
    profileData,
    isProfileDataLoading,
    profileDataError,
    refetchProfile,
  } = useProfile();

  const { allAccounts, isAllAccountsLoading } = useGetAllAccounts();

  const isOthers = profileData?.data?.data?.user?.username !== usernameID;
  const theUser = allAccounts?.data?.data?.find(
    (item) => item?.user?.username === usernameID
  );
  
  const realUser = isOthers ? theUser : profileData?.data?.data;

  const stickers = realUser?.stickers
  const userStickings = profileData?.data?.data?.stickings

  const pageRef = useRef(null);

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ top: 0 });
    }
  }, []);

  
  const [filteredStickers, setFilteredStickers] = useState(stickers);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setFilteredStickers(stickers);
  }, [stickers]);

  const handleInputChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    setFilteredStickers(stickers.filter(user =>
      user.username?.toLowerCase().includes(term) ||
      user.firstName?.toLowerCase().includes(term) ||
      user.lastName?.toLowerCase().includes(term)
    ));
  };

  
  return (
    <div ref={pageRef}>
      <div className="px-6 lg:p-0 lg:px-[22px] lg:pb-6 min-h-[93vh]">
        <div className="you-may-know">
          <div className="flex gap-4 items-center">
            <FaArrowLeftLong
              onClick={() => navigate(-1)}
              className="cursor-pointer text-[18px] mb-1"
            />
            <h3 className="text-[18px]">Stickers</h3>
          </div>
          {stickers.length === 0 ? (
            <p className="text-[16px] mt-4">No stickers</p>
          ) : (
            <div className="may-know-box">
              <div className="w-full border-none bg-neutral-100 rounded-full flex items-center px-4 my-4 ">
                <BiSearch className="text-black text-[16px]" />
                <input
                  type="text"
                  className="text-[14px] border-none outline-none"
                  placeholder="Search keywords and names"
                  value={searchTerm}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mt-12">
                {!filteredStickers.length && <p>No stickers found</p>}
                {filteredStickers.map(item => (
                  <Stick key={item?.id} item={item} stickings={userStickings} publicStickers={stickers} username={profileData?.data?.data?.user?.username}/>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Stickers;
