import React, { useState } from "react";
import benz from "../../assets/live_images/benz.jpg";
import bullseye from "../../assets/live_images/bullseye.svg";
import gift from "../../assets/live_images/gift.svg";

const buttons = ["All", "Claimed", "Active"];

const LiveGift = () => {
  const [clickedButton, setClickedButton] = useState("All");
  return (
    <div className="border border-neutral-500 h-[400px] mt-4 rounded-md p-2">
      <div>
        <h4 className="text-[#4f0da3] text-[17px] text-center">Gifts</h4>
      </div>
      <div className="grid grid-cols-3 gap-2 px-2 mt-8">
        {buttons.map((item) => (
          <button
            key={item}
            className={`
                  ${
                    clickedButton === item
                      ? "bg-[#4f0da3] text-white"
                      : "text-[#4f0da3] border-[#4f0da3]"
                  }
                  py-3 px-4 rounded-full text-[12px] 
                `}
            onClick={() => setClickedButton(item)}
          >
            {item}
          </button>
        ))}
      </div>
      {/* CARD 1 */}
      <div className="grid grid-cols-6 gap-1 text-black text-[16px] mt-4 zigzag-border">
        <div className="col-span-2  bg-[#4f0da3] p-3 text-[12px] text-white text-center flex flex-col justify-between">
          <div className="w-[65%] h-[65%] bg-white mx-auto">
            <img src={benz} alt="" className="h-full w-full object-cover" />
          </div>
          <div>Innoson G & T</div>
        </div>
        <div className="col-span-4  bg-[#4f0da3] py-2 px-4">
          <div className="flex justify-between text-white">
            <div className="text-[12px] font-light text-white flex gap-2 items-center">
              <div className=" h-12 w-12">
              <img src={gift} alt="" className="h-full w-full object-cover" />
              </div>
              <div>
                <div className="text-white">45,000</div>
                <div className="text-white">Collected</div>
              </div>
            </div>
            <div className="text-[12px] font-light text-white flex gap-2 items-center">
              <div className=" h-12 w-12">
              <img src={bullseye} alt="" className="h-full w-full object-cover" />
              </div>
              <div>
                <div className="text-white">60,000</div>
                <div className="text-white">Required</div>
              </div>
            </div>
          </div>
          <div className="my-2 mx-auto w-full flex items-center justify-center">
            <button className="justify-center items-center bg-white rounded text-[#4f0da3] text-[12px] px-14 py-2">
              Claim
            </button>
          </div>
        </div>
      </div>
      {/* CARD 2 */}
      <div className="grid grid-cols-6 gap-1 text-black text-[16px] mt-4">
        <div className="col-span-2  bg-[#4f0da3] p-3 text-[12px] text-white text-center flex flex-col justify-between">
          <div className="w-[65%] h-[65%] mx-auto">
          <img src={benz} alt="" className="h-full w-full object-cover" />
          </div>
          <div>Innoson G & T</div>
        </div>
        <div className="col-span-4  bg-[#4f0da3] py-2 px-4">
          <div className="flex justify-between text-white">
            <div className="text-[12px] font-light text-white flex gap-2 items-center">
              <div className="h-12 w-12">
              <img src={gift} alt="" className="h-full w-full object-cover" />
              </div>
              <div>
                <div className="text-white">45,000</div>
                <div className="text-white">Collected</div>
              </div>
            </div>
            <div className="text-[12px] font-light text-white flex gap-2 items-center">
              <div className=" h-12 w-12">
              <img src={bullseye} alt="" className="h-full w-full object-cover" />
              </div>
              <div>
                <div className="text-white">600,000</div>
                <div className="text-white">Required</div>
              </div>
            </div>
          </div>
          <div className="my-2 mx-auto w-full flex items-center justify-center">
            <button className="justify-center items-center bg-white rounded text-[#4f0da3] text-[12px] px-14 py-2">
              Claim
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveGift;
