import Description from "components/newCommerce/typography/txtDescription";
import Stacked from "components/newCommerce/shared/Stacked";
import Header from "components/newCommerce/typography/txtHeader";
import { useState, useEffect } from "react";
import { ButtonSide } from "components/newCommerce/shared/sideButton";
import star from "assets/images/one_star.png";
import { CircularProgress, Button } from "@mui/material";
import { promotePlan } from "api/commerce/Apiactions";
import { FormatwithComma } from "utils/commerceUtils";

const Planspromote = ({ click, planDetail }) => {
  const [selectedplan, setSelectedplan] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const handleSelect = (payload, plancontent) => {
    console.log(payload);
    setSelectedplan(payload);
    // auto-update-currently-clicked-plan
    planDetail(plancontent);
  };
  const [reload, setReload] = useState(false);

  let bg;
  const switchtagbg = (curr) => {
    switch (curr) {
      case 1:
        bg = "#FF6347";
        break;
      case 2:
        bg = "#1E90FF";
        break;
      case 3:
        bg = "#32CD32";
        break;
      case 4:
        bg = "#FFD700";
        break;
      case 5:
        bg = "#FF4500";
        break;
      case 6:
        bg = "#8A2BE2";
        break;
      default:
        bg = "";
    }
    return bg;
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    const fetchlist = async () => {
      try {
        const getPlan = await promotePlan();
        setPlans(getPlan.data);
      } catch (err) {
        console.error(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchlist();
  }, [reload]);

  if (loading) {
    return (
      <div className="w-full">
        <Stacked d="column" ai={"center"} cname={"pt-[4rem] gap-y-6"}>
          <CircularProgress />
          <Header title={" Fetching available Plans..."} />
        </Stacked>
      </div>
    );
  }

  // error
  if (error) {
    return (
      <div className="w-full">
        <Stacked d="column" ai={"center"} cname={"pt-[4rem] gap-y-6"}>
          <Header title={"Error Fetching Plans..."} />
          <Button
            variant="contained"
            onClick={() => setReload(!reload)}
            sx={{ fontSize: "12px" }}
          >
            Reload
          </Button>
        </Stacked>
      </div>
    );
  }
  return (
    <div className="w-full">
      <div className="pt-4 flex flex-col gap-3 items-center">
        <Header
          title={"Sell faster when you promote your item"}
          fw={"600"}
          cl={"#303030"}
        />
        <Description title={"We’ve got amazing plans for you"} cl={"#222222"} />
      </div>
      <Stacked
        cname={"pt-[8rem] w-full"}
        d="row"
        g={2}
        // ai={"center"}
        jc={"center"}
        fw={"wrap"}
      >
        {plans.map((plan, index) => {
          return (
            <div
              key={index}
              className="w-[200px] max-w-[200px] rounded-[10px] flex flex-col p-3 gap-y-2 cursor-pointer"
              style={{
                border: "1px solid #D9D9D9",
                outline: `${
                  selectedplan === Number(index) + 1 ? "2px solid #4f0da3" : ""
                }`,
              }}
              onClick={() => handleSelect(Number(index) + 1, plan)}
            >
              {/* flex-first */}
              <div className="flex justify-between items-center">
                {/* hint */}
                {index === 2 && (
                  <div className="flex flex-row gap-1 items-center">
                    <img src={star} className="mb-[0.7rem] p-0" alt="star" />
                    <h6>Best value</h6>
                  </div>
                )}
                {/* tag-plan */}
                <div
                  className="rounded-[16px] p-2 justify-end"
                  style={{
                    background: switchtagbg(Number(index) + 1),
                    color: `${
                      bg === "#FFD700" ? "rgba(34, 34, 34, 0.7)" : "#ffff"
                    }`,
                  }}
                >
                  {plan.tag}
                </div>
              </div>

              {/* plan-duration */}
              <Header title={plan.duration} />
              {/* plan-price */}
              <Header
                title={FormatwithComma(plan.price).toString().replace(/^/, "₦")}
                fs={"1.3rem"}
              />

              {/* plantitle */}
              <Header title={plan.name} fs={"1.3rem"} />
              <Description title={plan.description} />
            </div>
          );
        })}
      </Stacked>

      <Stacked pt={3} jc="center" d="column" g={3} ai="center">
        <ButtonSide
          title="Continue"
          bg="#4F0DA3"
          cl="#ffff"
          // isDisabled={disable}
          styles={{
            paddingInline: "8rem",
            marginTop: "5rem",
            paddingBlock: ".9rem",
            fontSize: "1.4rem",
          }}
          br="5px"
          click={click}
        />
      </Stacked>
    </div>
  );
};

export default Planspromote;

// const promotemock = [
//   {
//     id: 1,
//     name: "Instant Exposure💡",
//     duration: "1 Day",
//     price: 1500.0,
//     description: "Perfect for flash sales and grabbing attention.",
//     tag: "Flash Sale",
//   },
//   {
//     id: 2,
//     name: "Extended Visibility 🚀",
//     duration: "1 Week",
//     price: 7000.0,
//     description: "Ideal for limited-time offers and special events.",
//     tag: "Week Wave",
//   },
//   {
//     id: 3,
//     name: "Consistent Presence 🌟",
//     duration: "1 Month",
//     price: 20000.0,
//     description: "Great for brand building and ongoing promotions.",
//     tag: "Monthly boost",
//   },
//   {
//     id: 4,
//     name: "Consistent Presence 🌟",
//     duration: "3 Months",
//     price: 40000.0,
//     description: "Great for brand building and ongoing promotions.",
//     tag: "Season Saver",
//   },
//   {
//     id: 5,
//     name: "Sustained Engagement 🎉",
//     duration: "6 Months",
//     price: 55000.0,
//     description: "Ideal for building customers and long-term campaigns.",
//     tag: "Half-year hit",
//   },
//   {
//     id: 6,
//     name: "Comprehensive Marketing 🌈",
//     duration: "12 Months",
//     price: 80000.0,
//     description: "Unlock unparalleled visibility and strong market presence.",
//     tag: "Mega Marathon",
//   },
// ];
