import React, { useEffect, useState } from "react";
import Image from "../Assets/Image7.jpeg";
import Kebab from "../Assets/optBlack.svg";
import { BiPlay } from "react-icons/bi";

export default function MoreCard({ title, img, artist, onClick, setModalOpen }) {
  const [formatedTitle, setFormatedTitle] = useState();
  useEffect(() => {
    const length = 29;
    const string =
      title?.length > length ? title.substring(0, length - 3) + "..." : title;
    setFormatedTitle(string);
  }, [title]);
  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex gap-4" space={2}>
          <img
            src={img?img:Image}
            style={{ minHeight: 75 }}
            width={75}
            className="rounded-md"
          />
          <div className="flex flex-col justify-between gap-1" space={1} justifyContent={"space-between"}>
            <label
              style={{fontSize:"16px",
              fontWeight:"400",
              color:"black",
              // lineHeight={"18.38px"}
              width:"151px"}}>
              {title ? formatedTitle : "Unknown song"}
            </label>
            <label style={{fontSize:"12px", fontWeight:"400", color:"black"}}>
              {artist ? artist : "Unknown Artist"}
            </label>
          </div>
        </div>

        <button onClick={()=>{onClick(); setModalOpen(true)}} className="rounded-full px-2 py-2" style={{backgroundColor:"rgba(79, 13, 163, 0.85)"}}>
          <BiPlay size={24} color="white"/>
        </button>
      </div>
    </div>
  );
}
