import React, { useEffect, useContext, useRef, useState } from 'react'
import PostComp from 'components/Dashboard/PostComp'
import ProductDash from 'components/Dashboard/product-card/ProductDAs'
import MovieSlider from 'components/Home/Movieslider/MovieSlider'
import Stick from 'components/Dashboard/Stick'
import MovieDashCard from 'components/Home/Movieslider/MovieCards'
import { useGetAllFeeds } from 'api/hooks/feeds'
import { ModalContext } from 'Context/ModalContext'
import FeedsSkeleton from 'components/Home/Skeleton/FeedsSkeleton'
import { useAccountProfile } from 'pages/Profile/useAccountProfiles'
// import { useStickerUser } from 'pages/Profile/useStickers'
// import { useGetAllFeedsPost } from 'api/services/feeds'
import FeedsMusic from '../Music/FeedsMusic'
import FeedsStereoComp from 'pages/Stereo/Components/FeedsStereoComp'
import AllTickets from 'components/TicketComp/AllTickets'
import EmptyImage from '../../../../assets/2gede_nothing_here.svg'
import CustomerCareImage from './2geda-customer-care.svg'
import FeedsJob from '../Jobs/FeedsJob'
import JobListing from '../Jobs/JobListing'
import useGetTickets from 'Hooks/useGetTickets'
import MyPolls from 'pages/Polls/MyPolls'
import AllMoviesAll from 'pages/Tv/AllMoviesAll'
import { useGetAllMovies } from 'Hooks/tv/useGetAllMovies'
import MoviesCard from 'pages/Tv/MoviesCard'
import Voting from 'pages/Polls/Voting'
import { SuggestedPolls } from 'components/PollsComp/SuggestedPolls'
import { useGetAllAccounts } from 'Hooks/profile/useGetAllAccounts'
import { useProfile } from 'Hooks/profile/useProfile'
import HotelSection from 'components/Hotels/HotelSection'
import ProfileOverviewDetails from 'components/ProfileComponents/ProfileOverviewDetails'

const Feeds = () => {
  const { isAuthenticated } = useContext(ModalContext)
  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetAllFeeds(isAuthenticated)
  const loadMoreRef = useRef()
  const { allTickets } = useGetTickets()
  const { allMovies } = useGetAllMovies()
  const [APoll, setAPoll] = useState({})
  const [selectedPoll, setSelectedPoll] = useState(false)

  const newResults =
    data?.pages?.flatMap((page) =>
      page?.map((result) => ({ ...result, count: page.count }))
    ) || []

  const handleObserver = (entities) => {
    const target = entities[0]
    if (target.isIntersecting && hasNextPage) {
      fetchNextPage()
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    })
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current)
    }
    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current)
      }
    }
  }, [loadMoreRef, hasNextPage])

  const { accountStatus, accountProfile } = useAccountProfile()
  const { allAccounts, isAllAccountsLoading } = useGetAllAccounts()
  const { profileData } = useProfile()

  if (!isLoading && !isError && !newResults && isAuthenticated) {
    return (
      <div className='!flex !flex-col !justify-center !items-center'>
        <h3>No feeds available</h3>
        <img src={EmptyImage} alt='nothing here' />
      </div>
    )
  }
  if (!newResults || newResults?.length === 0) {
    return (
      <div className='!flex !flex-col !justify-center !items-center'>
        <h3>No feeds available</h3>
        <img src={EmptyImage} alt='nothing here' />
      </div>
    )
  }
  // console.log(accountStatus, accountProfile)
  if (isLoading) {
    return <FeedsSkeleton />
  }
  if (isAuthenticated && isError) {
    return (
      <div className='!flex !flex-col !justify-center !items-center'>
        <h3>
          unable to get feeds at this time, please check your network and try
          again in some minutes
        </h3>
        <img src={EmptyImage} alt='nothing here' />
      </div>
    )
  }

  function openCustomerCare() {
    // Handle the click event (e.g., open a chat window or redirect to a customer care page)
    console.log('Customer Care Clicked!')
  }

  const HandlePoll = (pollData) => {
    setAPoll(pollData)
    setSelectedPoll(true)
  }
  console.log(accountProfile)

  // const isOthers = profileData?.data?.data?.user?.username !== usernameID
  // const theUser = allAccounts?.data?.data?.find(
  //   (item) => item?.user?.username === usernameID
  // )

  // const realUser = isOthers ? theUser : profileData?.data?.data
  const realUser = profileData?.data?.data
  console.log(realUser, 'realuser')

  const stickers = realUser?.stickers
  const userStickings = profileData?.data?.data?.stickings

  return (
    <>
      <div
        // style={{ maxWidth: '560px' }}
        className='w-full xl:max-w-[636px] !relative'
        key={newResults?.pages}
      >
        {newResults?.length > 0 && (
          <PostComp
            shared={newResults?.[0]?.is_repost}
            postData={
              newResults?.[0]?.is_repost
                ? newResults?.[0]?.repost
                : newResults?.[0]
            }
          />
        )}

        {/* <Voting /> */}

        {/* <div>
          <h2 className='mt-4'>Suggested Polls</h2>
          <SuggestedPolls HandlePoll={HandlePoll} />
        </div> */}

        {newResults?.length > 1 && (
          <>
            <div className='music-das-row'>
              <FeedsMusic />
            </div>
            <div className=''>
              <AllTickets allEventsData={allTickets?.data?.message} />
            </div>
            <div className=''>
              <div className='post-ead'>Trending movies</div>
              <MoviesCard data={allMovies?.data?.results} />
            </div>
            <PostComp
              shared={newResults?.[1]?.is_repost}
              postData={
                newResults?.[1]?.is_repost
                  ? newResults?.[1]?.repost
                  : newResults?.[1]
              }
            />
          </>
        )}

        {newResults?.length > 2 && (
          <>
            <div className=''>
              <AllTickets allEventsData={allTickets?.data?.message} />
            </div>

            <div className='you-may-know'>
              <div className='post-ead'>People you may know</div>
              <div className='may-know-box'>
                <ProfileOverviewDetails data={accountProfile?.data} />
                {accountProfile?.data?.slice(0, 6)?.map((item) => (
                  <Stick
                    key={item?.id}
                    item={item}
                    stickings={userStickings}
                    publicStickers={stickers}
                    username={profileData?.data?.data?.user?.username}
                  />
                ))}
              </div>
            </div>
            <div className='music-das-row'>
              <FeedsMusic />
            </div>
            <div className=''>
              <div className='post-ead'>Trending movies</div>
              <MoviesCard data={allMovies?.data?.results} />
            </div>
            <PostComp
              shared={newResults?.[2]?.is_repost}
              postData={
                newResults?.[2]?.is_repost
                  ? newResults?.[2]?.repost
                  : newResults?.[2]
              }
            />
            <HotelSection />
          </>
        )}
        {/* {postsWithJobListings?.map((post, index) => {
          if (post === 'JobListing') {
            return <JobListing key={`joblisting-${index}`} />
          } else if (post?.id) {
            return (
              <PostComp
                key={post?.id}
                shared={post?.is_repost}
                postData={post?.is_repost ? post?.repost : post}
              />
            )
          }
          return null
        })} */}
        {newResults?.length > 2 && (
          <>
            <JobListing />
            <div className=''>
              <div className='post-ead'>Trending movies</div>
              <MoviesCard data={allMovies?.data?.results} />
            </div>
          </>
        )}
        {/* {newResults?.length > 2 && (
          <>
            <MyPolls />
          </>
        )} */}
        <div className='you-may-know'>
          <div className='post-ead'>People you may know</div>
          <div className='may-know-box'>
            {accountProfile?.data?.map((item) => (
              <Stick key={item} item={item} />
            ))}
          </div>
        </div>

        {newResults?.length > 3 && (
          <>
            <div className=''>
              <MoviesCard data={allMovies?.data?.results} />
            </div>
            <HotelSection />
            <PostComp
              shared={data?.posts?.[3]?.is_repost}
              postData={
                data?.posts?.[3]?.is_repost
                  ? data?.posts?.[3]?.repost
                  : data?.posts?.[3]
              }
            />
          </>
        )}
        <div className='you-may-know'>
          <div className='post-ead'>People you may know</div>
          <div className='may-know-box'>
            {accountProfile?.data?.map((item) => (
              <Stick key={item} item={item} />
            ))}
          </div>
        </div>

        {newResults?.length > 4 && (
          <>
            <div className='ticket-das-row'>
              {[1, 2, 3, 4, 5, 6, 7].map((item) => (
                <ProductDash key={item} />
              ))}
            </div>
            <PostComp
              shared={newResults?.[4]?.is_repost}
              postData={
                newResults?.[4]?.is_repost
                  ? newResults?.[4]?.repost
                  : newResults?.[4]
              }
            />
            <HotelSection />
          </>
        )}

        {data?.posts?.length > 5 && (
          <div className='movie-slid-box'>
            <div className='post-ead'>Trending movies</div>

            <div className=''>
              <MoviesCard data={allMovies?.data?.results} />
            </div>
          </div>
        )}
        <div className='movie-slid-box'>
          <div className='post-ead'>Stereo</div>
          <FeedsStereoComp />
        </div>
        <div>
          <AllTickets allEventsData={allTickets?.data?.message} />
        </div>
        <HotelSection />
        <div className='you-may-know'>
          <div className='post-ead'>People you may know</div>
          <div className='may-know-box'>
            {accountProfile?.data?.map((item) => (
              <Stick key={item} item={item} />
            ))}
          </div>
        </div>

        <HotelSection/>

        {newResults?.slice(5)?.map((post) => (
          <PostComp
            shared={post?.is_repost}
            postData={post?.is_repost ? post?.repost : post}
          />
        ))}
        <HotelSection />
        <div ref={loadMoreRef} />
        {isFetchingNextPage && <p>Loading more...</p>}

        <button
          class='fixed bottom-6 md:right-[56rem] bg-purple-200 text-white p4 rounded-full shadow-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-400'
          onclick={openCustomerCare}
        >
          <img
            src={CustomerCareImage}
            alt='Customer Care'
            className=' !h-full'
          />
        </button>
      </div>
    </>
  )
}

export default Feeds
