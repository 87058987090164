const AdPolicyList = ({ header, body }) => {
  return (
    <>
      <h3>{header}</h3>
      <p>{body}</p>
    </>
  );
};

export default AdPolicyList;
