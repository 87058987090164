import { useState } from "react";
import ModalButton from "components/Modals/ModalButton";
import CustomDropdown from "components/Modals/CustomDropdown";
import Spinner from "components/Spinner";
import { useUpdateGadget } from "Hooks/profile/useUpdateGadjet";
import toast from "react-hot-toast";
import { updateGadget } from "api/services/profile";
import { useGetGadgets } from "Hooks/profile/useGetGadjets";
import NewEditProfileHeader from "components/PollsComp/ProfilleComp/NewEditProfileHeader";
import {useNavigate, useParams } from "react-router-dom";
//import { createGadget } from "api/services/profile";

const inputStyle =
  "rounded-[8px] border border-neutral-500 py-[10px] text-[14px] placeholder:text-[14px] text-black outline-none";

const EditIMEISerialModal = () => {
  const { gadgetId} = useParams();
  const navigate = useNavigate();
  const { gadgetStatus: updatingStatus} = useUpdateGadget();
  const { gadgetRefetch, gadgets } = useGetGadgets();

  // eslint-disable-next-line eqeqeq
  const toEdit = gadgets?.data?.find((item) => item?.id == gadgetId);

  const [data, setData] = useState({
    id_number: toEdit?.id_number,
    phone_name: toEdit?.phone_name,
    category: toEdit?.category,
  });

  const handleChange = (e) => {
    setData((data) => ({ ...data, [e.target.name]: e.target.value }));
  };

  const handleEdit = async (e) => {
    e.preventDefault();

    const formData = {
      id_number: data.id_number,
      phone_name: data.phone_name,
      category: data.category,
    };

    try {
      await updateGadget(gadgetId, formData);
      toast.success("Gadget updated successfully");
      gadgetRefetch();
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <NewEditProfileHeader header={`Edit ${toEdit?.phone_name}`} back />

      <div className="px-[15px] h-[90vh] overflow-y-auto mb-0 py-[10px] lg:px-[10px] lg:h-[60vh] overflow-auto lg:mb-[1.5rem]">
        <form
          className="flex flex-col gap-[30px] mt-[5rem]"
          onSubmit={handleEdit}
        >
          <input
            type="text"
            placeholder="Preferred name"
            className={inputStyle}
            onChange={handleChange}
            name="phone_name"
            defaultValue={data?.phone_name}
          />

          <input
            type="text"
            placeholder="Phone Imei/Serial Number"
            className={inputStyle}
            onChange={handleChange}
            name="id_number"
            defaultValue={data?.id_number}
          />

          <CustomDropdown
            stallValue={toEdit?.category}
            menu={[
              { label: "Imei", value: "imei" },
              { label: "Serial", value: "serial_number" },
            ]}
            setData={setData}
            name="category"
            defaultValue={toEdit?.category}
          />

          <ModalButton>
            {updatingStatus === "pending" ? <Spinner /> : "Edit"}
          </ModalButton>
        </form>
      </div>
    </>
  );
};

export default EditIMEISerialModal;
