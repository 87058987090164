import React, { useEffect } from "react";
import Indicator from "../Assets/audioIndicator.svg"
import axios from "axios";

export default function LibraryCard({ img, title, no, id, onClick, setModalOpen1, modalOpen1, album }) {
	const authToken = localStorage.getItem("authToken")

  const GetPlays = () => {
     axios
      .get(`https://api.2geda.net/api/stereo/songs/${id}/play/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        // setPlays(res?.data?.data);
        // console.log(plays + "plays state===");
        console.log(JSON.stringify(res.data) + "plays====");
      }).catch(e=>console.log(e)) 
  };

//   useEffect(()=>{
// 	GetPlays()
//   },[])

	return (
		<div onClick={()=>{onClick(); setModalOpen1(!modalOpen1); if (!album){
			GetPlays()
		}else {
			console.log("album")
		} }} className="w-full h-[189]">
			<div className="relative w-full" style={{maxHeight: 148,
					minHeight: 148,}}>
			<img
				className="w-full"
				src={img}
				style={{
					//   maxWidth: 168,
					//   minWidth: 168,
					//   width: "168px",
					maxHeight: 148,
					minHeight: 148,
					//   borderRadius: 6,
					objectFit: "cover",
				}}
			/>
			<img src={Indicator} className="w-[44px] h-[22px] absolute top-[50%] left-[40%]"/>
			</div>
			<div className="flex flex-col">
				<span className="font-medium text-sm">{title}</span>
				{/* <span className="font-normal text-xs text-[#403F3F]">{no} songs</span> */}
				<span></span>
			</div>
		</div>
	);
}
