import React from "react";
import Container from "components/newCommerce/layout/container";
import Orderhistory from "./orderhistory";
const OrderHistroy = () => {
  return (
    <>
      <Container main={<Orderhistory />} />
    </>
  );
};

export default OrderHistroy;
