import { BiSearch } from "react-icons/bi";
import { GoFilter } from "react-icons/go";
import { useState } from "react";
import SortByModal from "../Modals/SortByModal";
import { IoMdCloseCircle } from "react-icons/io";

const ConnectSearch = ({
  userDetails,
  setUserDetails,
  setSearchQuery,
  handleClearSearch,
  searchQuery,
  NearbyBiz,
  setNearbyBiz,
  searchQueryBiz,
  setSearchQueryBiz,
  handleClearSearch_Biz,
  activeTab,
}) => {
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    if (query === "") {
      handleClearSearch();
    } else {
      const filteredResults = userDetails.filter((user) =>
        (user?.user?.first_name || user?.user?.username)
          .toLowerCase()
          .includes(query)
      );
      console.log("filteredResults", filteredResults);
      setUserDetails(filteredResults);
    }
  };

  const handleSearchBiz = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQueryBiz(query);

    if (query === "") {
      handleClearSearch_Biz();
    } else {
      const filteredResults = NearbyBiz.filter((biz) =>
        biz.business_name.toLowerCase().includes(query)
      );
      setNearbyBiz(filteredResults);
    }
  };

  return (
    <div className="business-search-container">
      <div className="search-container-busi rounded-[30px]">
        <BiSearch size={20} />
        {activeTab === "People nearby" ? (
          <input
            type="text"
            className="search-inp-bus "
            placeholder="Search people"
            value={searchQuery}
            onChange={handleSearch}
          />
        ) : (
          <input
            type="text"
            className="search-inp-bus "
            placeholder="Search businesses"
            value={searchQueryBiz}
            onChange={handleSearchBiz}
          />
        )}

        {activeTab === "People nearby" ? (
          <div onClick={handleClearSearch}>
            <IoMdCloseCircle color="#000" />
          </div>
        ) : (
          <div onClick={handleClearSearch_Biz}>
            <IoMdCloseCircle color="#000" />
          </div>
        )}
      </div>

      {/* {isFilterOpen && (
        <div className="modal-full-container">
          <SortByModal handleFilterClose={handleFilterClose} />
        </div>
      )} */}
      {/* <div className="filter-search-bus" onClick={handleFilterClick}>
        <GoFilter />
      </div> */}
    </div>
  );
};

export default ConnectSearch;
