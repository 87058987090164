import { useEffect, useRef, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import TvCardFull from "./TvCardFull";
import TvRightSide from "./TvRightSide";
import Spin from "components/Spin/Spin";
import { useGetUserMovies } from "Hooks/tv/useGetUserMovies";
import { useTvChannels } from "Hooks/tv/useTvChannels";

const AllUserMovies = () => {
  const navigate = useNavigate();
  const { user } = useParams();

  const { userMovies, userMoviesError, isUserMoviesLoading } =
    useGetUserMovies(user);

  const { channelData, isChannelDataLoading, channelDataError } =
    useTvChannels(user);
    
  const tvData = channelData?.data?.data;

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMovies, setFilteredMovies] = useState([]);

  const pageRef = useRef(null);

  const handleGetRef = () => {
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
    }
  };

  useEffect(() => {
    handleGetRef();
  }, []);

  useEffect(() => {
    if (userMovies?.data?.results) {
      setFilteredMovies(userMovies.data.results);
    }
  }, [userMovies?.data?.results]);

  const handleInputChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (userMovies?.data?.results) {
      const filtered = userMovies.data.results.filter((event) =>
        event.title.toLowerCase().includes(term)
      );
      setFilteredMovies(filtered);
    }
  };

  const goToHome = () => {
    navigate("/tv");
  };

  return (
    <div className="lg:grid lg:grid-cols-12 gap-3 min-h-screen" ref={pageRef}>
      <div className="lg:col-span-8 bg-white mt-2 px-4">
        {isUserMoviesLoading || isChannelDataLoading ? (
          <Spin />
        ) : (
          <>
            <div className="flex w-full items-center gap-4 mt-4 px-2">
              <FaArrowLeftLong
                onClick={goToHome}
                className="text-[18px] cursor-pointer"
              />
              <h2 className="font-semibold text-[17px] mt-2">
                Movies from {tvData?.name}
              </h2>
            </div>

            <div className="w-full border-none bg-neutral-100 rounded flex items-center px-4 mt-4 ">
              <BiSearch className="text-black text-[16px]" />
              <input
                type="text"
                className="text-[14px] border-none outline-none w-full"
                placeholder="Search movie"
                value={searchTerm}
                onChange={handleInputChange}
              />
            </div>

            {!filteredMovies?.length ? (
              <div className="text-[14px] mt-[10px] text-black">No movies to display</div>
            ) : (
              <div className="ticket-full-box mt-4">
                {filteredMovies?.map((item, index) => (
                  <TvCardFull data={item} key={index} />
                ))}
              </div>
            )}
          </>
        )}
      </div>

      <div className="lg:col-span-4 hidden lg:block bg-white mt-2 px-2 py-2 ">
        <TvRightSide />
      </div>
    </div>
  );
};

export default AllUserMovies;
