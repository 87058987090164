import { LiaTimesSolid } from "react-icons/lia";
import { BiHide } from "react-icons/bi";
import { MdOutlineContentCopy } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { IoOptions } from "react-icons/io5";
import useDeleteMovie from "Hooks/tv/useDeleteMovie";
import Spinner from "components/Spinner";
import SpinnerBlack from "components/ProfileComponents/SpinnerBlack";
import toast from "react-hot-toast"

const TvOptionsModal = ({ setIsModalOpen, setIsPromoteModalOpen, isUser, movie }) => {

 
  const {deleteMovieMutate, deleteMovieStatus} = useDeleteMovie(movie?.id)

  const handlePromoteMovie = () => {
    setIsModalOpen(false);
    setIsPromoteModalOpen(true);
  };
  
  const movieLink = `http://2geda.net/tv/movie/${movie?.id}`

  const copyToClipboard = () => {
    navigator.clipboard.writeText(movieLink)
        .then(() => {
            toast.success('Link copied to clipboard!');
        })
        .catch(err => {
            console.error('Failed to copy the text: ', err);
        });
};



  
  return (
    <div className="w-[200px] lg:w-[200px] absolute z-50 right-0 top-0 rounded-lg shadow-lg p-8 bg-white text-[14px] ">
      <div className="flex justify-between items-center mb-2 ">
        <div></div>
        <LiaTimesSolid
          className="text-[18px]"
          onClick={() => setIsModalOpen(false)}
        />
      </div>

      <ul className="profileOptions_lists">
        {/* {isUser && (
          <li
            style={{ fontSize: "14px" }}
            onClick={() => console.log("")}
            className="clickModalOpen flex gap-4 items-center"
          >
            <BiHide className="text-[16px]" />
            <span>Hide</span>
          </li>
        )} */}

        <li
          style={{ fontSize: "14px" }}
          onClick={copyToClipboard}
          className="clickModalOpen flex gap-4 items-center"
          
        >
          <MdOutlineContentCopy className="text-[16px]" />
          <span>Copy link</span>
        </li>

        <li
          style={{ fontSize: "14px" }}
          onClick={handlePromoteMovie}
          className="clickModalOpen flex gap-4 items-center"
        >
          <IoOptions className="text-[16px]" />
          <span>Promote</span>
        </li>

        {isUser && (
          <li
            style={{ fontSize: "14px" }}
            onClick={deleteMovieMutate}
            className="clickModalOpen flex gap-4 items-center"
          >
            <MdDeleteOutline className="text-[16px]" />
            <span>{deleteMovieStatus === "pending" ? <SpinnerBlack /> : "Delete"}</span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default TvOptionsModal;
