import useGetEventDetails from "Hooks/useGetEventDetails";
import { useEffect, useRef } from "react";
import logo from '../../assets/images/logo-thumb.png'

const SuccessTicketPurc = () => {
  
  const {handlePurchaseTicketSuccessClose, isPurchaseTicketSuccessOpen} = useGetEventDetails()
  const pageRef = useRef(null);

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    handleGetRef();
  }, [isPurchaseTicketSuccessOpen]);

  return (
    <div className="detail-bx-container bagw" ref={pageRef}>
      <div className="searchEmpty-bbox ddwn">
        <div className="empt-box-sea">
          <img src={logo} alt="" /> 
          <div className="full-notin max-w-[450px]">
            Your ticket purchase is being processed. You will receive a mail
            containing your ticket soon.
          </div>

          <button
            className="w-full lg:max-w-[400px] mt-3 bg-[#4f0da3] text-[#fff] py-3 text-[14px] rounded"
            onClick={handlePurchaseTicketSuccessClose}
          >
            Back to tickets
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessTicketPurc;
