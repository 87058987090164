import { FaPlay } from "react-icons/fa";
import { useNavigate } from "react-router-dom";



function limitString(str, limit = 20) {
  if (str?.length > limit) {
    return `${str.substring(0, limit)}...`;
  }
  return str;
}

const ChannelMovieCard = ({ data }) => {
  const navigate = useNavigate()
  return (
    <div className="p-2 grid gap-4 grid-flow-col lg:auto-cols-[29%] md:auto-cols-[70%] auto-cols-[80%] overflow-x-scroll overscroll-contain">
      {data?.map((item) => (
        <div key={item.id} className="h-[200px] rounded shadow-sm py-2">
          <div className="h-[75%] w-full relative">
            <img
              src={item?.cover_photo}
              alt={item?.title}
              className="w-full h-full object-cover rounded hover:text-[#4f0da3] transition duration-300"
            >
              
            </img>
            <div className="bg-neutral-100 h-[40px] w-[40px] opacity-50 rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center" onClick={() => navigate(`/tv/movie/${item?.id}`)}>
              <FaPlay className="text-neutral-800 text-[14px]" />
            </div>
            {/* <div className="bottom-2 left-3 absolute">
              <FaHeart className="text-[24px] text-[#4f0da3] opacity-80"/>
            </div> */}
          </div>

          <div className="mt-2 px-2">
            <div className="text-neutral-600 text-[13px]">
              {limitString(item?.title)}
            </div>
            {/* <div className="text-neutral-600 text-[13px]">({item?.date})</div> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChannelMovieCard;
