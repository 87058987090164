import { useQuery } from "@tanstack/react-query";
import { getWatched} from "api/services/tv";

export const useGetWatched = () => {
  const {
    isLoading: isWatchedMoviesLoading,
    data: watchedMovies,
    status: watchedMoviesStatus,
    error: watchedMoviesError,
    refetch: watchedMoviesRefetch
  } = useQuery({
    queryKey: ["watched"],
    queryFn: getWatched,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    keepPreviousData: false,
  });

  return {
    isWatchedMoviesLoading,
    watchedMovies,
    watchedMoviesStatus,
    watchedMoviesError,
    watchedMoviesRefetch
  };
};
