import React, { useEffect, useRef, useState } from "react";
import { GoFilter } from "react-icons/go";
import StepThird from "./StepThird/StepThird";
import useGetEventDetails from "Hooks/useGetEventDetails";
import TicketSearchComp from "./TicketSearchComp";
import { useNavigate } from "react-router-dom";
import useCreateUpdateEvent from "Hooks/useCreateUpdateEvent";
import Spinner from "components/Spinner";
import VerifyConfirmationButton from "components/Modals/VerifyConfirmationButton";
import useProfileModal from "Hooks/profile/useProfileModal";

const CreateEventTictetFormThree = () => {
  const { sellTicketOne, sellTicketTwo, isEditing, editDetail } =
    useGetEventDetails();

  const { createTicket, updateTicket, createTicketStatus, updateTicketStatus } =
    useCreateUpdateEvent(editDetail?.id);

  const { isSuccessModal } = useProfileModal();

  const [feesOption, setFeesOption] = useState("");
  const [showRemainingTickets, setShowRemainingTickets] = useState(
    editDetail?.show_remaining_ticktet || false
  );
  const [isEventListingPrivate, setIsEventListingPrivate] = useState(
    editDetail?.is_private || false
  );

  const [feesOptionError, setFeesOptionError] = useState("");

  const pageRef = useRef(null);

  const navigate = useNavigate();

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth", top: -2 });
    }
  };

  useEffect(() => {
    handleGetRef();
  }, []);

  const handleToggleRemainingTickets = () => {
    setShowRemainingTickets(!showRemainingTickets);
  };

  const handleToggleEventListing = () => {
    setIsEventListingPrivate(!isEventListingPrivate);
  };

  const goBack = () => {
    navigate("/ticket/sell-ticket/create-event-two");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (feesOption === "") {
      setFeesOptionError("Fee option is required");
      setTimeout(() => {
        setFeesOptionError("");
      }, 3000);
    } else {
      const ticketData = {
        title: sellTicketOne.eventTitle,
        description: sellTicketOne.eventDescription,
        venue_name: sellTicketOne.venueName,
        venue_address: sellTicketOne.venueAddress,
        regular_ticket_price: sellTicketTwo.regular_ticket_price,
        regular_ticket_quantity: sellTicketTwo.regular_ticket_quantity,
        vip_ticket_price: sellTicketTwo.vip_ticket_price,
        vip_ticket_quantity: sellTicketTwo.vip_ticket_quantity,
        event_image: sellTicketOne.image,
        event_category: sellTicketTwo.ticketCategory,
        is_public: !isEventListingPrivate,
        is_private: isEventListingPrivate,
        is_promoted: true,
        start_date: sellTicketOne.startDate,
        end_date: sellTicketOne.endDate,
        start_time: sellTicketOne.startTime,
        end_time: sellTicketOne.endTime,
        regular_ticket_type: "regular",
        vip_ticket_type: "vip",
        ticket_name: sellTicketOne.eventTitle,
        show_tickets_remaining: showRemainingTickets,
        transaction_fee_option:
          feesOption === "Passed to buyers" ? "BUYER" : "ACCOUNT",
      };

      createTicket(ticketData);
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    if (feesOption === "") {
      setFeesOptionError("Fee option is required");
      setTimeout(() => {
        setFeesOptionError("");
      }, 3000);
    } else {
      const ticketData = {
        title: sellTicketOne.eventTitle,
        description: sellTicketOne.eventDescription,
        venue_name: sellTicketOne.venueName,
        venue_address: sellTicketOne.venueAddress,
        regular_ticket_price: sellTicketTwo.regular_ticket_price,
        regular_ticket_quantity: sellTicketTwo.regular_ticket_quantity,
        vip_ticket_price: sellTicketTwo.vip_ticket_price,
        vip_ticket_quantity: sellTicketTwo.vip_ticket_quantity,
        event_image: sellTicketOne.image,
        event_category: sellTicketTwo.ticketCategory,
        is_public: !isEventListingPrivate,
        is_private: isEventListingPrivate,
        is_promoted: false,
        start_date: sellTicketOne.startDate,
        end_date: sellTicketOne.endDate,
        start_time: sellTicketOne.startTime,
        end_time: sellTicketOne.endTime,
        regular_ticket_type: "regular",
        vip_ticket_type: "vip",
        ticket_name: sellTicketOne.eventTitle,
        show_tickets_remaining: showRemainingTickets,
        transaction_fee_option:
          feesOption === "Passed to buyers" ? "BUYER" : "ACCOUNT",
      };

      updateTicket(ticketData);
    }
  };

  return (
    <form
      className="p-4"
      ref={pageRef}
      onSubmit={isEditing ? handleUpdate : handleSubmit}
    >
      <div>
        <TicketSearchComp
          label={isEditing ? "Edit event" : "Create event"}
          /* COME BACK FOR THIS */
          handleCloseContainerClick={goBack}
          add={"add"}
        />
      </div>
      <StepThird />

      <div className="event-ead">Additional information</div>
      <div className="event-txt">Tell us a bit more about your event</div>
      <div className="main-event-container">
        <div className="add-info-cont flex">
          <div className="add-inf-txtx">
            <div className="main-add-info" style={{ fontWeight: "600" }}>
              Show remaining tickets
            </div>
            <div className="text-[14px] text-neutral-400">
              Show the number of remaining tickets on your events
            </div>
          </div>
          <div className="toggle-container">
            <label className="switch">
              <input type="checkbox" onChange={handleToggleRemainingTickets} />
              <span
                className={`slider ${showRemainingTickets ? "on" : "off"}`}
              />
            </label>
          </div>
        </div>

        <hr className="add-info-line" />

        <div className="event-ead bw-bit">Event Listing Privacy</div>
        <div className="add-info-cont flex">
          <div className="add-inf-txtx">
            {!isEventListingPrivate ? (
              <>
                <div className="text-[15px] font-semibold text-neutral-500">
                  Public
                </div>
                <div className="text-[14px]  text-neutral-400">
                  Your event can be found by anyone on our app, our promotion
                  partners, and search engines
                </div>
              </>
            ) : (
              <>
                <div className="text-[15px] font-semibold text-neutral-500">
                  Private
                </div>
                <div className="text-[14px]  text-neutral-400">
                  Only people you share invite link can find your event
                </div>
              </>
            )}
          </div>
          <div className="toggle-container">
            <label className="switch">
              <input type="checkbox" onChange={handleToggleEventListing} />
              <span
                className={`slider ${!isEventListingPrivate ? "on" : "off"}`}
              />
            </label>
          </div>
        </div>

        <div className="event-ead">Fees settings</div>
        <div className="text-[14px]  text-neutral-400">
          Please specify if transaction fees would be passed on to buyers or
          charged from your account. We charge 5% + N200.
        </div>
        <div className="flex items-center cursor-pointer py-1 px-4 text-neutral-400 border">
          <GoFilter className="text-[20px] text-neutral-500 rounded" />
          <select
            name=""
            id=""
            value={feesOption}
            onChange={(e) => setFeesOption(e.target.value)}
            className="border-none  bg-white cursor-pointer text-neutral-400 text-[14px] outline-none"
          >
            <option value="">Select an option</option>
            <option value="Passed To Buyers">Passed to buyers</option>
            <option value="Charged from account">Charged from account</option>
          </select>
        </div>
        {feesOptionError && (
          <p className="text-red-500 text-[12px]">{feesOptionError}</p>
        )}

        <button
          type="submit"
          className="w-full mt-3 bg-[#4f0da3] text-[#fff] py-3 text-[14px] rounded hover:opacity-90"
          disabled={
            feesOption === "" ||
            createTicketStatus === "pending" ||
            updateTicketStatus === "pending"
          }
        >
          {createTicketStatus === "pending" ? (
            <Spinner />
          ) : updateTicketStatus === "pending" ? (
            <Spinner />
          ) : (
            "Submit"
          )}
        </button>
      </div>
      {isSuccessModal && (
        <VerifyConfirmationButton
          title="Ticket Created"
          sub="You can now share your unique ticket links to your attendees"
          btnText="Back to tickets"
          ticket
        />
      )}
    </form>
  );
};

export default CreateEventTictetFormThree;
