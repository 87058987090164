import { useFetchVoiceNote } from 'api/services/feeds'
import React from 'react'
import './Feeds-voice.css'

import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css' // Default styles, which you can override
import EmptyImage from '../../../../assets/2gede_nothing_here.svg'

const FeedsVoice = () => {
  const { data: fetchVoicenote, isPending } = useFetchVoiceNote()
  console.log(fetchVoicenote, 'fetchVoicenote')

  const fileBaseUrl = `https://2geda-backend.s3.amazonaws.com`

  const getMimeType = (filename) => {
    const extension = filename.split('.').pop()
    switch (extension.toLowerCase()) {
      case 'mp3':
        return 'audio/mpeg'
      case 'wav':
        return 'audio/wav'
      case 'ogg':
        return 'audio/ogg'
      default:
        return 'audio/mpeg' // Default to mp3 if unsure
    }
  }

  if (isPending) {
    return (
      <div className='feed-images-container'>
        <div className='feed-images-loader'>
          <div className='feed-images-loader-inner'>
            <div className='feed-images-loader-text'>Loading...</div>
          </div>
        </div>
      </div>
    )
  }

  const audioContainerStyle = {
    width: '100%',
    // backgroundImage: `url('images/p1.png')`, // Specify your background image URL
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '10px',
    backgroundColor: 'transparent',
  }
  return (
    <div
      style={{
        display: 'grid',
        // gridTemplateColumns: 'repeat(auto-fit, minmax(140px, 1fr))',
        gap: '1rem',
      }}
    >
      <div className='pb-8'>
        {fetchVoicenote?.length === 0 && (
          <div className='text-3xl'>
            <h1>No Voice note at the moment, please come back later</h1>
            <img src={EmptyImage} alt='nothing here' />
          </div>
        )}
      </div>
      {fetchVoicenote?.map((item) => (
        <div key={item?.file_id}>
          {/* <audio width='100%' height='120' controls>
            <source
              src={fileBaseUrl + item?.file}
              type={getMimeType(item?.file)}
            />
            Your browser does not support the video tag.
          </audio> */}
          <AudioPlayer
            src={item?.file}
            onPlay={(e) => console.log('onPlay')}
            // You can apply custom styles here
            style={audioContainerStyle} // Example: setting transparent background
          />
        </div>
      ))}
    </div>
  )
}

export default FeedsVoice
