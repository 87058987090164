import { HotelsContext } from 'Context/HotelsContext'
import React, { useContext, useEffect } from 'react'
import HotelCard from 'components/Hotels/HotelCard'

const HotelSection = () => {
  const { hotels, fetchHotels } = useContext(HotelsContext)

  useEffect(() => {
    fetchHotels()
  }, [])

  return (
    <div className='bg-white mb-5 p-4'>
      <h2>Hotels</h2>

      <div className='flex gap-6 overflow-auto mt-5'>
        {hotels && hotels.length > 0 ? (
          hotels.map((hotel) => <HotelCard key={hotel.id} data={hotel} />)
        ) : (
          <p className='col-span-full'>No hotels available.</p>
        )}
      </div>
    </div>
  )
}

export default HotelSection
