import React, { useState } from "react";
import { IoMdSearch } from "react-icons/io";
import { Link } from "react-router-dom";
import "./live.css";
import LiveProfiles from "components/Live/LiveProfiles";
import { IoWifiOutline } from "react-icons/io5";
import LiveVideoCard from "./LiveVideoCard";
import LiveGift from "./LiveGift";
import ads from "../../assets/profile_images/Jumia-Celebrate-the-Game.png";

const buttons = ["Business", "Personal"];

const Live = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [clickedButton, setClickedButton] = useState("Business");

  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  const active =
    "justify-center items-center bg-[#FF8A15] rounded-full text-neutral-500 text-[14px] px-10 py-2";
  const inactive =
    "justify-center items-center border border-[#4f0da3] text-[#4f0da3] rounded-full text-[14px] px-10 py-2";

  return (
    <div className="lg:grid lg:grid-cols-12 gap-3 min-h-screen">
      <div className="lg:col-span-8 bg-white lg:overflow-y-auto mt-2 py-4 lg:max-h-screen">
        <div className="mt-2 lg:hidden mx-2 block">
          <Link
            to={`/live/new-live`}
            className="w-full no-underline bg-[#4f0da3] text-white py-3 px-4 flex items-center justify-start"
          >
            <span className="text-[16px]">Start livestream</span>
          </Link>
        </div>
        <div className="lg:grid lg:grid-cols-2 flex items-center justify-between gap-4 px-4 lg:mt-0 mt-4">
          <div className="mt-2">
            <h1>Live</h1>
          </div>
          <div>
            <div className="w-full border-none bg-neutral-100 rounded flex items-center px-4 ">
              <IoMdSearch className="text-black text-[22px]" />
              <input
                type="text"
                className="text-[14px] border-none outline-none"
                placeholder="Find event"
                value={searchTerm}
                onChange={handleInputChange}
              />
              <button className="bg-[#4f0da3] py-2 px-4 rounded text-white text-[12px]">
                Search
              </button>
            </div>
          </div>
        </div>

        {/* SECOND  */}
        <div className="p-4">
          {/* Promoted */}
          <div>
            <div className="flex gap-2 items-center">
              <h4>Promoted</h4>
              <IoWifiOutline className="text-[18px] mb-2" />
            </div>
            <LiveProfiles />
          </div>
          {/* Categories */}
          <div>
            <h4 className="mt-12">Categories</h4>
            <div className="flex justify-start gap-2 mt-3">
              {buttons?.map((item, index) => (
                <button
                  style={{ color: "black" }}
                  key={index}
                  onClick={() => setClickedButton(item)}
                  className={clickedButton === item ? active : inactive}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
          {/* Live card */}
          <LiveVideoCard />
          {/* AD */}
          <div className="hidden lg:block">
            <img src={ads} alt="Ads" className="w-full h-full object-contain" />
          </div>
          {/* Second card */}
          <LiveVideoCard />
          <LiveVideoCard />
        </div>
      </div>
      <div className="lg:col-span-4 hidden lg:block bg-white mt-2 px-4 pt-4 ">
        <div className="mt-2">
          <Link
            to={`/live/new-live`}
            className="w-full no-underline bg-[#4f0da3] text-white py-3 px-4 flex items-center justify-start rounded"
          >
            <span className="text-[16px]">Start livestream</span>
          </Link>
        </div>
        <LiveGift />
      </div>
    </div>
  );
};

export default Live;
