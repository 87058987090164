import { useGetLikedMovies } from "Hooks/tv/useGetLikedMovies";
import React, { useEffect, useState } from "react";
import { FaHeart, FaPlay } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function limitString(str, limit = 20) {
  if (str?.length > limit) {
    return `${str.substring(0, limit)}...`;
  }
  return str;
}

const MoviesCardItem = ({ item }) => {
  const navigate = useNavigate();
  const { likedMovies } = useGetLikedMovies();

  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    const allLiked = likedMovies?.data?.results?.map(
      (likedItem) => likedItem?.video?.id
    );
    setIsLiked(allLiked?.includes(item?.id));
  }, [likedMovies, item?.id]);

  
  return (
    <div key={item.id} className="h-[200px] shadow-md rounded py-2">
      <div className="h-[75%] w-full relative">
        <img
          src={item?.cover_photo}
          alt={item?.title}
          className="w-full h-full object-cover rounded hover:text-[#4f0da3] transition duration-300"
        />

        <div
          className="bg-neutral-100 h-[50px] w-[50px] opacity-50 rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center"
          onClick={() => navigate(`/tv/movie/${item?.id}`)}
        >
          <FaPlay className="text-neutral-800 text-[16px]" />
        </div>
        {isLiked && (
          <div className="bottom-2 left-3 absolute">
            <FaHeart className="text-[24px] opacity-80 text-[#4f0da3]" />
          </div>
        )}
      </div>

      <div className="mt-2 px-2">
        <div className="text-neutral-600 text-[13px]">
          {limitString(item?.title)}
        </div>
      </div>
    </div>
  );
};

export default MoviesCardItem;
