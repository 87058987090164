/* eslint-disable eqeqeq */
import React from "react";
import { Link } from "react-router-dom";
import TextMessage from "./TextMessage";
import Spin from "components/Spin/Spin";

const PrivateMessages = ({
  isConversationLoading,
  isProfileDataLoading,
  userMessages,
  sortedMessages,
  unreadMessages,
  myID,
}) => {
  if (isConversationLoading || isProfileDataLoading) {
    return <Spin />;
  }

  
  return (
    <div>
      {!userMessages?.length ? (
        <>
          <div
            style={{ border: "1px solid #d1d5db" }}
            className="p-4 bg-neutral-200 rounded flex flex-col mx-auto mt-[60px] mb-[30px] text-center items-center w-[250px] h-[140px] shadow-sm"
          >
            <div>
              <img src="images/new.png" className="w-[60px]" alt="chathead" />
            </div>
            <div className="mt-2">
              <h3 className="text-center lg:text-[13px] text-[12px] font-normal">
                Start a conversation
              </h3>
              <h3 className="text-center lg:text-[13px] text-[12px] font-normal">
                Your messages will appear here
              </h3>
            </div>
          </div>
          <div className="flex justify-center">
            <Link
              to="/connect"
              className="text-[#4f0da3] font-semibold text-[14px] mt-4 text-center"
            >
              Connect with people now
            </Link>
          </div>
        </>
      ) : (
        <div className="bg-white p-4 mt-[30px] rounded">
          <div className=" flex  items-center gap-3">
            <div
              style={{ border: "1px solid #d1d5db" }}
              className=" h-[35px] text-[16px] font-semibold rounded-full flex items-center justify-center w-[35px]"
            >
              {unreadMessages}
            </div>
            <h3 className="text-[13px] font-semibold mt-2">Unread messages</h3>
          </div>
          {sortedMessages?.map((item) => (
            <TextMessage
              key={item?.id}
              text={item?.messages[0]?.text}
              date={item?.messages[0]?.created_at}
              friend={item?.participants}
              userID={myID}
              isRead={true}
              messages={item?.messages}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default PrivateMessages;
