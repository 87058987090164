import Description from "components/newCommerce/typography/txtDescription";
import Stacked from "components/newCommerce/shared/Stacked";
import Header from "components/newCommerce/typography/txtHeader";
import { useContext, useEffect } from "react";
import { ButtonSide } from "components/newCommerce/shared/sideButton";
import { ModalContext } from "Context/ModalContext";
import Minimalchair from "assets/images/Minimal_chair.png";
import Template from "components/newCommerce/shared/template";
import { FormatwithComma } from "utils/commerceUtils";
const Previewpromote = ({ click, plannedchose }) => {
  const { promotedDetails } = useContext(ModalContext);
  return (
    <Stacked d="column" g={2} jc={"center"} ai={"center"} cname={"pt-[5rem]"}>
      {/* preview-item */}

      <div>
        {/* content-accepts-array -notobject */}
        <Template content={promotedDetails} hidebuy={true} />
      </div>

      {/* payment-details */}
      <Stacked
        d="column"
        g={0.5}
        styles={{
          border: "1px solid #4f0da3 ",
          width: "clamp(300px, 350px, 370px)",
        }}
        cname={"py-2 pr-3 pl-2 text-left rounded-lg"}
      >
        <Description title={"You are paying"} fs={"13px"} />
        <Header
          title={FormatwithComma(plannedchose?.price)
            .toString()
            .replace(/^/, "₦")}
        />
        <Header title={"Being payment for Product promotion"} />
      </Stacked>

      {/* wallet-payment */}
      <Stacked
        d="row"
        styles={{ width: "clamp(300px, 350px, 370px)" }}
        ai={"center"}
        jc={"space-between"}
      >
        <div className="flex flex-col gap-y-[0.2rem]">
          <Header title={"Pay from wallet"} />
          <Description title={"₦0.00"} />
        </div>

        <button className="text-[13px] bg-[#4f0da3] rounded-[20px] text-[#ffff] py-2 px-4">
          Fund Wallet
        </button>
      </Stacked>
      <ButtonSide
        title="Pay Now >>"
        bg="#4F0DA3"
        cl="#ffff"
        // isDisabled={disable}
        styles={{
          paddingInline: "8rem",
          marginTop: "5rem",
          paddingBlock: ".9rem",
          fontSize: "1.4rem",
        }}
        br="5px"
        click={click}
      />
    </Stacked>
  );
};

const mockItem = [
  {
    id: 1,
    name: "Minimal Chair",
    price: 2500,
    product_image: Minimalchair,
  },
];

export default Previewpromote;
