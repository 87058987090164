/* eslint-disable eqeqeq */
import { useState } from "react";
import { BiMessageDetail } from "react-icons/bi";
import { FiPlusCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import PrivateMessages from "./PrivateMessages";
import PublicMessages from "./PublicMessages";
import ActiveMessages from "./ActiveMessages";
import { useProfile } from "Hooks/profile/useProfile";
import { useGetConversation } from "Hooks/chat/useGetConversation";
import { usePublicMessages } from "Hooks/chat/usePublicMessages";

const sortArrayByMostRecentMessage = (arr) => {
   return arr?.sort((a, b) => {
     const aLatestMessage = a.messages[0].created_at;
     const bLatestMessage = b.messages[0].created_at;
     return new Date(bLatestMessage) - new Date(aLatestMessage);
   });
 };


const Chat = () => {
   const [mode, setMode] = useState('private')


   const setToPrivate = () => {
      setMode('private')
   }

   const setToPublic = () => {
      setMode('public')
   }

   const setToActive = () => {
      setMode('active')
   }

   const { profileData, isProfileDataLoading, profileDataError } = useProfile();
   const { conversation, isConversationLoading } = useGetConversation();
   const {publicMessages, isPublicMessagesLoading, publicMessagesError} = usePublicMessages()

  const myID = profileData?.data?.data?.user?.id;

  //console.log('public messages', publicMessages)



  
  const filterUserChats = (array, userID) => {
      return array?.filter((item) => {
          const [firstParticipant, secondParticipant] = item.participants;
          return firstParticipant.id == userID || secondParticipant.id == userID;
        });
    };
    
    const userMessages = filterUserChats(conversation?.data, myID)

    console.log('userMessages', userMessages)
 
  const sortedMessages = sortArrayByMostRecentMessage(userMessages);

  
    function countFilteredMessages(data, senderId) {
      let count = 0;
    
      data?.forEach(conversation => {
        conversation.messages.forEach(message => {
          if (message.read === false && message.sender != senderId) {
            count++;
          }
        });
      });
    
      return count;
    }
    
    
  const unreadMessages = countFilteredMessages(userMessages, myID)
   

  return (
    <div className="min-h-screen full px-4 py-4 bg-neutral-100">
      <h2 className="my-4">Chats</h2>
      <div className="grid lg:grid-cols-3 gap-4">
        <div className={`h-[170px] bg-purple-300 hover:opacity-80 px-3 py-2 rounded cursor-pointer flex flex-col justify-between relative shadow-sm
        ${mode === "privatee" && 'bg-purple-500'}
        `} onClick={setToPrivate}>
          {/* <div className="rounded-full h-10 w-10 absolute top-0 left-0 bg-neutral-400"></div> */}
          <div className="flex justify-between items-center py-2 cursor-pointer">
            <BiMessageDetail className="text-[17px]" />
            <FiPlusCircle className="text-[17px]"/>
          </div>
          <div className="flex flex-col cursor-pointer">
            <p className="lg:text-[26px] text-[20px] font-medium">{unreadMessages}</p>
            <p className="lg:text-[16px] text-[16px] font-medium">Private messages</p>
          </div>
        </div>
        <div className={`h-[170px] bg-orange-200 hover:opacity-80 px-3 py-2 cursor-pointer rounded flex flex-col justify-between relative shadow-sm
         ${mode === "publicc" && 'bg-[#f8cf9d]'}
         `}  onClick={setToPublic}>
          {/* <div className="rounded-full h-16 w-16 absolute top-0 -left-4 bg-neutral-400"></div> */}
          <div className="flex justify-between items-center py-2 z-20 cursor-pointer">
            <BiMessageDetail className="text-[17px]" />
            <FiPlusCircle className="text-[17px]"/>
          </div>
          <div className="flex flex-col cursor-pointer">
            <p className="lg:text-[26px] text-[20px] font-medium">0</p>
            <p className="lg:text-[16px] text-[16px] font-medium">Public messages</p>
          </div>
        </div>
        <div className={`h-[170px] bg-[#CAE6B2] hover:opacity-80 cursor-pointer px-3 py-2 rounded flex flex-col justify-between relative shadow-sm
         ${mode === "activee" && 'bg-[#a5bd82]'}
         `} onClick={setToActive}>
          {/* <div className="rounded-full h-10 w-10 absolute top-0 left-0 bg-neutral-400"></div> */}
          <div className="flex justify-between items-center py-2"></div>
          <div className="flex flex-col">
            <p className="lg:text-[26px] text-[20px] font-medium">{userMessages?.length}</p>
            <p className="lg:text-[16px] text-[16px] font-medium">Active friends</p>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <img src="images/ads4.png" alt="Advertisement" />
      </div>

      <div className="w-full mt-4">
        {mode === "private" && <PrivateMessages isProfileDataLoading={isProfileDataLoading} isConversationLoading={isConversationLoading} sortedMessages={sortedMessages} userMessages={userMessages} myID={myID} unreadMessages={unreadMessages}/>}
        {mode === "public" && <PublicMessages />}
        {mode === "active" && <ActiveMessages isProfileDataLoading={isProfileDataLoading} isConversationLoading={isConversationLoading} sortedMessages={sortedMessages} userMessages={userMessages} myID={myID}/>}
      </div>
    </div>
  );
};

export default Chat;


