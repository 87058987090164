import { useMutation, useQueryClient } from "@tanstack/react-query";
import { stickUser, unstickUser } from "api/services/profile";

const useStickUnstick = (id) => {
  const queryClient = useQueryClient();

  const { status: stickStatus, mutate: stickMutate } = useMutation({
    mutationFn: () => stickUser(id),

    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return ["allAccounts", "profile"].includes(query.queryKey[0]);
        },
      });

      // toast.success("sticked user");
    },
  });

  const { status: unstickStatus, mutate: unstickMutate } = useMutation({
    mutationFn: () => unstickUser(id),

    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return ["allAccounts", "profile"].includes(query.queryKey[0]);
        },
      });

      // toast.success("unsticked user");
    },
  });

  return { stickStatus, stickMutate, unstickStatus, unstickMutate };
};

export default useStickUnstick;
