import React from "react";
import { FaChevronRight} from "react-icons/fa";
import { PiMegaphoneBold } from "react-icons/pi";
import { PiMegaphoneLight } from "react-icons/pi";
import { GoCheck } from "react-icons/go";
import { useNavigate } from "react-router-dom";


const PublicMessages = () => {
    const navigate = useNavigate()
  return (
    <div className="mt-[30px]">
      <div
        style={{ border: "1px solid #d1d5db" }}
        className=" rounded flex items-center justify-between cursor-pointer"
        onClick={() => navigate('/chat/broadcast')}
      >
        <div className="flex items-center gap-4 py-3 px-3">
          <PiMegaphoneBold className="text-[22px] text-[#4f0da3]" />

          <div className="">
            <h3 className="text-[15px] font-semibold">Create public message</h3>
            <h3 className="text-neutral-400 font-normal text-[13px]">
              Send a message to everyone on 2GEDA
            </h3>
          </div>
        </div>
        <div>
          <FaChevronRight className="text-[14px] text-neutral-300 mr-4" />
        </div>
      </div>
      {/* <div
        style={{ borderBottom: "1px solid #dde2e9" }}
        className=" flex items-center justify-between mt-2 cursor-pointer"
      >
        <div className="flex items-center gap-4 py-3 px-2">
          <div className="bg-[#4f0da3] h-[40px] rounded-full flex items-center justify-center w-[40px]">
            <PiMegaphoneLight className="text-[22px] text-white" />
          </div>

          <div className="">
            <h3 className="text-[13px] font-semibold">Twins Arcade</h3>
            <h3 className="text-neutral-300 font-normal text-[13px]">
              This is a broadcast message
            </h3>
          </div>
        </div>
        <div className="flex justify-end flex-col items-end">
          <h3 className="text-[#4f0da3] font-normal text-[11px] flex gap-2 items-center">11:00pm</h3>
          <div className="py-2 px-2 bg-[#4f0da3] text-white text-[11px] rounded flex items-center justify-center h-8 ">1</div>
        </div>
      </div>
      <div
        style={{ borderBottom: "1px solid #dde2e9" }}
        className="flex items-center justify-between cursor-pointer"
      >
        <div className="flex items-center gap-4 py-3 px-2">
          <div className="bg-[#4f0da3] h-[40px] rounded-full flex items-center justify-center w-[40px]">
            <PiMegaphoneLight className="text-[22px] text-white" />
          </div>

          <div className="">
            <h3 className="text-[13px] font-semibold">You</h3>
            <h3 className="text-neutral-300 font-normal text-[13px] flex gap-2 items-center">
                <GoCheck  className="text-neutral-400"/>
              You should save the design page
            </h3>
          </div>
        </div>
        <div className="flex justify-end flex-col items-end">
          <h3 className="text-neutral-300 font-normal text-[11px] flex gap-2 items-center">2:00pm</h3>
        </div>
      </div> */}
    </div>
  );
};

export default PublicMessages;

