import { useMutation, useQueryClient } from "@tanstack/react-query";
import { incrementPlaysFn} from "api/services/tv";

const useIncrementPlays = () => {
  const queryClient = useQueryClient();

  const {
    status: incrementPlaysStatus,
    mutate: incrementPlays,
    error: incrementPlaysError
  } = useMutation({
    mutationFn: (formData) => incrementPlaysFn(formData),

    onSuccess: () => {
        queryClient.invalidateQueries({
            queryKey: ["usermovies"],
          });
    },
  });

  return {
    incrementPlays,
    incrementPlaysStatus,
    incrementPlaysError
  };
};

export default useIncrementPlays;
