import ProfileOverview from "components/ProfileComponents/ProfileOverview";
import "./style.css";

const Profile = () => {

  return (
    <ProfileOverview />
  );
};

export default Profile;
