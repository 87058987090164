import LikedMoviesCardItem from "./LikedMoviesCardItem";

const LikedMoviesCard = ({ data, history }) => {

  return (
    <div
      className={`p-2 grid gap-4 grid-flow-col lg:auto-cols-[61%] md:auto-cols-[70%] auto-cols-[80%] overflow-x-scroll overscroll-contain
    ${history && "lg:auto-cols-[60]  md:auto-cols-[70%] auto-cols-[80%]"}
    `}
    >
      {data?.map((item) => (
        <div key={item?.video?.id} className="h-[200px] rounded shadow-sm py-2">
          <LikedMoviesCardItem item={item}/>
        </div>
      ))}
    </div>
  );
};

export default LikedMoviesCard;
