import Header from "components/newCommerce/typography/txtHeader";
import Description from "components/newCommerce/typography/txtDescription";

import { ModalContext } from "Context/ModalContext";
import { useContext, useEffect, useState } from "react";
import useLocalstorage from "api/hooks/commerce/uselocalStorage";
const align = {
  textAlign: "left",
};
const Selectaddress = ({ show, curr }) => {
  const { nextCartPopup, setDetailsBuyerEdit } = useContext(ModalContext);
  const [detailsBuyer, setdetails] = useState([]);
  const { getFormlist } = useLocalstorage();

  const [checked, setIschecked] = useState(
    new Array(JSON.parse(getFormlist)?.length).fill(false)
  );

  useEffect(() => {
    getFormlist && setdetails(JSON.parse(getFormlist));
    setDetailsBuyerEdit(null);
  }, [getFormlist, setDetailsBuyerEdit]);

  const editAddress = (payload, args) => {
    nextCartPopup(payload);
    setDetailsBuyerEdit(args);
  };

  const handleToggle = (index, args) => {
    // setIschecked(!checked);
    const newChecked = checked.map((item, ind) =>
      ind === index ? true : false
    );
    setIschecked(newChecked);

    // console.log(args);

    if (args) {
      show(false);
      curr(args);
    } else {
      show(true);
    }
  };

  useEffect(() => {
    console.log(checked);
  }, [checked]);
  return (
    <>
      {detailsBuyer.length < 1 ? (
        <>
          <h4 className="text-center px-3">No Details Added Yet</h4>
        </>
      ) : (
        detailsBuyer.map((content, index) => {
          return (
            <div
              key={index}
              className="flex justify-between w-full border border-[#f5f5f5] border-solid rounded-md py-4 items-start bg-white px-3"
            >
              <div
                className="flex gap-3 items-start"
                onClick={() => {
                  handleToggle(index, content);
                }}
              >
                <div>
                  <input
                    type="radio"
                    name="address-default"
                    id="dfaddress"
                    checked={checked[index]}
                    onChange={() => handleToggle(index, content)}
                    className="mt-[.3rem]"
                    style={{ width: "17px", height: "17px" }}
                  />
                </div>

                <div className="block">
                  <Header
                    title={`${content.fname} ${content.secname}`}
                    cl="#000000"
                    sx={align}
                  />
                  <Description title={content.del_address} sx={align} />
                  <Description
                    title={`${content.city}, ${content.region}`}
                    sx={align}
                  />
                  <Description title={content.phone_number} sx={align} />
                  {content.isdefault && (
                    <Description
                      title="Deafult"
                      fs="12px"
                      cl="#000000"
                      sx={{
                        width: "max-content",
                        padding: "3px 5px",
                        marginTop: "3px",
                        background: "rgb(225 225 225 /.6)",
                      }}
                    />
                  )}
                </div>
              </div>
              <button
                className="bg-[transparent] text-[13px] text-[#4f0da3] p-1"
                onClick={() => editAddress("buyerDetails", content)}
              >
                Edit
              </button>
            </div>
          );
        })
      )}
    </>
  );
};

export default Selectaddress;
