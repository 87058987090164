import React, { useState, useEffect } from 'react'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { GoPlusCircle } from 'react-icons/go'
import { FaCirclePlus } from 'react-icons/fa6'
import { RxCross2 } from 'react-icons/rx'
import _2gedaLogo from 'assets/logo.png'
import MovieImage1 from 'assets/images/sample-image-1.png'
import MovieImage2 from 'assets/images/sample-image-2.png'
import { MdSend } from 'react-icons/md'
import { MdCancel } from 'react-icons/md'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation, Autoplay } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import './lifestyle.css'
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material'
import TextField from '@mui/material/TextField'
import { IoTextOutline } from 'react-icons/io5'
import { AiOutlineUserAdd } from 'react-icons/ai'
import { useCreateStatus } from 'api/hooks/feeds'
import Custombutton from 'components/Custom-button/Custombutton'
import {
  useFetchLifeStyle,
  useFetchSingleLifeStyle,
  usePostLifeStyle,
  useUserFetchLifeStyle,
} from 'api/services/feeds'
import { formatDistanceToNow } from 'date-fns'
import { useQueryClient } from '@tanstack/react-query'
import TagFriends from 'components/Modals/Post-form-modals/tag-friends/TagFriends'

const LifestyleStatus = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [inputCaption, setInputCaption] = useState(false)
  const [statusCaption, setStatusCaption] = useState('')
  const [images, setImages] = useState([])
  // const [taggedUsers, setTaggedUsers] = useState([]);
  const queryClient = useQueryClient()
  const [isTagsFrd, setIsTagsFrd] = useState(false)
  const [checkedFriends, setCheckedFriends] = useState([])
  const [taggedUserIds, setTaggedUserIds] = useState([])

  // State to store the selected ID
  const [selectedId, setSelectedId] = useState(null)

  const { data: lifeStyle, isPending: loadingStatus } = useFetchLifeStyle()
  const { data: userLifeStyle, isPending: userLoadingStatus } =
    useUserFetchLifeStyle()
  // const { data: singleLifeStyle, isPending: loadingleStatus } =
  //   useFetchSingleLifeStyle()
  const { data: singleLifeStyle, isPending: loadingleStatus } =
    useFetchSingleLifeStyle(selectedId)

  const { mutateAsync: postLifeStyle, isPending } = usePostLifeStyle()
  const handleCloseModal = () => setIsOpen(false)
  const pagination = {
    clickable: true,
    type: 'bullets',
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + ' ' + '</span>'
    },
  }

  console.log(lifeStyle, 'life style')
  console.log(userLifeStyle, ' style')
  console.log(singleLifeStyle, 'single life style')

  const handleImageChange = (e) => {
    const fileList = Array.from(e.target.files)
    setImages([...images, ...fileList])
  }

  const deleteImage = (index) => {
    const updatedImages = [...images]
    updatedImages.splice(index, 1)
    setImages(updatedImages)
  }

  const handleShareStatus = async () => {
    try {
      let data = new FormData()
      // Append selected image to formData if available
      if (images.length > 0) {
        images.map((image) => data.append('file', image))
      }
      data.append('caption', statusCaption)
      data.append('tagged_users', taggedUserIds)
      const res = await postLifeStyle(data)
      if (res.data.status) {
        console.log(res, 'post')
        queryClient.invalidateQueries(['get_lifestyle'])
      }
    } catch (error) {
      console.log({ error: error.message })
    }
  }

  useEffect(() => {
    if (images.length > 0) {
      setIsEdit(true)
    }
  }, [images.length])

  const handleEditStatus = () => {
    setIsOpen(true)
    setIsEdit(true)
  }

  const handleFriendCheck = (item) => {
    if (checkedFriends.includes(item)) {
      setCheckedFriends(
        checkedFriends.filter((friend) => friend.id !== item?.id)
      )
    } else {
      setCheckedFriends([...checkedFriends, item])
    }
  }

  const handleRemoveTagFrd = (index) => {
    const updatedFriends = [...checkedFriends]
    updatedFriends.splice(index, 1)
    setCheckedFriends(updatedFriends)
  }

  useEffect(() => {
    setTaggedUserIds(checkedFriends.map((item) => item?.id))
  }, [checkedFriends])

  const handleTagFrdClick = () => {
    setIsTagsFrd(true)
  }

  const handleCloseTagFrdClick = () => {
    setIsTagsFrd(false)
  }

  // const handleViewStatus = async (id) => {
  //   // setIsEdit(false)
  //   // setIsOpen(true)
  //   // setImages([])
  //   console.log(id, 'iddd')
  //   const status = lifeStyle.find((item) => item.id === id)
  //   setIsEdit(false)
  //   setIsOpen(true)
  //   setStatusCaption(status?.caption || '')
  //   setImages([{ file: status?.file }])
  // }

  const handleViewStatus = async (id) => {
    setSelectedId(id)
    setIsOpen(true)
    setIsEdit(false)
    setStatusCaption('')
    setImages([])
  }

  // console.log(singleLifeStyle, 'single')

  const fileBaseUrl = `https://2geda-backend.s3.amazonaws.com`

  return (
    <>
      <div className='status-container px-12 lg:px-0'>
        <div className='add-status-btn'>
          <button
            onClick={() => {
              handleEditStatus()
            }}
          >
            <GoPlusCircle size={40} />
          </button>
        </div>
        {userLifeStyle?.map((item) => (
          <div key={item?.id} className='user-status-item'>
            <Avatar
              src={item?.file}
              sx={{
                border: '2px solid #4f0da3',
                width: '40px',
                height: '40px',
                cursor: 'pointer',
              }}
              onClick={() => {
                handleViewStatus(item?.id)
              }}
            />
            <span>{item?.user?.username}</span>
          </div>
        ))}
      </div>

      <div>
        <Modal open={isOpen} onClose={handleCloseModal}>
          <Box className='lifestyle-modal'>
            <Box className='lifestyle-modal-header'>
              <div className='lifestyle-modal-logo'>
                <img src={_2gedaLogo} alt='modal-logo' />
                <h3>2geda</h3>
              </div>
              <div className='lifestyle-modal-cancel-btn'>
                <RxCross2
                  size={24}
                  onClick={handleCloseModal}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </Box>
            <div className='lifestyle-main-display'>
              <div className='left-display'>
                <label htmlFor='upload-status'>
                  <FaCirclePlus size={36} fill='#7f2bee66' />
                  <div className='btn-text-wrapper'>
                    <h4 className='m-0'>Your lifestyle</h4>
                    <p className='text-base'>Share an event or happening</p>
                  </div>
                  <input
                    id='upload-status'
                    type='file'
                    accept='image/*'
                    multiple
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                  />
                </label>
                <div className='friends-status'>
                  <div
                    className='flex align-center gap-2 mb-3 cursor-pointer'
                    onClick={() => {
                      handleViewStatus(selectedId)
                    }}
                  >
                    {/* <div className='flex items-center justify-center border-1 border-[#4f0da3] border-solid rounded-full p-1 width-[32px] height-[32px] cursor-pointer'>
                      <Avatar
                        src={'/broken-image.jpg'}
                        sx={{
                          width: '32px',
                          height: '32px',
                          cursor: 'pointer',
                        }}
                      />
                    </div>
                    <span className='font-bold text-lg capitalize'>You</span>
                    <span className='hide-status-detail'>2 items</span>
                    <span className='flex items-center hide-status-detail'>
                      <span className='status-time-indicato hide-status-detailr'></span>
                      3m ago
                    </span> */}
                  </div>
                  {userLifeStyle?.map((item) => (
                    <div
                      key={item?.id}
                      className='flex align-center gap-2 mb-3 cursor-pointer'
                      onClick={() => {
                        handleViewStatus(item?.id)
                      }}
                    >
                      <div className='flex items-center justify-center border-1 border-[#4f0da3] border-solid rounded-full p-1 width-[32px] height-[32px]'>
                        {' '}
                        <Avatar
                          src={item?.file}
                          sx={{
                            width: '32px',
                            height: '32px',
                          }}
                        />
                      </div>
                      <span className='font-bold text-lg capitalize'>
                        {item?.user?.username}
                      </span>
                      <span className='flex items-center hide-status-detail'>
                        <span className='status-time-indicator hide-status-detail'></span>

                        {/* {formatDistanceToNow(new Date(item?.created_at))} */}
                      </span>
                    </div>
                  ))}
                  {lifeStyle?.map((item) => (
                    <div
                      key={item?.id}
                      className='flex align-center gap-2 mb-3 cursor-pointer'
                      onClick={() => {
                        console.log(item?.id, 'fffff')
                        handleViewStatus(item?.id)
                      }}
                    >
                      <div className='flex items-center justify-center border-1 border-[#4f0da3] border-solid rounded-full p-1 width-[32px] height-[32px]'>
                        {' '}
                        <Avatar
                          src={item?.file}
                          sx={{
                            width: '32px',
                            height: '32px',
                          }}
                        />
                      </div>
                      <span className='font-bold text-lg capitalize'>
                        {item?.user?.username}
                      </span>
                      {/* <span className='hide-status-detail'>2 items</span> */}
                      <span className='flex items-center hide-status-detail'>
                        <span className='status-time-indicator hide-status-detail'></span>

                        {/* {formatDistanceToNow(new Date(item?.created_at))} */}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className='right-display'>
                {!isEdit && (
                  <div className='status-app'>
                    <Swiper
                      pagination={pagination}
                      navigation={true}
                      modules={[Pagination, Navigation, Autoplay]}
                      autoplay={{
                        delay: 3000,
                        disableOnInteraction: true,
                      }}
                      loop={false}
                      className='lifestyle-swiper'
                    >
                      {/* {[1, 2, 3, 4, 5, 6].map((i) => (
                      ))} */}
                      <SwiperSlide data-hash='sample-hash'>
                        <div className='status-content !py-12'>
                          <img
                            src={
                              singleLifeStyle?.file
                                ? singleLifeStyle?.file
                                : singleLifeStyle?.file
                            }
                            alt='lifestyle-i'
                          />
                          <div className='lifestyle-caption'>
                            <p>{singleLifeStyle?.caption}</p>
                          </div>
                          <OutlinedInput
                            id='outlined-comment-input'
                            type='text'
                            size='small'
                            endAdornment={
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='comment on lifestyle'
                                  onClick={() => {}}
                                  edge='end'
                                >
                                  <MdSend size={20} color='#bd1f04' />
                                </IconButton>
                              </InputAdornment>
                            }
                            placeholder='comment on this lifestyle'
                            className='comment-input-container !-mt-16'
                          />
                        </div>
                      </SwiperSlide>
                      <div className='status-logo'>
                        <Avatar
                          src={singleLifeStyle?.file}
                          // src={fileBaseUrl + singleLifeStyle?.file}
                          sx={{
                            width: '32px',
                            height: '32px',
                          }}
                        />
                        <div className='status-user-info'>
                          <span>{singleLifeStyle?.user?.username}</span>
                          <span>
                            {' '}
                            {/* {formatDistanceToNow(
                              new Date(singleLifeStyle?.created_at)
                            )} */}
                          </span>
                        </div>
                      </div>
                    </Swiper>
                  </div>
                )}
                {isEdit && (
                  <div className='status-app'>
                    {images?.length === 0 && (
                      <label htmlFor='upload-status' className='cursor-pointer'>
                        <div className='upload-status-btn'>Upload media</div>
                        <input
                          id='upload-status'
                          type='file'
                          accept='image/*'
                          multiple
                          onChange={handleImageChange}
                          style={{ display: 'none' }}
                        />
                      </label>
                    )}
                    {images?.length > 0 && (
                      <Swiper
                        pagination={pagination}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        autoplay={false}
                        loop={false}
                        className='lifestyle-swiper'
                      >
                        {images?.map((image, i) => (
                          <SwiperSlide
                            key={i}
                            className='md:!h-[50rem]'
                            data-hash='sample-hash'
                          >
                            <div className='status-content !py-12'>
                              <button
                                className='delete-lifestyle-btn'
                                onClick={() => {
                                  deleteImage(i)
                                }}
                              >
                                <MdCancel
                                  size={24}
                                  color='#4d4d4d'
                                  style={{ cursor: 'pointer' }}
                                />
                              </button>
                              <div className='flex items-end gap-3 justify-end py-[4px] absolute right-[23%]'>
                                <button
                                  onClick={handleTagFrdClick}
                                  className='status-action-btn'
                                >
                                  tag <AiOutlineUserAdd size={12} />
                                </button>
                                <button
                                  className='status-action-btn'
                                  onClick={() => {
                                    setInputCaption(true)
                                  }}
                                >
                                  text <IoTextOutline size={12} />
                                </button>
                              </div>
                              <div className='lifestyle-caption'>
                                <p>{statusCaption}</p>
                              </div>
                              {inputCaption && (
                                <div className='status-input-caption'>
                                  <div className='flex items-center gap-2'>
                                    <TextField
                                      id='standard-basic'
                                      placeholder='Start typing'
                                      variant='standard'
                                      size='small'
                                      onChange={(e) =>
                                        setStatusCaption(e.target.value)
                                      }
                                    />
                                    <MdCancel
                                      size={20}
                                      color='#4d4d4d'
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setInputCaption(false)
                                        setStatusCaption('')
                                      }}
                                    />
                                  </div>
                                  <button
                                    type='submit'
                                    onClick={() => {
                                      setInputCaption(false)
                                    }}
                                  >
                                    Done
                                  </button>
                                </div>
                              )}
                              <img
                                src={URL?.createObjectURL(image)}
                                // src={image}
                                // src={URL.createObjectURL(images)}
                                alt='lifestyle-media'
                              />
                              <Custombutton
                                type={'button'}
                                name={
                                  isPending ? 'Sharing...' : 'Share lifestyle'
                                }
                                className={'share-lifestyle-btn'}
                                disabled={isPending}
                                onClick={handleShareStatus}
                              />
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Box>
        </Modal>
      </div>

      {isTagsFrd && (
        <div className='modal-full-container'>
          <TagFriends
            handleCloseTagFrdClick={handleCloseTagFrdClick}
            onFriendCheck={handleFriendCheck}
          />
        </div>
      )}
    </>
  )
}

export default LifestyleStatus
