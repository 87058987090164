import React, { useState } from "react";
import { BsCoin } from "react-icons/bs";

const GiftPack = ({ item }) => {
    const [num, setNum] = useState(0)

    const handleIncrease = () => {
        if (num < 99) {
          setNum(num + 1);
        }
      };
    
      const handleDecrease = () => {
        if (num > 0) {
          setNum(num - 1);
        }
      };

  return (
    <div
      key={item?.id}
      className="border h-[90px] w-full grid grid-rows-8 rounded bg-blue-500"
    >
      <div className=" row-span-2 flex justify-end items-center px-2 gap-2">
        <div>
          <BsCoin className="text-yellow-500 text-[16px]" />
        </div>
        <div className="text-black">90</div>
      </div>
      <div className=" row-span-4">
        <img src={item?.img} alt="" className="h-[90%] object-cover mx-auto" />
      </div>
      <div className="row-span-2 grid grid-cols-3">
        <button className="bg-neutral-900 h-[80%] w-[80%] ml-2 text-white rounded" onClick={handleDecrease}>
          -
        </button>
        <div className="text-neutral-500 text-center text-[14px]">{num}</div>
        <button className="bg-neutral-900 text-white rounded h-[80%] w-[80%]" onClick={handleIncrease}>
          +
        </button>
      </div>
    </div>
  );
};

export default GiftPack;
