import React, { useEffect, useRef, useState } from "react";
import person from "../Assets/artist.jpeg";
import ModalWrapper2 from "../Components/Modals/ModalWrapper2";
import Edit from "../Assets/ic_round-edit.svg";
import { HiXMark } from "react-icons/hi2";
import { BiArrowBack, BiPlus } from "react-icons/bi";
import axios from "axios";
import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import Lottie from "lottie-react";
// import NothingHere from "../Assets/"
import Search from "../Assets/Vector.svg";
import preloader from "../../../pages/Home/Animation - 1703321875032 (1).json"
import SongListModalCard from "../Components/SongListModalCard";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';


export default function UploadMusicSider() {
  const [isOpen1, setIsOpen1] = useState(false);
  const [upload1, setUpload1] = useState(false)
  const [upload2, setUpload2] = useState(false)
  const [upload3, setUpload3] = useState(false)
  const [upload4, setUpload4] = useState(false)
  const [upload5, setUpload5] = useState(false)
  const [upload6, setUpload6] = useState(false)
  const [upload7, setUpload7] = useState(false)
  const [upload8, setUpload8] = useState(false)
  const [audio, setAudio] = useState()
  const [artist, setArtist] = useState()
  const [song, setSong] = useState()
  const [featuring, setFeaturing] = useState()
  const [producer, setProducer] = useState()
  const [uploadRadio, setUploadRadio] = useState()
  const authToken = localStorage.getItem("authToken")
  const fileInput = useRef(null)
  const [category, setCategory] = useState([])
  const [categoryId, setCategoryId] = useState()
  const [photo, setPhoto] = useState()
  const [albumName, setAlbumName] = useState()
  const [aboutAlbum, setAboutAlbum] = useState()
  const [albumImg, setAlbumImg] = useState()
  const [loading, setLoading] = useState(false)
  const [songs, setSongs] = useState([])
  const [selectedSongs, setSelectedSongs] = useState([]);
  const [albumId, setAlbumId] = useState()
  const [albumSongTitle, setAlbumSongTitle] = useState()
  const [albumSongImage, setAlbumSongImage] = useState()
  const [albumSongAudio, setAlbumSongAudio] = useState()
  const [artistData, setArtistData] = useState({})
  const [open, setOpen] = useState(false);
  const [songId, setSongId] = useState()

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const closeAllUploadModals = () => {
    setUpload1(false)
    setUpload2(false)
    setUpload3(false)
    setUpload4(false)
    setUpload5(false)
    setUpload6(false)
    setUpload7(false)
    setUpload8(false)
    setOpen(false)
    setAudio()
    setAboutAlbum()
    setSong()
    setAlbumImg()
    setAlbumSongAudio()
    setAlbumSongImage()
    // setAlbumSongs()
    // setCheckedSongs()
    setPhoto()
    setSongId()
  }

  const continueAlbumAudioUpload = () =>{
    setOpen(false)
    setAlbumSongTitle("")
    setAlbumSongAudio()
  }


  // Function to handle checkbox change
//   const handleCheckboxChange = (event) => {
//     const checkboxValue = event.target.value;
//     const songObject = songs.find((song) => song.title === checkboxValue);

//     if (event.target.checked) {
//         setSelectedSongs((prevSelectedSongs) => [...prevSelectedSongs, songObject]);
//     } else {
//         setSelectedSongs((prevSelectedSongs) =>
//             prevSelectedSongs.filter((song) => song.value !== checkboxValue)
//         );
//     }
// };

const handleCheckboxChange = (id) => {
  setSongId(id)
}

const UploadExistingSongToAlbum = async() => {
  const payload = {
    song_id: songId
  }
  await axios.post(`https://api.2geda.net/api/stereo/artist/albums/${albumId}/add-existing-song/`, payload, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": 'multipart/form-data',
      "X-CSRFToken": process.env.REACT_TOKEN,
    },
  }).then(res=>{
    setLoading(false)
      // setAlbumId(res.data?.data?.id)
      console.log(res)
      if (res.status === 200) {
        setLoading(false)
        toast.success(res.data.message)
        onOpenModal()
        // setUpload1(false)
        // setUpload2(false)
        // setUpload3(false)
        // setUpload4(false)
        // setUpload5(false)
      } else {
        toast.error("Something went wrong")
        setLoading(false)
      }
    }).catch(err=>{
      console.log(err)
      setLoading(false)
    })
}


// Handle file input change
const handleFileChange = (e) => {
  const file = e.target.files[0];
  setSelectedSongs((prevSelectedSongs) => [...prevSelectedSongs, file]);
};

// Remove a selected audio file
const removeAudioFile = (index) => {
  setSelectedSongs((prevSelectedFiles) =>
    prevSelectedFiles.filter((_, i) => i !== index)
  );
};


const GetArtistData = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/artists/profile/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setArtistData(res?.data?.data);
        console.log(artistData + "artistData state===&&&");
        console.log(JSON.stringify(res.data) + "artistData====");
      });
  };

  const GetCategories = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/categories/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setCategory(res?.data?.data);
        console.log(category + "category state===&&&");
        console.log(JSON.stringify(res.data) + "category====");
      });
  };


  const UploadSong = () => {
    setLoading(true)
    console.log(audio + categoryId +photo)
    const payload = {
      category_id: categoryId,
      cover_image: photo,
      audio_file: audio,
      title:song,
      // artist: artist
    }
    axios.post(`https://api.2geda.net/api/stereo/artist/songs/`, payload, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": 'multipart/form-data',
        "X-CSRFToken": process.env.REACT_TOKEN,
      },
    }).then(res=>{
      setLoading(true)
      if (res.status === 201) {
        toast.success(res.data.message)
        setLoading(false)
        setUpload1(false)
        setUpload2(false)
        setUpload3(false)
        setUpload4(false)
        setUpload5(false)
        window.location.reload()
      } else {
        toast.error("Something went wrong")
        setLoading(false)
      }
      console.log(res)
    })
  }

  const UploadAlbum = () => {
    setLoading(true)
    console.log(albumName + aboutAlbum + albumImg)
    const payload = {
      name: albumName,
      about: aboutAlbum,
      cover_image: albumImg,
      songs: selectedSongs
    }
    axios.post(`https://api.2geda.net/api/stereo/artist/albums/`, payload, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": 'multipart/form-data',
        "X-CSRFToken": process.env.REACT_TOKEN,
      },
    }).then(res=>{
      setLoading(false)
      setAlbumId(res.data?.data?.id)
      console.log(res)
      if (res.status === 201) {
        setLoading(false)
        toast.success(res.data.message)
        // setUpload1(false)
        // setUpload2(false)
        // setUpload3(false)
        // setUpload4(false)
        // setUpload5(false)
        setUpload3(!upload3)
        setUpload5(!upload5)
        window.location.reload()
      } else {
        toast.error("Something went wrong")
        setLoading(false)
      }
    }).catch(err=>{
      console.log(err)
      setLoading(false)
    })
  }

  const UploadAlbumSong = () => {
    setLoading(true)
    console.log(albumId, categoryId, albumImg, albumSongAudio)
    const payload = {
      category_id: categoryId,
      title: albumSongTitle,
      cover_image: albumImg,
      audio_file: albumSongAudio,
      // album_id: albumId
    }
    axios.post(`https://api.2geda.net/api/stereo/artist/albums/${albumId}/upload-song/`, payload, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": 'multipart/form-data',
        "X-CSRFToken": process.env.REACT_TOKEN,
      },
    }).then(res=>{
      setLoading(false)
      // setAlbumId(res.data?.data?.id)
      console.log(res)
      if (res.status === 200) {
        setLoading(false)
        toast.success(res.data.message)
        onOpenModal()
        // setUpload1(false)
        // setUpload2(false)
        // setUpload3(false)
        // setUpload4(false)
        // setUpload5(false)
      } else {
        toast.error("Something went wrong")
        setLoading(false)
      }
    }).catch(err=>{
      console.log(err)
      setLoading(false)
    })
  }

  const GetSongs = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/artist/songs/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        // Filter the items where plays < 3
        setSongs(res.data.data)
        // const filteredHits = res?.data?.data.filter((item) => item.plays > 0);
        // Assuming setBigHit is a function to update state
        // setBigHit(filteredHits);
        // console.log(filteredHits,"bighits==="); // This will log the filtered items
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };


  
  useEffect(()=>{
    GetCategories()
    GetSongs()
    GetArtistData()
  },[])
  return (

    <div className="bg-white lg:px-10 xl:px-10 md:px-5 pt-5 w-auto h-full mx-10">
      <div className="flex justify-between items-center lg:mt-5 xl:mt-5">
        <span className="font-medium text-2xl">About artist</span>
      </div>

      <div className="flex items-center gap-3 mt-7 mb-7">
        <img src={artistData.brand_image} className="w-[50px] h-[50px] rounded-full" />
        <div className="flex flex-col">
          <span className="font-normal text-xl">{artistData.artist_name}</span>
          {/* <button
            className="flex justify-between"
            onClick={() => setIsOpen1(!isOpen1)}>
            <span className="text-sm font-light text-[#4F0DA3] underline">
              Edit profile
            </span>
          </button> */}
        </div>
      </div>

      <button onClick={()=>setUpload1(!upload1)} className="bg-[#4F0DA3] flex text-white justify-between w-full px-2 py-3 rounded-lg mb-4 mt-4">
        <span className="font-normal text-base">New upload</span>
        <span className="font-normal text-base">+</span>
      </button>

      <section>
        <span className="font-medium text-2xl">Artist Overview</span>

        <section className="grid grid-cols-2 mt-4 gap-3">
          <main className="bg-[#F5F5F5] flex flex-col w-full h-full justify-center items-center py-8 rounded-md">
            <span className="text-2xl font-normal">{artistData.total_plays}</span>
            <span className="font-normal text-sm text-[#4F0DA3]">Plays</span>
          </main>
          <main className="bg-[#F5F5F5] flex flex-col w-full h-full justify-center items-center py-8 rounded-md">
            <span className="text-2xl font-normal">{artistData?.playlists?.length}</span>
            <span className="font-normal text-sm text-[#4F0DA3]">
              Playlist entries
            </span>
          </main>
          <main className="bg-[#F5F5F5] flex flex-col w-full h-full justify-center items-center py-8 rounded-md">
            <span className="text-2xl font-normal">{artistData.profile?.sticker}</span>
            <span className="font-normal text-sm text-[#4F0DA3]">Stickers</span>
          </main>
          <main className="bg-[#F5F5F5] flex flex-col w-full h-full justify-center items-center py-8 rounded-md">
            <span className="text-2xl font-normal">{artistData.total_downloads}</span>
            <span className="font-normal text-sm text-[#4F0DA3]">Downloads</span>
          </main>
        </section>
      </section>
      {/* modal */}
      <ModalWrapper2 header={true} isOpen={isOpen1} closeModal={() => setIsOpen1(!isOpen1)}>
        <div className="">
          <div className="flex flex-col justify-center items-center mx-10">
            <div className="relative my-10">
              <img
                src={require("../Assets/artist.jpeg")}
                className="w-[134px] h-[134px] rounded-full"
              />
              <button className="absolute top-44 left-40 w-[17.46px] h-[17.46px] rounded-full bg-[#FF8A15] flex justify-center items-center">
                <img className="w-[9.53px] h-[9.53px]" src={Edit} />
              </button>
            </div>
            <input
              type="text"
              placeholder="Artist name"
              className="w-full h-[48px] px-5 border-[#F5F5F5] border rounded-md my-4"
            />
            <textarea
              rows={6}
              placeholder="Bio"
              className="w-full px-5 py-2 border-[#F5F5F5] border rounded-md my-4"></textarea>

            <button className="bg-[#4F0DA3] px-24 py-2 text-white my-10 rounded-md">
              Save
            </button>
          </div>
        </div>
      </ModalWrapper2>
      {/* upload modal */}
      <ModalWrapper2 header={false} isOpen={upload1} closeModal={() => setUpload1(!upload1)}>
        <div className="flex items-center justify-between px-2 py-4">
          <span className="font-bold text-xl text-black">Upload</span>
          <button onClick={()=>{setUpload1(!upload1); closeAllUploadModals()}} className="w-[16] h-[16]"><HiXMark size={16}/></button>
        </div>
        <div className="flex flex-col px-2 mt-4 mb-2 pb-10 gap-2">
          <span className="text-base font-normal">Select a mode</span>
          <span className="text-sm font-light">Choose between uploading a single or an entire album, and let your creativity flow.</span>
          <div className="mt-3 flex justify-between items-center">
          <main className="flex flex-col gap-2">
            <span className="text-base font-normal">Single</span>
            <span className="text-base font-normal">Album/EP</span>
            {/* <input type="radio" name="upload" value={"Single"} className="flex items-end justify-end"/> */}
          </main>
          <main className="flex flex-col gap-2">
            <input type="radio" name="upload" value={"Single"} className="flex items-end justify-end" onChange={()=>{setUpload2(true); setUpload1(false); setUploadRadio("Single")}}/>
            <input type="radio" name="upload" value={"Album"} className="flex items-end justify-end" onChange={()=>{setUpload5(true); setUpload1(false); setUploadRadio("Album")}}/>
          </main>
        </div>
        </div>
        
      </ModalWrapper2>
      <ModalWrapper2 header={false} isOpen={upload2} closeModal={() => setUpload2(!upload2)}>
      <div className="flex items-center justify-between px-2 py-4">
        <div className="flex gap-2 items-center">
          <button className="h-[24] w-[24]" onClick={()=>{setUpload2(!upload2); setUpload1(!upload1)}}>
            <BiArrowBack size={24}/>
          </button>
          <span className="font-bold text-xl text-black">Upload</span>
          </div>
          <button onClick={()=>{setUpload1(!upload1); closeAllUploadModals()}} className="w-[16] h-[16]"><HiXMark size={16}/></button>
        </div>
        <div className="px-2 flex flex-col gap-2 mt-3">
          <span className="text-base font-normal">Select music file</span>
          <span className="text-base font-light">Choose a music file you want to upload. Supported file types include MP3, WAV, and M4A. </span>
        </div>
        <div>
        {audio? <div className="flex flex-col justify-center ml-5 mr-5"><div className="flex flex-col justify-center mt-5 mb-5">
        <span className="mb-3 text-lg font-medium">{audio?.name}</span>
        <audio controls>
            <source src={URL.createObjectURL(audio)} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio></div></div>:<div class="relative mx-2 my-4">
    <label title="Click to upload" for="button2" class="cursor-pointer flex flex-col items-center justify-center gap-4 px-6 py-4 before:border-gray-400/60 hover:before:border-gray-300 group before:bg-gray-100 before:absolute before:inset-0 before:rounded-3xl before:border before:border-dashed before:transition-transform before:duration-300 active:duration-75 active:before:scale-95">
      
      <div className="relative">
        <div className="flex flex-col justify-center items-center">
        <div className="rounded-full mb-4 bg-[#FF8A15] flex justify-center items-center w-[62px] h-[62px]">
          <span className="text-4xl font-light text-white">+</span>
        </div>
        <span className="font-medium text-sm">Tap here to select a file</span>
        </div>
      </div>
     </label>
    <input hidden="" type="file" ref={fileInput} name="button2" id="button2" onChange={(e)=>setAudio(e.target.files[0])}/>
</div>}
        </div>
        <div className="flex justify-center items-center pb-4">
        <button onClick={()=>{setUpload2(!upload2); setUpload4(!upload4)}} className="bg-[#4F0DA3] rounded-md py-2 w-1/2 text-white">Next</button>
        </div>
      </ModalWrapper2>

      <ModalWrapper2 header={false} isOpen={upload4} closeModal={() => setUpload4(!upload4)}>
      <div className="flex items-center justify-between px-2 py-4">
        <div className="flex gap-2 items-center">
          <button className="h-[24] w-[24]" onClick={()=>{setUpload4(!upload4); setUpload1(!upload1)}}>
            <BiArrowBack size={24}/>
          </button>
          <span className="font-bold text-xl text-black">Upload</span>
          </div>
          <button onClick={()=>{setUpload4(!upload4); closeAllUploadModals()}} className="w-[16] h-[16]"><HiXMark size={16}/></button>
        </div>
        <div className="flex flex-col items-center justify-center mt-8 gap-3">
          <span className="font-medium text-base">Your song has been uploaded!</span>
          <span className="font-medium text-sm text-gray-400">Follow these steps to complete your upload</span>
        </div>

        <div className="flex flex-col items-center justify-center px-10 mt-5">
          <div class="file-upload-content">
    <label>Profile Picture</label>
    <div class="form-group custom-drop-file text-center mb-3">
      <input type="file" class="form-control" id="img-upload" placeholder="Upload a picture" onChange={(e)=>setPhoto(e.target.files[0])}/>
      <main className="flex flex-col justify-center items-center">
      {photo?<img src={URL.createObjectURL(photo)}/>:<div className="rounded-full mb-4 bg-[#FF8A15] flex justify-center items-center w-[62px] h-[62px]">
          <span className="text-4xl font-light text-white">+</span>
        </div>}
        <span className="font-medium text-sm">Tap here to select a file</span>
        </main>
      {/* <p>Upload Picture</p> */}
    </div>
  </div>
        <input
        onChange={(e)=>setArtist(e.target.value)}
                  type="text"
                  placeholder="Artist Name"
                  className="max-w-[351px] px-3 mb-2 text-black rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]"
                />
                <input
                onChange={(e)=>setSong(e.target.value)}
                  type="text"
                  placeholder="Song Title"
                  className="max-w-[351px] px-3 mb-2 text-black rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]"
                />
                
                <select value={categoryId} onChange={(e)=>setCategoryId(e.target.value)} placeholder={"Category"} className="max-w-[351px] px-3 text-black mb-2 rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]">
                  {category.map(res=>{
                    return(
                    <option className="text-black" value={res.id}>{res.name}</option>
                    )
                  })}
                </select>
        </div>
        <div className="flex justify-center items-center pb-4 mt-5">
        {loading ?<Lottie
              animationData={preloader}
              style={{
                width: "300px",
                height: "100px",
              }}
            />:<button onClick={()=>UploadSong()} className="bg-[#4F0DA3] rounded-md py-2 w-1/2 text-white">Upload</button>}
        </div>
      </ModalWrapper2>

      {/* upload album */}

      <ModalWrapper2 header={false} isOpen={upload3} closeModal={() => setUpload3(!upload3)}>
      <div className="flex items-center justify-between px-2 py-4">
        <div className="flex gap-2 items-center">
          <button className="h-[24] w-[24]" onClick={()=>{setUpload3(!upload3); setUpload1(!upload1)}}>
            <BiArrowBack size={24}/>
          </button>
          <span className="font-bold text-xl text-black">Upload</span>
          </div>
          <button onClick={()=>{setUpload3(!upload3); setUpload1(!upload1); closeAllUploadModals()}} className="w-[16] h-[16]"><HiXMark size={16}/></button>
        </div>
        
        <div className="flex flex-col justify-center items-center text-center gap-4 mx-2">
          <span className="text-2xl font-medium">Before you upload.</span>
          <span className="text-xl font-normal text-[#403F3F]">Should this album include any music you’ve already uploaded?</span>
        </div>

        <div className="flex flex-col justify-center items-center my-5">
          <button onClick={()=>{setUpload6(!upload6); setUpload3(!upload3)}} className="font-normal rounded-md text-sm px-4 py-2 text-white bg-[#4F0DA3]">Yes, add songs</button>
          <button onClick={()=>{setUpload8(!upload8); setUpload3(!upload3)}} className="font-normal rounded-md text-sm px-4 py-2 text-black border mt-3 mb-3 border-[#4F0DA3]">No, start upload</button>
        </div>
    
        
      </ModalWrapper2>

      <ModalWrapper2 header={false} isOpen={upload6} closeModal={() => setUpload6(!upload6)}>
      <div className="flex items-center justify-between px-2 py-4">
        <div className="flex gap-2 items-center">
          <button className="h-[24] w-[24]" onClick={()=>{setUpload6(!upload6); setUpload3(!upload3)}}>
            <BiArrowBack size={24}/>
          </button>
          <span className="font-bold text-xl text-black">Add existing songs</span>
          </div>
          <button onClick={()=>{setUpload6(!upload6); setUpload3(!upload3); closeAllUploadModals()}} className="w-[16] h-[16]"><HiXMark size={16}/></button>
        </div>
        
        <div className="mx-3">
          <span className="text-sm font-normal text-[#403F3F]">Select your pre-existing songs to add to this album</span>
        </div>
        {/* {JSON.stringify(selectedSongs)}
        {selectedSongs.map(res=>{
          return <p>{res?.title}</p>
        })} */}

        <div className="relative mx-3 mt-3 mb-3">
        <input
          type="text"
          className="w-full bg-[#F5F5F5] py-2 rounded-3xl pl-10"
        />
        <img src={Search} className="absolute top-2 left-2" />
      </div>

      <div className="mx-3">
      {songs?.length>0?songs?.map(res=>{
                  return (
                  <SongListModalCard onCheckboxChange={()=>handleCheckboxChange(res.id)} title={res.title} img={res.cover_image} artist={res?.artist.artist_name} audio={res.audio_file?res.audio_file:null} value={res.title}/>
                  )
                }):<div className="flex justify-center items-center"><Lottie
                animationData={preloader}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
        {/* <SongListModalCard/> */}
      </div>
      <div className="flex justify-center items-center">
        <button onClick={()=>{UploadExistingSongToAlbum()}} className="text-sm font-normal bg-[#4F0DA3] text-[#F5F5F5] px-9 rounded-md py-3">Add track</button>
      </div>
        
      </ModalWrapper2>

      <ModalWrapper2 header={false} isOpen={upload7} closeModal={() => setUpload7(!upload7)}>
      <div className="flex items-center justify-between px-2 py-4">
        <div className="flex gap-2 items-center">
          <button className="h-[24] w-[24]" onClick={()=>{setUpload7(!upload7); setUpload3(!upload3)}}>
            <BiArrowBack size={24}/>
          </button>
          <span className="font-bold text-xl text-black">Upload</span>
          </div>
          <button onClick={()=>{setUpload3(!upload3); setUpload7(!upload7); closeAllUploadModals()}} className="w-[16] h-[16]"><HiXMark size={16}/></button>
        </div>
        <div className="flex flex-col items-start justify-start mt-8 gap-3 mx-3">
          <span className="font-medium text-base">Select music file</span>
          <span className="font-medium text-sm text-gray-400">Choose a music file you want to upload. Supported file types include MP3, WAV, and M4A. </span>
        </div>

        <div className="flex flex-col items-center justify-center px-10 mt-5">
          <div class="file-upload-content w-full">
    {/* <label>Profile Picture</label> */}
    <div class="form-group custom-drop-file text-center mb-3 w-full">
      <input type="file" class="form-control" id="img-upload" placeholder="Upload a picture" accept="audio/*" multiple onChange={handleFileChange}/>
      <main className="flex flex-col justify-center items-center">
        <div className="rounded-full mb-4 bg-[#FF8A15] flex justify-center items-center w-[62px] h-[62px]">
          <span className="text-4xl font-light text-white">+</span>
        </div>
        <span className="font-medium text-sm">Tap here to select a file</span>
        </main>
      {/* <p>Upload Picture</p> */}
    </div>
  </div>
  </div>
  {selectedSongs.map((file, index) => (
        <div key={index} className="flex justify-between items-center mx-3 my-4">
          <div>
          <h5 className="mb-2">{file.name}</h5>
          
          <audio controls>
            <source src={URL.createObjectURL(file)} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          </div>
          <button onClick={() => removeAudioFile(index)}>Remove</button>
        </div>
      ))}

      <div className="flex justify-center items-center">
        <button onClick={()=>{setUpload7(!upload7); setUpload5(!upload5)}} className="py-3 px-14 bg-[#4F0DA3] text-sm text-gray-400 font-normal">Upload</button>
      </div>
  </ModalWrapper2>

  <ModalWrapper2 header={false} isOpen={upload8} closeModal={() => setUpload8(!upload8)}>
      <div className="flex items-center justify-between px-2 py-4">
        <div className="flex gap-2 items-center">
          <button className="h-[24] w-[24]" onClick={()=>{setUpload8(!upload8); setUpload3(!upload3)}}>
            <BiArrowBack size={24}/>
          </button>
          <span className="font-bold text-xl text-black">Upload</span>
          </div>
          <button onClick={()=>{setUpload8(!upload8); setUpload3(!upload3); closeAllUploadModals()}} className="w-[16] h-[16]"><HiXMark size={16}/></button>
        </div>
        <div className="px-2 flex flex-col gap-2 mt-3">
          <span className="text-base font-normal">Select music file</span>
          <span className="text-base font-light">Choose a music file you want to upload. Supported file types include MP3, WAV, and M4A. </span>
        </div>
        <div>
        <div class="relative mx-3 my-4">
    <label title="Click to upload" for="button2" class="cursor-pointer flex flex-col items-center justify-center gap-4 px-6 py-4 before:border-gray-400/60 hover:before:border-gray-300 group before:bg-gray-100 before:absolute before:inset-0 before:rounded-3xl before:border before:border-dashed before:transition-transform before:duration-300 active:duration-75 active:before:scale-95">
      
      <div className="relative">
        <div className="flex flex-col justify-center items-center">
        {albumSongAudio?<audio controls>
            <source src={URL.createObjectURL(albumSongAudio)} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>:<div className="rounded-full mb-4 bg-[#FF8A15] flex justify-center items-center w-[62px] h-[62px]">
          <span className="text-4xl font-light text-white">+</span>
        </div>}
        <span className="font-medium text-sm">Tap here to select a file</span>
        </div>
      </div>
     </label>
    <input hidden="" type="file" ref={fileInput} name="button2" id="button2" onChange={(e)=>setAlbumSongAudio(e.target.files[0])}/>
{/* </div> */}
{/* </div> */}
        </div>
        {/* <input
        onChange={(e)=>setArtist(e.target.value)}
                  type="text"
                  placeholder="Artist Name"
                  className="max-w-[351px] px-3 mb-2 text-black rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]"
                /> */}
                <div className="w-auto">

                <input
                onChange={(e)=>setAlbumSongTitle(e.target.value)}
                  type="text"
                  placeholder="Song Title"
                  className="mx-3 mb-2 text-black rounded-lg py-3 border border-[rgba(40, 40, 40, 0.15)]"
                />
                
                <select value={categoryId} onChange={(e)=>setCategoryId(e.target.value)} placeholder={"Category"} className="mx-3 text-black mb-2 rounded-lg py-3 border border-[rgba(40, 40, 40, 0.15)]">
                  {category.map(res=>{
                    return(
                    <option className="text-black" value={res.id}>{res.name}</option>
                    )
                  })}
                </select>
                </div>
        </div>
        <div className="flex justify-center items-center pb-4 mt-5">
        {loading ?<Lottie
              animationData={preloader}
              style={{
                width: "300px",
                height: "100px",
              }}
            />:<button onClick={()=>UploadAlbumSong()} className="bg-[#4F0DA3] rounded-md py-2 w-1/2 text-white">Upload Song</button>}
        </div>
      </ModalWrapper2>

      <ModalWrapper2 header={false} isOpen={upload5} closeModal={() => setUpload5(!upload5)}>
      <div className="flex items-center justify-between px-2 py-4">
        <div className="flex gap-2 items-center">
          <button className="h-[24] w-[24]" onClick={()=>{setUpload5(!upload5); setUpload1(!upload1)}}>
            <BiArrowBack size={24}/>
          </button>
          <span className="font-bold text-xl text-black">Upload</span>
          </div>
          <button onClick={()=>{setUpload1(!upload1); setUpload5(!upload5); closeAllUploadModals()}} className="w-[16] h-[16]"><HiXMark size={16}/></button>
        </div>
        <div className="flex flex-col items-center justify-center mt-8 gap-3">
          <span className="font-medium text-base">Your song has been uploaded!</span>
          <span className="font-medium text-sm text-gray-400">Follow these steps to complete your upload</span>
        </div>

        <div className="flex flex-col items-center justify-center px-10 mt-5">
          <div class="file-upload-content">
    <label>Profile Picture</label>
    <div class="form-group custom-drop-file text-center mb-3">
      <input type="file" class="form-control" id="img-upload" placeholder="Upload a picture" onChange={(e)=>setAlbumImg(e.target.files[0])}/>
      <main className="flex flex-col justify-center items-center">
        {albumImg?<img src={URL.createObjectURL(albumImg)}/>:<div className="rounded-full mb-4 bg-[#FF8A15] flex justify-center items-center w-[62px] h-[62px]">
          <span className="text-4xl font-light text-white">+</span>
        </div>}
        <span className="font-medium text-sm">Tap here to select a file</span>
        </main>
      {/* <p>Upload Picture</p> */}
    </div>
  </div>
        <input
        onChange={(e)=>setAlbumName(e.target.value)}
                  type="text"
                  placeholder="Album Name"
                  className="max-w-[351px] px-3 mb-2 text-black rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]"
                />
                <input
                onChange={(e)=>setAboutAlbum(e.target.value)}
                  type="text"
                  placeholder="About Album"
                  className="max-w-[351px] px-3 mb-2 text-black rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]"
                />
                
                {/* <select value={categoryId} onChange={(e)=>setCategoryId(e.target.value)} placeholder={"Category"} className="max-w-[351px] px-3 text-black mb-2 rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]">
                  {category.map(res=>{
                    return(
                    <option className="text-black" value={res.id}>{res.name}</option>
                    )
                  })}
                </select> */}
        </div>
        <div className="flex justify-center items-center pb-4 mt-5">
        {loading ? <Lottie
              animationData={preloader}
              style={{
                width: "300px",
                height: "100px",
              }}
            />:<button onClick={()=>UploadAlbum()} className="bg-[#4F0DA3] rounded-md py-2 w-1/2 text-white">Upload</button>}
        </div>

        
      </ModalWrapper2>
      <Modal open={open} onClose={onCloseModal} center styles={{zIndex: 3000}}>
        <main className="flex flex-col justify-center items-center text-center mt-10 gap-3">
        <span className="text-2xl font-medium">Song added successfully</span>
        <span className="text-lg font-normal text-gray-600">Would you like to upload another song to your album?</span>
        </main>
        <main className="flex flex-row justify-center items-center gap-4 mt-4">
        <button className="bg-[#4F0DA3] py-3 px-10 text-gray-300 rounded-md" onClick={()=>continueAlbumAudioUpload()}>
        Yes
        </button>
        <button className="bg-red-500 py-3 px-10 text-gray-300 rounded-md" onClick={()=>closeAllUploadModals()}>No</button>
        </main>
      </Modal>
    </div>
  );
}
