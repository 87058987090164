import { AiOutlinePlus } from "react-icons/ai";
import IMELPhone from "components/Modals/IMELPhone";
import { useGetGadgets } from "Hooks/profile/useGetGadjets";
import NewEditProfileHeader from "components/PollsComp/ProfilleComp/NewEditProfileHeader";
import Spinner from "components/Spinner";
import { useNavigate } from "react-router-dom";
import { useProfile } from "Hooks/profile/useProfile";

const NewSerialNumber = () => {
  const { gadgetStatus, gadgets } = useGetGadgets();
  const {profileData} = useProfile()
  const username = profileData?.data?.data?.user?.username

  const navigate = useNavigate();

  const serialNo = gadgets?.data.filter((item) => item.category === "serial_number");

  return (
    <>
      <NewEditProfileHeader header="Gadget serial no" type />
      <div className="px-[15px] mb-0 py-[10px] lg:px-[10px] lg:mb-[1.5rem]">
        <div className="flex justify-between items-center">
          <div></div>
          <div
            className="w-[40px] h-[40px] flex items-center justify-center rounded bg-[#4f0da3] cursor-pointer"
            onClick={() => navigate(`/${username}/gadgets/phone-imei/new`)}
          >
            <AiOutlinePlus
              color="white"
              className="text-[18px] lg-text-[20px] "
            />
          </div>
        </div>
        <div className="flex flex-col gap-[15px] py-[20px]">
          {gadgetStatus === "pending" ? (
            <Spinner />
          ) : !serialNo?.length ? (
            <p className="text-[16px] text-center">
              You have no Gadget Serial no saved
            </p>
          ) : (
            serialNo?.map((gadget) => (
              <IMELPhone
                key={gadget?.id}
                name={gadget?.phone_name}
                id_number={gadget?.id_number}
                id={gadget?.id}
                type="serial"
                category={gadget?.category === "serial_number" ? "Serial no" : ""}
              />
            ))
          )}
        </div>

        {/* ADDITIONAL BUTTON */}
      </div>
    </>
  );
};

export default NewSerialNumber;
