import { useQuery } from "@tanstack/react-query";
import { getConversation, getMessages } from "api/services/chat";

export const useGetConversation = () => {
  const {
    isLoading: isConversationLoading,
    data: conversation,
    error: conversationError,
  } = useQuery({
    queryKey: ["conversation"],
    queryFn: getConversation,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    keepPreviousData: false,
  });

  return {
    isConversationLoading,
    conversation,
    conversationError,
  };
};
