import React, { useEffect, useState, useContext } from 'react'
import AudioPlayer2 from '../AudioPlayer2/AudioPlayer';
import { useNavigate } from 'react-router-dom';
import Lottie from "lottie-react";
import NothingHere from "../Assets/nothing_here.json"
import axios from 'axios';
import HitsCard from '../Components/HitsCard';
import { MusicPlayerContext } from '../context/MusicPlayerContext';

export default function BighitsSection() {
    const [bighit, setBigHit] = useState([])
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [loading, setLoading] = useState(false)
  const [activePlayer, setActivePlayer] = useState(null);
  const [songs, setSongs] = useState([])
  const [modalOpen1, setModalOpen1] = useState(false)
  const authToken = localStorage.getItem("authToken")
  const [minimized,setMinimized]=useState(false)
  const navigation = useNavigate()
  const [isplaying, setisplaying] = useState(false)
  const [clickedIndex2, setClickedIndex2] = useState(null); // Initialize with null
 const [currentSong2, setCurrentSong2] = useState(bighit[clickedIndex2])
 const { tracks, setTracks, index, setIndex, modalOpen, setModalOpen, minimized1, setMinimized1 } = useContext(MusicPlayerContext)

 const handleItemClick2 = (index, tracks) => {
    console.log('Clicked index:', index);
    // setCurrentSong(quickpicks[clickedIndex]);
    setIndex(index); // Set the clicked index to state
    setModalOpen(!modalOpen)
    setTracks(tracks)
  };

  const handlePlayPause = (id) => {
    if (id === currentlyPlaying) {
      setCurrentlyPlaying(null);
    } else {
      setCurrentlyPlaying(id);
    }
  };

  const GetBigHits = () => {
    setLoading(true)
    axios
      .get(`https://api.2geda.net/api/stereo/songs/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setLoading(false)
        // Filter the items where plays < 3
        setSongs(res.data.data)
        const filteredHits = res?.data?.data.filter((item) => item.plays > 0);
        // Assuming setBigHit is a function to update state
        setBigHit(filteredHits);
        console.log(filteredHits,"bighits==="); // This will log the filtered items
      })
      .catch((error) => {
        setLoading(false)
        console.error('Error fetching data:', error);
      });
  };

  useEffect(()=>{
    GetBigHits()
  },[])

  return (
    <div>
      <div
              className={bighit?.length>0?"flex mx-3 gap-3 overflow-x-scroll justify-between h-full":null}
                style={{
                  // marginLeft: 10,
                  // marginRight: 10,
                  // display: "flex",
                  // gap: 10,
                  // overflowX: "scroll",
                  scrollbarWidth: "none",
                  // justifyContent: "space-between",
                  height: "100%",
                }}>
                  {!loading?bighit?.length>0?bighit?.map((res, index)=>{
                  return (
                  <HitsCard onClick={()=>handleItemClick2(index, bighit)} setModalOpen1={setModalOpen1} modalOpen1={modalOpen1} name={res.title} img={res.cover_image?res.cover_image:null} artist={res.artist.artist_name} id={res.id} activePlayer={activePlayer} setActivePlayer={setActivePlayer} handlePlayPause={handlePlayPause}/>
                  )
                }):<div className="flex justify-center items-center">
                <span>Nothing here</span>
              </div>:<div className="flex justify-center items-center"><Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
                {/* <HitsCard />
                <HitsCard
                  img={require("../Assets/Image3.jpeg")}
                  name={"Life"}
                  artist={"Burna Boy"}
                />
                <HitsCard img={require("../Assets/Image4.jpeg")} />
                <HitsCard />
                <HitsCard
                  img={require("../Assets/Image3.jpeg")}
                  name={"Life"}
                  artist={"Burna Boy"}
                />
                <HitsCard img={require("../Assets/Image4.jpeg")} />
                <HitsCard /> */}
              </div>

              {/* {modalOpen1 && (
          <div
          id="modelConfirm"
          className={!minimized ? "fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4":""}>
          <div className={!minimized ? "relative top-40 mx-auto shadow-xl bg-white max-w-[100%] lg:max-w-[30%] md:max-w-[30%] xl:max-w-[30%] rounded-xl overflow-x-hidden": "fixed bottom-0 lg:left-[30%] lg:right-[30%] xl:left-[30%] xl:right-[30%] left-0 right-0 z-50 w-full lg:w-[50%] xl:w-[50%] h-60 bg-white border-t border-gray-200"}>
             <main className="py-10 my-10"> */}
                {/* <MusicPlayer playerIndex={clickedIndex} songs={quickpicks} setSongs={setQuickPicks} currentSong={currentSong} setCurrentSong={setCurrentSong} src={currentSong.url} ref={audioElem} onTimeUpdate={onPlaying}/> */}
                {/* <audio ref={audioElem} onTimeUpdate={onPlaying}>
                <source src={currentSong?.audio_file?`https://development.2geda.net${currentSong?.audio_file}`:""} type="audio/mp3" />
                </audio> */}
                {/* {modalOpen1 &&<AudioPlayer2 minimized1={minimized} setMinimized1={setMinimized} setModalOpen={setModalOpen1} modalOpen={modalOpen1} tracks={bighit} index={clickedIndex2}/>} */}
      {/* <Player songs={quickpicks} setSongs={setSongs} isplaying={isplaying} setisplaying={setisplaying} audioElem={audioElem} currentSong={currentSong} setCurrentSong={setCurrentSong} /> */}
              {/* </main>
            </div>
          </div>
        )} */}
    </div>
  )
}
