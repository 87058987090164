import { useEffect, useRef } from "react";
import { PiCopySimple } from "react-icons/pi";
import { useState } from "react";
import useGetEventDetails from "Hooks/useGetEventDetails";
import { useNavigate } from "react-router-dom";

const SuccessTicketCreate = () => {
  
  const {
    isEditing,
    newTicketID,
    handleRemoveNewTicketID,
  } = useGetEventDetails();
  
  const url = `https://2geda.net/ticket/event/${newTicketID}`
  const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();

  const pageRef = useRef(null);

  console.log(newTicketID)

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth", top: -2 });
    }
  };

  const handleEditClose = () => {
    handleRemoveNewTicketID()
    navigate("/ticket");
  };

  const handleCreateClose = () => {
    handleRemoveNewTicketID()
    navigate("/ticket");
  };

  useEffect(() => {
    handleGetRef();
  }, []);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(newTicketID);
      console.log("URL copied to clipboard:", newTicketID);
      setIsCopied(true);
      
      setTimeout(() => {
        setIsCopied(false);
      }, 2000); 
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div className="detail-bx-container bagw" style={{padding: "30px"}} ref={pageRef}>
      <div className="searchEmpty-bbox ddwn">
        <div className="empt-box-sea">
          <img src="images/em2.png" alt="" />
          <div className="notin-is">
            {isEditing
              ? "Your event has been edited"
              : "Your event has been created"}
          </div>
          <div className="full-notin">
            You can now share your unique ticket links to your attendees
          </div>

          <div className="copy-url-cont">
            <div className="url-txt">
              {url}
            </div>
            <PiCopySimple className="cp-icon" onClick={handleCopyClick} />
          </div>
          {isCopied && <div className="copied">Copied</div>}

          <button
            className="w-full mt-3 bg-[#4f0da3] text-[#fff] py-3 text-[15px] rounded"
            onClick={isEditing ? handleEditClose : handleCreateClose}
          >
            Back to tickets
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessTicketCreate;
