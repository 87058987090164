import React, { useEffect, useRef } from "react";
import { Outlet, useParams } from "react-router-dom";
import ProfileAds from "components/ProfileComponents/ProfileAds";
import ProfileStickersAndMessages from "components/ProfileComponents/ProfileStickersAndMessages";
import DashMessage from "components/Dashboard/DasMess";
import Follower from "components/Dashboard/Follower";
import useSetActiveFriends from "Hooks/chat/useSetActiveFriends";

const ChatLayout = () => {
  const pageRef = useRef(null);

  const {isActiveFriends} = useSetActiveFriends()

  console.log('active friends', isActiveFriends)


  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth", top: -8 });
    }
  };

  useEffect(() => {
    handleGetRef();
  }, []);

  return (
    <div className="lg:grid lg:grid-cols-12" ref={pageRef}>
      <div className="lg:col-span-7 bg-white lg:overflow-y-auto lg:max-h-screen">
        <Outlet />
      </div>

      <div className="lg:col-span-2 hidden lg:block bg-white mx-3 lg:h-screen">
        <ProfileAds />
      </div>
      <div className="lg:col-span-3 hidden lg:block lg:h-screen">
        <Follower />
        <div className="">
          {isActiveFriends ? <div>You have no friends, you will die alone lol</div>:<DashMessage />}
        </div>
      </div>
    </div>
  );
};

export default ChatLayout;
