import { createContext, useState } from "react";

const MusicPlayerContext = createContext()

const MusicPlayerProvider = ({children}) => {
    const [modalOpen, setModalOpen] = useState(false)
  const [index, setIndex] = useState(0); // Initialize with null
//  const [currentSong1, setCurrentSong1] = useState(Afrobeats[clickedIndex])
 const [minimized1, setMinimized1] = useState(false)
 const [activeModal, setActiveModal] = useState(null);
 const [tracks, setTracks] = useState()

 return (
    <MusicPlayerContext.Provider value={{ tracks, setTracks, index, setIndex, modalOpen, setModalOpen, minimized1, setMinimized1 }}>
        {children}
    </MusicPlayerContext.Provider>
 )
}

export {MusicPlayerContext, MusicPlayerProvider}