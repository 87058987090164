import { AiFillDelete } from 'react-icons/ai'
import './post-modal.css'
import { useEffect, useState } from 'react'

import { BsMicFill, BsSoundwave } from 'react-icons/bs'
import { IoMdMicOff } from 'react-icons/io'
import { useRef } from 'react'
const PostFormRecModal = ({ setAudioFile }) => {
  const [audioBlob, setAudioBlob] = useState(null)
  const [isRecording, setIsRecording] = useState(false) // Added recording state
  const mediaRecorder = useRef(null)

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      })
      mediaRecorder.current = new MediaRecorder(stream)

      const chunks = []

      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.push(e.data)
        }
      }

      mediaRecorder.current.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/wav' })
        setAudioBlob(blob)
        setIsRecording(false) // Update recording status when recording stops
        setAudioFile([blob])
      }

      mediaRecorder.current.start()
      setIsRecording(true) // Update recording status when recording starts
    } catch (error) {
      console.error('Error accessing microphone:', error)
    }
  }

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.stop()
    }
  }
  const waveContainerRef = useRef(null)

  const iconArray = new Array(20).fill(null)

  const handleDeleteItem = () => {
    setAudioBlob(null)
    setAudioFile(null)
  }

  const waveCount = 30 // Set the desired number of waves
  // Generate dynamic CSS for waves
  const generateWaveCSS = (count) => {
    let styles = ''
    const delayStep = 1.2 / count // Calculate delay step for each wave
    for (let i = 1; i <= count; i++) {
      styles += `
        .wave:nth-child(${i}) {
          animation-delay: -${delayStep * i}s;
        }
      `
    }
    return styles
  }

  useEffect(() => {
    const styleSheet = document.createElement('style')
    styleSheet.type = 'text/css'
    styleSheet.innerText = generateWaveCSS(waveCount)
    document.head.appendChild(styleSheet)
    return () => {
      document.head.removeChild(styleSheet)
    }
  }, [waveCount])

  return (
    <>
      <div className='post-audio-cont-box'>
        {audioBlob ? (
          <div className='audio-item'>
            <audio controls>
              <source src={URL.createObjectURL(audioBlob)} type='audio/wav' />
              Your browser does not support the audio tag.
            </audio>
            <div className='de-aud'>
              <div className='delete-audio' onClick={handleDeleteItem}>
                <AiFillDelete />
                <div className='del-tss'>Delete</div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className='!flex items-center justify-between'>
              {isRecording && (
                <div className='recording-indicator-container'>
                  <div className='recording-indicator'>
                    {[...Array(waveCount)].map((_, index) => (
                      <div key={index} className='wave'></div>
                    ))}
                  </div>
                </div>
              )}
              <button
                onClick={isRecording ? stopRecording : startRecording}
                // className={`rec-bbtn ${
                //   isRecording ? 'stop-record' : 'start-record'
                // } ${isRecording ? 'recording' : ''}`}
                className={`recbbtn h-[30px] m-[10px] !flex !items-center !space-x-2 p-4 rounded ${
                  isRecording ? 'bg-red-600' : 'bg-green-600'
                } text-white`}
              >
                {isRecording ? (
                  <>
                    Stop <IoMdMicOff />
                  </>
                ) : (
                  <>
                    Start <BsMicFill />
                  </>
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default PostFormRecModal
