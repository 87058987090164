import { useMutation, useQueryClient } from "@tanstack/react-query";
import { downloadMovieFn} from "api/services/tv";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const useDownloadMovie = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    status: downloadMovieStatus,
    mutate: downloadMovie,
    error: downloadMovieError
  } = useMutation({
    mutationFn: (formData) => downloadMovieFn(formData),

    onSuccess: () => {
        queryClient.invalidateQueries({
            queryKey: ["downloads"],
          });
    },
  });

  return {
    downloadMovie,
    downloadMovieStatus,
    downloadMovieError
  };
};

export default useDownloadMovie;
