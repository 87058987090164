import React from 'react'

const JobDescription = ({ onBack, job }) => {
  return (
    <div className='max-w-full mx-auto p-4 bg-white border border-gray-200 rounded-lg shadow-md'>
      <div className='flex justify-between py-2 border-bottom items-center'>
        <button
          onClick={onBack}
          className='text-gray-700 text-[22px] hover:text-gray-700'
        >
          ← Job description
        </button>
      </div>
      <div className='flex justify-between py-2 items-center'>
        <div className='flex items-center gap-2'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke-width='1.5'
            stroke='currentColor'
            className='h-6 w-6 text-black mr-2'
          >
            <path
              stroke-linecap='round'
              stroke-linejoin='round'
              d='M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z'
            />
          </svg>

          <div className=''>
            <h2 className='mt-2 text-2xl font-semibold text-gray-900'>
              {job?.company_name}
            </h2>
            <div className='text-sm text-gray-500'>{job?.company_name}</div>
          </div>
        </div>
        <div className='text-sm text-gray-500'>2 days ago</div>
      </div>

      <div className='mt-2 flex items-center text-gray-500'>
        <span className='text-[14px]'>{job?.location}</span>
      </div>
      <div className='mt-2 flex items-center text-gray-500'>
        <span className='text-[14px]'>
          ₦{job?.min_salary?.toLocaleString()} to ₦
          {job?.max_salary?.toLocaleString()}{' '}
          {job?.period === 1
            ? 'Weekly'
            : job?.period === 2
            ? 'Monthly'
            : 'Yearly'}
        </span>
      </div>
      <button className='mt-4 w-full px-4 py-4 text-[16px] bg-purple-700 text-white rounded hover:bg-purple-800'>
        Send a message
      </button>
      <h3 className='mt-4 text-[20px] font-semibold text-gray-900'>
        Job description
      </h3>
      <p className='mt-2 text-gray-700'>{job?.job_description}</p>
      {/* <h3 className='mt-4 text-lg font-semibold text-gray-900'>
        Key Responsibilities
      </h3>
      <ul className='list-disc pl-1 mt-2 text-gray-700'>
        <li>Provide administrative assistance.</li>
        <li>Maintain data and records accurately.</li>
        Add the rest of the responsibilities here
      </ul>
      <h3 className='mt-4 text-lg font-semibold text-gray-900'>
        Qualifications
      </h3>
      <ul className='list-disc pl-1 mt-2 text-gray-700'>
        <li>High school diploma or equivalent.</li>
        <li>Proficiency in Microsoft Office.</li>
        Add the rest of the qualifications here
      </ul> */}
      <h3 className='mt-4 text-lg font-semibold text-gray-900'>
        Salary and Benefits
      </h3>
      <p className='mt-2 text-gray-700'>
        Salary: ₦{job?.min_salary?.toLocaleString()} to ₦
        {job?.max_salary?.toLocaleString()}{' '}
        {job?.period === 1
          ? 'Weekly'
          : job?.period === 2
          ? 'Monthly'
          : 'Yearly'}
        .
      </p>
    </div>
  )
}

export default JobDescription
