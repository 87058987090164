import { useEffect, useRef, useState } from "react";
import TickectCardFull from "../../components/TicketComp/TickectCardFull";
import { BiSearch } from "react-icons/bi";
import { FaArrowLeftLong } from "react-icons/fa6";
import useGetTickets from "Hooks/useGetTickets";
import { useNavigate } from "react-router-dom";

const PromotedTicketsAll = () => {
  const pageRef = useRef(null);

  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth", top: -10 });
    }
  };

  const navigate = useNavigate();
  const {
    allEvents,
    allEventsRefetch,
  } = useGetTickets();
  const promotedEvents = allEvents?.data?.data?.promoted_events;

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEvents, setFilteredEvents] = useState(promotedEvents);

  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filtered = promotedEvents?.filter((event) =>
      event.title.toLowerCase().includes(term.toLowerCase())
    );

    setFilteredEvents(filtered);
  };

  const goToHome = () => {
    navigate("/ticket");
  };

  useEffect(() => {
    handleGetRef();
  }, []);

  useEffect(() => {
    setFilteredEvents(promotedEvents);
  }, [promotedEvents]);

  useEffect(() => {
    allEventsRefetch();
  }, [allEventsRefetch]);

  return (
    <div className="p-4 bg-white">
      <div className="flex w-full items-center gap-4 mt-4" ref={pageRef}>
        <FaArrowLeftLong onClick={goToHome} className="text-[18px]" />
        <h2 className="font-semibold text-[17px] mt-2">Promoted events</h2>
      </div>

      <div className="w-full border-none bg-neutral-100 rounded flex items-center px-4 mt-4 ">
        <BiSearch className="text-black text-[16px]" />
        <input
          type="text"
          className=" text-[14px] border-none outline-none "
          placeholder="Find event"
          value={searchTerm}
          onChange={handleInputChange}
        />
      </div>

      {filteredEvents?.length === 0 && searchTerm.length ? (
        <p className="text-[16px] mt-4">No events</p>
      ) : (
        <div className="ticket-full-box">
          {filteredEvents?.map((item, index) => (
            <TickectCardFull data={item} key={index} />
          ))}
        </div>
      )}
    </div>
  );
};

export default PromotedTicketsAll;
