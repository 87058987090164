import { useMutation, useQueryClient } from "@tanstack/react-query";
import { sendMessage } from "api/services/chat";
import { useNavigate } from "react-router-dom";

const useSendDirectMessage = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    status: sendDirectMessageStatus,
    mutate: sendDirectMessage,
    error: sendDirectMessageError,
  } = useMutation({
    mutationFn: (formData) => sendMessage(formData),

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["conversation"],
      });
    },
  });

  return {
    sendDirectMessage,
    sendDirectMessageStatus,
    sendDirectMessageError,
  };
};

export default useSendDirectMessage;
