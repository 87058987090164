import { useEffect, useRef, useState } from "react";
import TicketSearchComp from "../../components/TicketComp/TicketSearchComp";
import { useNavigate } from "react-router-dom";
import { BsBank2 } from "react-icons/bs";
import { withdrawalBanks } from "utils/helper";
import useGetTickets from "Hooks/useGetTickets";
import useTicketPayout from "Hooks/useTicketPayout";
import Spinner from "components/Spinner";
import VerifyConfirmationButton from "components/Modals/VerifyConfirmationButton";
import useProfileModal from "Hooks/profile/useProfileModal";

const Payout = () => {
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");

  const pageRef = useRef(null);

  const navigate = useNavigate();

  const { overview} = useGetTickets();

  const user = overview?.data?.message[0] ? overview?.data?.message[0] : [];

  const {withdrawalMutate, withdrawalStatus} = useTicketPayout()

  const {isSuccessModal} = useProfileModal()


  const handleGetRef = () => {
    if (pageRef && pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth", top: -10 });
    }
  };

  const goToPayoutHistory = () => {
    navigate("/ticket/sell-ticket/payout-history");
  };

  const handlePayoutClose = () => {
    navigate("/ticket/sell-ticket");
  };

  const handleWithdrawal = (e) => {
    e.preventDefault()
    if (!bankName) {
      setError("Bank name required");
    } else {
      setError("")
      const data = {
        amount: amount,
        account_name: accountName,
        bank_name: bankName,
        account_number: accountNumber,
      };
      
      withdrawalMutate(data)
    }
  };

  const isMore = user?.current_balance < amount

  useEffect(() => {
    handleGetRef();
  }, []);


  return (
    <div className="p-4">
      {/* <div className="modal-full-container">
        <RequestSucess />
      </div> */}

      {isSuccessModal && <VerifyConfirmationButton sub="Your withdrawal request has been received and is being processed" btnText="Back to dashboard" payout/>}

      <>
        <div ref={pageRef}>
          <TicketSearchComp
            label={"Payout"}
            handleCloseContainerClick={handlePayoutClose}
            add={"add"}
          />
        </div>

        <div className="payout-container">
          <div className="pay-top-box">
            <div className="avail-bal-cont flex">
              <div className="avail-bal">Available balance</div>
              <div className="view-roy" onClick={goToPayoutHistory}>
                View history
              </div>
            </div>
            <div className="avail-tol-bal">#{user?.current_balance?.toLocaleString() || '0.00'}</div>

            <div className="ifo-tvt">
              Amount will be displayed in your local currency
            </div>
            <div className="first-cir-pay"></div>
            <div className="sec-cir-pay"></div>
          </div>
          <form onSubmit={handleWithdrawal}>
            <div className="withdraw-info-box">
              <div className="heawid-line">Withdrawal information</div>
              <div className="double-input"></div>
              <div className="border h-[45px] rounded relative px-3 flex  mt-4">
                <label className="absolute -top-4 left-4 z-10 bg-white">
                  Account name
                </label>
                <div className="">
                  <input
                    type="text"
                    id="accountName"
                    value={accountName}
                    onChange={(e) => setAccountName(e.target.value)}
                    className="border-none outline-none text-[15px] absolute left-0 "
                    placeholder="Enter account name"
                    required
                  />
                </div>
                {/* <div className="flex justify-between items-center self-end w-full mt-4">
              {errors.ticketName ? (
                <p className="text-red-500 text-[11px]">{errors.ticketName}</p>
              ) : (
                <div> </div>
              )}
              <p className="text-[12px] text-neutral-400">0/50 words</p>
            </div> */}
              </div>
              {/* <div className="border h-[45px] rounded relative px-3 flex  mt-4">
                <label className="absolute -top-4 left-4 z-10 bg-white">
                  Bank name
                </label>
                <div className="">
                  <input
                    type="text"
                    id="bankName"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                    className="border-none outline-none text-[15px] absolute left-0 "
                    placeholder=""
                  />
                </div>
              </div> */}
              <div className="flex items-center cursor-pointer mt-4 py-1 px-4 text-neutral-400 border">
                <BsBank2 className="text-[20px] text-neutral-500 rounded" />
                <select
                  name=""
                  id="bankName"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                  className="border-none  bg-white cursor-pointer text-neutral-400 text-[14px] outline-none"
                >
                  <option value="">Bank</option>
                  {withdrawalBanks.map((bank, index) => (
                    <option key={index} value={bank}>
                      {bank}
                    </option>
                  ))}
                </select>
              </div>
              {error && (
                <p className="text-red-500 text-[12px]">{error}</p>
              )}

              <div className="border h-[45px] rounded relative px-3 flex  mt-4">
                <label className="absolute -top-4 left-4 z-10 bg-white">
                  Account number
                </label>
                <div className="">
                  <input
                    type="text"
                    id="accountNumber"
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                    className="border-none outline-none text-[15px] absolute left-0 "
                    placeholder="Enter account number"
                    required
                  />
                </div>
                {/* <div className="flex justify-between items-center self-end w-full mt-4">
              {errors.ticketName ? (
                <p className="text-red-500 text-[11px]">{errors.ticketName}</p>
              ) : (
                <div> </div>
              )}
              <p className="text-[12px] text-neutral-400">0/50 words</p>
            </div> */}
              </div>

              <div className="w-full my-3 bg-[#4f0da3] text-[#fff] py-3 text-[14px] flex justify-center items-center max-w-[300px] mx-auto">
                Add withdrawal info
              </div>
            </div>
            <div className="withdraw-info-box">
              <div className="heawid-line">Withdrawal request</div>
              <div className="border h-[48px] rounded relative px-3 flex  mt-4">
                <label className="absolute -top-4 left-4 z-10 bg-white">
                  Amount
                </label>
                <div className="">
                  <input
                    type="text"
                    id="amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="border-none outline-none text-[15px] absolute left-0 "
                    placeholder="Enter amount to withdraw"
                    required
                  />
                </div>
                {/* <div className="flex justify-between items-center self-end w-full mt-4">
              {errors.ticketName ? (
                <p className="text-red-500 text-[11px]">{errors.ticketName}</p>
              ) : (
                <div> </div>
              )}
              <p className="text-[12px] text-neutral-400">0/50 words</p>
            </div> */}
              </div>

              <button
                type="submit"
                className="w-full mt-6 bg-[#4f0da3] text-[#fff] py-3 text-[14px] rounded"
                disabled={isMore}
              >
                {withdrawalStatus === "pending" ? <Spinner /> : "Request"}
              </button>
            </div>
          </form>
        </div>
      </>
    </div>
  );
};

export default Payout;
