import React, { useState } from "react";
import Indicator from "../Assets/audioIndicator.svg"
import axios from "axios";


export default function ChartCard({ img, title, id, no, onClick, setModalOpen2, modalOpen2 }) {
  const authToken = localStorage.getItem("authToken")
  const [plays, setPlays] = useState([])

  const GetPlays = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/songs/${id}/play/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setPlays(res?.data?.data);
        console.log(plays + "plays state===");
        console.log(JSON.stringify(res.data) + "plays====");
      }).catch(e=>console.log(e));
  };
  return (
    <div onClick={()=>{onClick(); setModalOpen2(!modalOpen2); GetPlays()}} className="sm:max-w-[251.07px] sm:min-w-[251.07px] max-w-[168px] min-w-[168px] max-h-[282.62px]">
      <div className="relative w-full" style={{maxHeight: 148,
          minHeight: 148,}}>
      <img
        className="w-full"
        src={img}
        style={{
          //   maxWidth: 168,
          //   minWidth: 168,
          //   width: "168px",
          maxHeight: 148,
          minHeight: 148,
          //   borderRadius: 6,
          objectFit: "cover",
        }}
      />
      <img src={Indicator} className="w-[44px] h-[22px] absolute top-[50%] left-[40%]"/>
      </div>
      <div className="flex flex-col">
        <span className="font-medium text-xl">{title}</span><br/>
        <span className="font-normal text-lg text-[#403F3F]">{no? `${no} songs`: null}</span>
        <p></p>
      </div>
    </div>
  );
}
