import React from 'react'
import More from "../Assets/codicon_kebab-vertical.svg";

export default function SongListModalCard({title, artist, img, value, onCheckboxChange}) {
  return (
    <div className="bg-[#F5F5F5] py-3 px-3 rounded-md w-full mb-4">
      <section className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <img
            src={img?img:require("../Assets/Image2.jpeg")}
            className="w-[35px] h-[35px] rounded-lg"
          />
          <div className="flex flex-col">
            <p className="font-normal text-sm">{title? title:"Unknown Song"}</p>
            <p className="text-sm font-light text-[#403F3F]">{artist?artist:"Unknown Artist"}</p>
          </div>
        </div>
        <div className="flex items-end justify-end">
        <input type="radio" name="upload" value={value} className="flex items-end justify-end rounded-full" onChange={onCheckboxChange} />
        </div>
      </section>
    </div>
  )
}
