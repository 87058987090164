import { useQuery } from "@tanstack/react-query";
import { getPublicConversation } from "api/services/chat";

export const usePublicMessages = () => {
  const {
    isLoading: isPublicMessagesLoading,
    data: publicMessages,
    error: publicMessagesError,
  } = useQuery({
    queryKey: ["publicmessages"],
    queryFn: getPublicConversation,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    keepPreviousData: false,
  });

  return {
    isPublicMessagesLoading,
    publicMessages,
    publicMessagesError,
  };
};
