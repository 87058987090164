import "./second.css";
import EachReportCol from "./EachReportCol";
import useGetTickets from "Hooks/useGetTickets";
//import TicketReport from "pages/Ticket/TicketReport";

const TicketActualReport = ({id}) => {
  const { ticketReportData } = useGetTickets();

  const allTicketReports = ticketReportData?.data;
  
  const ticketReport = allTicketReports?.filter(
    // eslint-disable-next-line eqeqeq
    (item) => item.event?.id?.trim() == id
  );

  console.log('all ticket reports', allTicketReports)

  return (
    <div className="tick-actual-container">
      <div className="down-csv-cont flex">
        {ticketReport?.length ? <div className="event-rep">Event report</div> : null}
        <div className="act-btn-cont flex ">
          {/* <button className="p-3 text-[15px] w-fit flex items-center gap-3 bg-[#4f0da3] text-[#fff] justify-center rounded">
            <AiOutlineDownload className="mb-1 text-[17px]" />
            Download .CSV
          </button> */}
        </div>
      </div>
      <div className="all-rep-box">
        {!ticketReport?.length ? (
          <h4>No purchases have been made on this ticket</h4>
        ) : (
          ticketReport?.reverse().map((item) => (
            <EachReportCol
              key={item.id}
              firstName={item.first_name}
              lastName={item.last_name}
              date={item.date}
              baseID={item.id}
              amount={item?.amount}
              quantity={item?.regular_ticket + item?.vip_ticket}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default TicketActualReport;
