import React from 'react'
import { useFetchJobs } from 'api/services/feeds'
import EmptyImage from '../../../../assets/2gede_nothing_here.svg'

const FeedsJob = ({ onViewDescription }) => {
  const { data: getJobs, isPending } = useFetchJobs()

  if (isPending) {
    return (
      <div className='feed-images-container'>
        <div className='feed-images-loader'>
          <div className='feed-images-loader-inner'>
            <div className='feed-images-loader-text'>Loading...</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='pb-8 space-y-4 !w-full'>
      {getJobs?.length === 0 && (
        <div className='text-3xl mx-auto'>
          <h1>No job at the moment, please come back later</h1>
          <img src={EmptyImage} alt='nothing here' />
        </div>
      )}
      {getJobs?.map((item) => (
        <div className='max-w-full mx-auto bg-white border border-gray-200 rounded-lg shadow-md overflow-hidden'>
          <div className='p-4 '>
            <div className='flex justify-between border-bottom py-2 items-center'>
              <div className='flex items-center gap-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke-width='1.5'
                  stroke='currentColor'
                  className='h-6 w-6 text-purple-700 mr-2'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    d='M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z'
                  />
                </svg>

                <div className=''>
                  <div className='text-sm text-gray-500'>
                    {item?.company_name}
                  </div>
                  <h2 className='mt-2 text-md font-semibold text-gray-900'>
                    {item?.job_title}
                  </h2>
                </div>
              </div>
              <div className='text-sm text-gray-500'>30 Minutes ago</div>
            </div>

            <div className='mt-2 flex items-center text-gray-500'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke-width='1.5'
                stroke='currentColor'
                className='h-6 w-6 text-blue-500 mr-2'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  d='M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z'
                />
              </svg>

              <span className='text-[14px]'>
                NGN {item?.min_salary?.toLocaleString()} to{' '}
                {item?.max_salary?.toLocaleString()}
              </span>
            </div>
            <div className='mt-2 flex items-center text-gray-500'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke-width='1.5'
                stroke='currentColor'
                className='h-6 w-6 text-red-500 mr-2'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  d='M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'
                />
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  d='M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z'
                />
              </svg>

              <span className='text-[14px]'>{item?.location}</span>
            </div>
            <div className='mt-2 flex items-center text-gray-500'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke-width='1.5'
                stroke='currentColor'
                className='h-6 w-6 text-green-500 mr-2'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  d='M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z'
                />
              </svg>

              <span className='text-[14px]'>0 Applicants</span>
            </div>
            <div className='mt-4 grid grid-cols-2 gap-4'>
              <button
                className='px-3 py-3 text-3xl bg-white border !text-purple-700 rounded hover:!text-white hover:!bg-purple-800'
                onClick={() => onViewDescription(item)}
              >
                View Description
              </button>
              <button className='px-3 py-3 text-3xl bg-purple-700 text-white rounded hover:bg-purple-800'>
                Apply Now
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default FeedsJob
