import { useRef, useState, useEffect } from 'react'
import { useCreateComment } from 'api/hooks/feeds'
import { BsSoundwave } from 'react-icons/bs'
import { MdSend } from 'react-icons/md'
import { AiFillDelete } from 'react-icons/ai'
import './feed-detail-modal.css'
import Custombutton from 'components/Custom-button/Custombutton'

const RecInputField = ({ postId, onStop, onClose }) => {
  const { comment, isLoading } = useCreateComment({
    postId,
    onSuccess: () => {
      onClose()
      setCommentText('')
      setAudioFile(null)
      setAudioDuration(null)
    },
    onError: (errorResponse) => {
      console.log({ errorResponse })
    },
  })
  const [commentText, setCommentText] = useState('')
  const [audioDuration, setAudioDuration] = useState(null)

  const indicatorRef = useRef(null)
  const mediaRecorder = useRef(null)
  const [audioBlob, setAudioBlob] = useState(null)
  const [audioFile, setAudioFile] = useState(null)
  const audioRef = useRef(null)

  const [isRecording, setIsRecording] = useState(false)

  const estimateDuration = (blob) => {
    const headerSize = 44 // Standard WAV header size
    const sampleRate = 44100 // Sample rate in Hz
    const bitDepth = 16 // Bit depth in bits
    const channels = 2 // Number of channels (stereo)

    const bitrate = sampleRate * (bitDepth / 8) * channels // Bitrate in bytes/second
    const audioDataSize = blob.size - headerSize // Audio data size in bytes
    const duration = audioDataSize / bitrate // Duration in seconds

    return duration
  }

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      })
      mediaRecorder.current = new MediaRecorder(stream)

      const chunks = []

      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.push(e.data)
        }
      }

      mediaRecorder.current.onstop = async () => {
        const blob = new Blob(chunks, { type: 'audio/wav' })
        setAudioBlob(blob)
        setIsRecording(false) // Update recording status when recording stops
        // setAudioFile([blob])
        setAudioFile(new File([blob], 'recording.wav', { type: 'audio/wav' }))

        // Create a temporary audio element to load the blob and get its duration
        const duration = estimateDuration(blob)
        setAudioDuration(duration)
        console.log(duration, 'Estimated audio duration')
      }
      console.log(audioDuration, 'Audio duration')
      mediaRecorder.current.start()
      setIsRecording(true) // Update recording status when recording starts
    } catch (error) {
      console.error('Error accessing microphone:', error)
    }
  }

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.stop()
    }
  }
  const handleDeleteItem = () => {
    setAudioBlob(null)
    setAudioFile(null)
    setAudioDuration(null)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (indicatorRef.current) {
        indicatorRef.current.scrollLeft += 1 // Adjust the speed by changing the value
      }
    }, 50) // Adjust the interval to change the speed of scrolling

    return () => clearInterval(interval)
  }, [])

  const handlePlay = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0
    }
  }

  const handleComment = () => {
    const commentData = new FormData()
    commentData.append('text_content', commentText)
    if (audioFile) {
      commentData.append('file', audioFile)
    }
    comment(commentData)
  }

  const waveCount = 30 // Set the desired number of waves
  // Generate dynamic CSS for waves
  const generateWaveCSS = (count) => {
    let styles = ''
    const delayStep = 1.2 / count // Calculate delay step for each wave
    for (let i = 1; i <= count; i++) {
      styles += `
        .wave:nth-child(${i}) {
          animation-delay: -${delayStep * i}s;
        }
      `
    }
    return styles
  }

  useEffect(() => {
    const styleSheet = document.createElement('style')
    styleSheet.type = 'text/css'
    styleSheet.innerText = generateWaveCSS(waveCount)
    document.head.appendChild(styleSheet)
    return () => {
      document.head.removeChild(styleSheet)
    }
  }, [waveCount])

  return (
    <>
      <div className='pic-cont-box'>
        <div className='np-pic-bx'>
          {!audioBlob && !isRecording && (
            <div className='not-wrt'>Click start to record</div>
          )}
          {audioBlob ? (
            <div className='audio-item'>
              <div className='custom-audio-player'>
                <audio
                  className='aud-st'
                  controls
                  onPlay={handlePlay}
                  ref={audioRef}
                >
                  <source
                    src={URL.createObjectURL(audioBlob)}
                    type='audio/wav'
                  />
                  Your browser does not support the audio tag.
                </audio>
                {/* <div className='audio-duration'>
                  {audioDuration !== null ? `${audioDuration.toFixed(2)}s` : ''}
                </div> */}
              </div>
            </div>
          ) : (
            <>
              {isRecording && (
                <div className='recording-indicator-container'>
                  <div className='recording-indicator'>
                    {[...Array(waveCount)].map((_, index) => (
                      <div key={index} className='wave'></div>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <hr className='ln-hr' />
        <div className='inp-sen-send jus'>
          {audioBlob ? (
            <AiFillDelete className='cur' onClick={handleDeleteItem} />
          ) : (
            <button
              onClick={isRecording ? stopRecording : startRecording}
              // className={`${
              //   isRecording ? 'stop-recordcom record' : 'start-recordcom record'
              // } ${isRecording ? 'recording' : ''}`}
              className={` py-2 !flex !items-center !space-x-2 p-4 rounded ${
                isRecording ? 'bg-red-600' : 'bg-green-600'
              } text-white`}
            >
              {isRecording ? 'Stop' : 'Start'}
            </button>
          )}
          <div className='inp-sen-send'>
            <input
              type='text'
              className='pic-inpt'
              placeholder='Start typing'
              name='text_content'
              onChange={(e) => {
                setCommentText(e.target.value)
              }}
            />
            <Custombutton
              className='com-icon-btn'
              type='submit'
              onClick={handleComment}
              name={
                isLoading ? (
                  ''
                ) : (
                  <MdSend className='cur' size={22} color='#4f0da3' />
                )
              }
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default RecInputField
