import React from "react";
import girl from "../../assets/live_images/light-girl.jpg";

const LiveProfiles = () => {
  return (
    <div className="mt-2 flex items-center gap-4">
      <div className="lg:h-20 lg:w-20 w-16 h-16 bg-red-500 rounded-full relative z-10">
        <div className="rounded-full bg-blue-50 absolute top-1 lg:w-20 lg:h-20 h-16 w-16 -z-10">
          <img
            src={girl}
            alt=""
            className="w-full h-full object-cover rounded-full"
          />
        </div>
        <div className="absolute bg-red-500 py-1 lg:block hidden px-3 top-16 align ml-[6px] rounded-md text-white">
          Live
        </div>
      </div>
      <div className="lg:h-20 lg:w-20 w-16 h-16 bg-red-500 rounded-full relative z-10">
        <div className="rounded-full bg-blue-50 absolute top-1 lg:w-20 lg:h-20 h-16 w-16 -z-10">
          <img
            src={girl}
            alt=""
            className="w-full h-full object-cover rounded-full"
          />
        </div>
        <div className="absolute bg-red-500 py-1 lg:block hidden px-3 top-16 align ml-[6px] rounded-md text-white">
          Live
        </div>
      </div>
      <div className="lg:h-20 lg:w-20 w-16 h-16 bg-red-500 rounded-full relative z-10">
        <div className="rounded-full bg-blue-50 absolute top-1 lg:w-20 lg:h-20 h-16 w-16 -z-10">
          <img
            src={girl}
            alt=""
            className="w-full h-full object-cover rounded-full"
          />
        </div>
        <div className="absolute bg-red-500 py-1 lg:block hidden px-3 top-16 align ml-[6px] rounded-md text-white">
          Live
        </div>
      </div>
      <div className="lg:h-20 lg:w-20 w-16 h-16 bg-red-500 rounded-full relative z-10">
        <div className="rounded-full bg-blue-50 absolute top-1 lg:w-20 lg:h-20 h-16 w-16 -z-10">
          <img
            src={girl}
            alt=""
            className="w-full h-full object-cover rounded-full"
          />
        </div>
        <div className="absolute bg-red-500 py-1 lg:block hidden px-3 top-16 align ml-[6px] rounded-md text-white">
          Live
        </div>
      </div>
      <div className="lg:h-20 lg:w-20 w-16 h-16 bg-red-500 rounded-full relative z-10">
        <div className="rounded-full bg-blue-50 absolute top-1 lg:w-20 lg:h-20 h-16 w-16 -z-10">
          <img
            src={girl}
            alt=""
            className="w-full h-full object-cover rounded-full"
          />
        </div>
        <div className="absolute bg-red-500 py-1 lg:block hidden px-3 top-16 align ml-[6px] rounded-md text-white">
          Live
        </div>
      </div>
      <div className="lg:h-20 lg:w-20 w-16 h-16 bg-red-500 rounded-full relative z-10">
        <div className="rounded-full bg-blue-50 absolute top-1 lg:w-20 lg:h-20 h-16 w-16 -z-10">
          <img
            src={girl}
            alt=""
            className="w-full h-full object-cover rounded-full"
          />
        </div>
        <div className="absolute bg-red-500 py-1 lg:block hidden px-3 top-16 align ml-[6px] rounded-md text-white">
          Live
        </div>
      </div>
      
            
    </div>
  );
};

export default LiveProfiles;
