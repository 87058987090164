import React, { useContext, useEffect, useState } from 'react'
import LayoutMain from '../Layout/LayoutMain'
import Arrow from "../Assets/arrowback.svg";
import Search from "../Assets/majesticons_search-line.svg";
import Header1 from '../Components/Header1';
import axios from 'axios';
import Lottie from "lottie-react";
import NothingHere from "../Assets/nothing_here.json"
import MoreCard from '../Components/MoreCard';
import ArtistTabCard from '../Components/ArtistTabCard';
import { useNavigate } from 'react-router-dom';
import { MusicPlayerContext } from '../context/MusicPlayerContext';

export default function StereoSearchPage() {
  const [active, setActive] = useState(1)
  const [songs, setSongs] = useState([])
  const [artist, setArtist] = useState([])
  const [albums, setAlbums] = useState([])
  const authToken = localStorage.getItem("authToken")
  const [searchItem, setSearchItem] = useState('')
  const [filteredUsers, setFilteredUsers] = useState([])
  const navigate = useNavigate()
  const { tracks, setTracks, index, setIndex, modalOpen, setModalOpen, minimized1, setMinimized1 } = useContext(MusicPlayerContext)


  const handleItemClick3 = (index, tracks) => {
    console.log('Clicked index:', index);
    // setCurrentSong(quickpicks[clickedIndex]);
    setIndex(index); // Set the clicked index to state
    setModalOpen(!modalOpen)
    setTracks(tracks)
  };


  const GetAllSongs = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/songs/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        // Filter the items where plays < 3
        // const filteredHits = res?.data?.data.filter((item) => item.plays > 0);
        // Assuming setBigHit is a function to update state
        setSongs(res.data.data);
        console.log(res.data.data,"bighits==="); // This will log the filtered items
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const GetAlbums = async() => {
    await axios
      .get(`https://api.2geda.net/api/stereo/albums/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setAlbums(res?.data.data);
        console.log(albums + "recent upload state===");
        console.log(JSON.stringify(res.data) + "recentUpload====");
      }).catch(e=>console.log(e));
  };

  const GetArtists = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/artists/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setArtist(res.data.data);
        console.log(JSON.stringify(artist) + "artist state===");
        console.log(JSON.stringify(res.data.data) + "artists====");
      }).catch(e=>console.log(e));
  };

  const handleInputChange = (e) => { 
    const searchTerm = (e.target.value ?? '').toLowerCase(); // Convert search term to lowercase
    setSearchItem(searchTerm);
  
    let filteredItems = [];
  
    if (active === 1) { // All
      filteredItems = songs.filter((song) =>
        song?.title?.toLowerCase().includes(searchTerm)
      ).concat(
        artist.filter((artists) =>
          artists?.artist_name?.toLowerCase().includes(searchTerm)
        ),
        albums.filter((album) =>
          album?.name?.toLowerCase().includes(searchTerm)
        )
      );
    } else if (active === 2) { // Songs
      filteredItems = songs.filter((song) =>
        song?.title?.toLowerCase().includes(searchTerm)
      );
    } else if (active === 3) { // Artists
      filteredItems = artist.filter((artists) =>
        artists?.artist_name?.toLowerCase().includes(searchTerm)
      );
    }
  
    setFilteredUsers(filteredItems);
    console.log(filteredUsers, "filtered====")
    console.log(songs, "song=====")
    console.log(searchTerm, "searchTerm=====")
  };
  


  const handleActiveTab = (id) => {
    setActive(id)
  }

  useEffect(()=>{
    GetAllSongs()
    GetAlbums()
    GetArtists()
  },[])
  return (
    <LayoutMain>
        <div>
          <Header1/>
        <section
              id="iconwrapper"
              className="flex items-center mx-2 space-x-3">
              <button style={{ width: 24, height: 24 }} onClick={()=>navigate("/stereo_home")}>
                <img src={Arrow} />
              </button>
              <span style={{ fontSize: 20, fontWeight: "700" }} fontSize={"20px"}>
                Search
              </span>
            </section>
            <div className="relative mt-3 mx-3">
        <input
          type="text"
          className="w-full bg-[#F5F5F5] py-2 rounded-3xl pl-10"
          value={searchItem}
        onChange={handleInputChange}
        />
        <img src={Search} className="absolute top-2 left-2" />
      </div>

      <main className="flex items-center space-x-8 my-4 mx-2">
        <button onClick={()=>{handleActiveTab(1)}} className={active===1?'bg-[#4F0DA3] rounded-3xl px-8 py-2': 'bg-transparent px-8 py-2'}>
          <span className={active===1?"text-white text-xl":"text-xl text-[#403F3F]"}>All</span>
        </button>
        <button onClick={()=>{handleActiveTab(2)}} className={active===2?'bg-[#4F0DA3] rounded-3xl px-8 py-2': 'bg-transparent px-8 py-2'}>
          <span className={active===2?"text-white text-xl":"text-xl text-[#403F3F]"}>Songs</span>
        </button>
        <button onClick={()=>{handleActiveTab(3)}} className={active===3?'bg-[#4F0DA3] rounded-3xl px-8 py-2': 'bg-transparent px-8 py-2'}>
          <span className={active===3?"text-white text-xl":"text-xl text-[#403F3F]"}>Artists</span>
        </button>
      </main>
      <main className='mx-2'>
      {active === 1 && (
  <div className="flex flex-col gap-4">
    {filteredUsers.length > 0 ? (
      // console.log(filteredUsers, "filtered====")
      filteredUsers.map((item) => (
      <MoreCard
          title={item.title || item.name}
          img={item.cover_image}
          artist={item.artist?.artist_name}
          audio={item.audio_file}
          id={item.id}
        />
      ))
    ) : (
      <div className="flex justify-center items-center">
        <Lottie
          animationData={NothingHere}
          style={{
            width: "263.38px",
            height: "100%",
          }}
        />
      </div>
    )}
  </div>
)}
        {active === 2 && (
  <div className="flex flex-col gap-4">
    {filteredUsers.length > 0 ? (
      filteredUsers.map((item) => (
        <MoreCard
          title={item.title || item.name}
          img={item.cover_image}
          artist={item.artist?.artist_name}
          audio={item.audio_file}
          id={item.id}
          key={item.id} // Add a unique key prop for React rendering
        />
      ))
    ) : (
      <div className="flex justify-center items-center">
        <Lottie
          animationData={NothingHere}
          style={{
            width: "263.38px",
            height: "100%",
          }}
        />
      </div>
    )}
  </div>
)}

{active === 3 && (
  <div className="flex flex-col gap-4">
    {filteredUsers.length > 0 ? (
      filteredUsers.map((item) => (
        <ArtistTabCard
          name={item.artist_name}
          image={item.brand_image || null}
          artist={item.artist_name}
          audio={item.audio_file}
          id={item.id}
          key={item.id} // Add a unique key prop for React rendering
        />
      ))
    ) : (
      <div className="flex justify-center items-center">
        <Lottie
          animationData={NothingHere}
          style={{
            width: "263.38px",
            height: "100%",
          }}
        />
      </div>
    )}
  </div>
)}
      </main>
        </div>
    </LayoutMain>
  )
}
