import React, { useEffect, useState, useRef, useContext } from "react";
import UploadMusicLayout from "../../Layout/UploadMusicLayout";
import Arrow from "../../Assets/arrowback.svg";
import Search from "../../Assets/majesticons_search-line.svg";
import RecentUploadCard from "../../Components/recentUploadCard";
import Ad from "../../Assets/AD.jpeg";
import UploadSongHeader from "../../Components/UploadSongHeader";
import axios from "axios";
import Lottie from "lottie-react";
import NothingHere from "../../Assets/nothing_here.json"
import UploadLayout2 from "pages/Stereo/Layout/UploadLayout2";
import person from "../../Assets/artist.jpeg";
import ModalWrapper2 from "../../Components/Modals/ModalWrapper2";
import Edit from "../../Assets/ic_round-edit.svg";
import { HiXMark } from "react-icons/hi2";
import { BiArrowBack, BiPlus } from "react-icons/bi";
// import axios from "axios";
import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
// import Lottie from "lottie-react";
import preloader from "../../../../pages/Home/Animation - 1703321875032 (1).json"
// import Search from "../../Assets/Vector.svg"
import SongListModalCard from "pages/Stereo/Components/SongListModalCard";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useNavigate } from "react-router-dom";
import AudioPlayer2 from "pages/Stereo/AudioPlayer2/AudioPlayer";
import { MusicPlayerContext } from "pages/Stereo/context/MusicPlayerContext";

export default function RecentUploads() {
  const [recentUpload, setRecentUpload] = useState([])
  const authToken = localStorage.getItem("authToken")
  const navigation = useNavigate()

  // const [recentUpload, setRecentUpload] = useState([])
  // const authToken = localStorage.getItem("authToken")
  const [isOpen1, setIsOpen1] = useState(false);
  const [upload1, setUpload1] = useState(false)
  const [upload2, setUpload2] = useState(false)
  const [upload3, setUpload3] = useState(false)
  const [upload6, setUpload6] = useState(false)
  const [upload7, setUpload7] = useState(false)
  const [upload4, setUpload4] = useState(false)
  const [upload5, setUpload5] = useState(false)
  const [upload8, setUpload8] = useState(false)
  const [audio, setAudio] = useState()
  const [artist, setArtist] = useState()
  const [song, setSong] = useState()
  const [featuring, setFeaturing] = useState()
  const [producer, setProducer] = useState()
  const [uploadRadio, setUploadRadio] = useState()
  // const authToken = localStorage.getItem("authToken")
  const fileInput = useRef(null)
  const [category, setCategory] = useState([])
  const [categoryId, setCategoryId] = useState()
  const [photo, setPhoto] = useState()
  const [albumName, setAlbumName] = useState()
  const [aboutAlbum, setAboutAlbum] = useState()
  const [albumImg, setAlbumImg] = useState()
  const [loading, setLoading] = useState(false)
  const [songLoading, setSongLoading] = useState(false)
  const [recentLoading, setRecentLoading] = useState(false)
  const [albumSongs, setAlbumSongs] = useState([])
  const [songs, setSongs] = useState([])
  const [selectedSongs, setSelectedSongs] = useState([]);
  const [albumId, setAlbumId] = useState()
  const [albumSongTitle, setAlbumSongTitle] = useState()
  const [albumSongImage, setAlbumSongImage] = useState()
  const [albumSongAudio, setAlbumSongAudio] = useState()
  const [artistData, setArtistData] = useState({})
  const [open, setOpen] = useState(false);
  const [checkedSongs, setCheckedSongs] = useState([])
  const [songId, setSongId] = useState()
  const [clickedIndex, setClickedIndex] = useState()
  // const [modalOpen, setModalOpen] = useState(false)
  // const [minimized, setMinimized] = useState(false)

  const { tracks, setTracks, index, setIndex, modalOpen, setModalOpen, minimized1, setMinimized1 } = useContext(MusicPlayerContext)

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const closeAllUploadModals = () => {
    setUpload1(false)
    setUpload2(false)
    setUpload3(false)
    setUpload4(false)
    setUpload5(false)
    setUpload6(false)
    setUpload7(false)
    setUpload8(false)
    setOpen(false)
    setAudio()
    setAboutAlbum()
    setAlbumImg()
    setAlbumSongAudio()
    setAlbumSongImage()
    setAlbumSongs()
    setCheckedSongs()
    setPhoto()
    setSongId()
  }

  const continueAlbumAudioUpload = () =>{
    setOpen(false)
    setAlbumSongTitle("")
    setAlbumSongAudio()
  }

  const handleCheckboxChange = (id) => {
    setSongId(id)
  }

  const GetArtistData = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/artists/profile/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setArtistData(res?.data?.data);
        console.log(artistData + "artistData state===&&&");
        console.log(JSON.stringify(res.data) + "artistData====");
      });
  };
  
  const UploadExistingSongToAlbum = async() => {
    const payload = {
      song_id: songId
    }
    await axios.post(`https://api.2geda.net/api/stereo/artist/albums/${albumId}/add-existing-song/`, payload, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": 'multipart/form-data',
        "X-CSRFToken": process.env.REACT_TOKEN,
      },
    }).then(res=>{
      setLoading(false)
        // setAlbumId(res.data?.data?.id)
        console.log(res)
        if (res.status === 200) {
          setLoading(false)
          toast.success(res.data.message)
          onOpenModal()
          // setUpload1(false)
          // setUpload2(false)
          // setUpload3(false)
          // setUpload4(false)
          // setUpload5(false)
        } else {
          toast.error("Something went wrong")
          setLoading(false)
        }
      }).catch(err=>{
        console.log(err)
        setLoading(false)
      })
  }
  
  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedSongs((prevSelectedSongs) => [...prevSelectedSongs, file]);
  };
  
  // Remove a selected audio file
  const removeAudioFile = (index) => {
    setSelectedSongs((prevSelectedFiles) =>
      prevSelectedFiles.filter((_, i) => i !== index)
    );
  };


  const GetCategories = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/categories/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setCategory(res?.data?.data);
        console.log(category + "category state===&&&");
        console.log(JSON.stringify(res.data) + "category====");
      }).catch(e=>console.log(e));
  };


  const UploadSong = () => {
    setLoading(true)
    console.log(audio + categoryId +photo)
    const payload = {
      category_id: categoryId,
      cover_image: photo,
      audio_file: audio,
      title:song,
      // artist: artist
    }
    axios.post(`https://api.2geda.net/api/stereo/artist/songs/`, payload, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": 'multipart/form-data',
        "X-CSRFToken": process.env.REACT_TOKEN,
      },
    }).then(res=>{
      setLoading(true)
      if (res.status === 201) {
        toast.success(res.data.message)
        setLoading(false)
        setUpload1(false)
        setUpload2(false)
        // setUpload3(false)
        setUpload4(false)
        setUpload5(false)
        window.location.reload()
      } else {
        toast.error("Something went wrong")
        setLoading(false)
      }
      console.log(res)
    }).catch(e=>console.log(e))
  }

  const UploadAlbum = () => {
    setLoading(true)
    console.log(albumName + aboutAlbum + albumImg + JSON.stringify(selectedSongs))
    const payload = {
      name: albumName,
      about: aboutAlbum,
      cover_image: albumImg,
      // songs: selectedSongs.length>0?selectedSongs:checkedSongs
      // songs: albumSongs
    }
    axios.post(`https://api.2geda.net/api/stereo/artist/albums/`, payload, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": 'multipart/form-data',
        "X-CSRFToken": process.env.REACT_TOKEN,
      },
    }).then(res=>{
      setLoading(false)
      console.log(res)
      if (res.status === 201) {
        setLoading(false)
        toast.success(res.data.message)
        // setUpload1(false)
        // setUpload2(false)
        // setUpload3(false)
        // setUpload4(false)
        // setUpload5(false)
        setUpload3(!upload3)
        setUpload5(!upload5)
        window.location.reload()
      } else {
        toast.error("Something went wrong")
        setLoading(false)
      }
    }).catch(err=>{
      console.log(err)
      setLoading(false)
    })
  }

  const UploadAlbumSong = () => {
    setLoading(true)
    console.log(albumId, categoryId, albumImg, albumSongAudio)
    const payload = {
      category_id: categoryId,
      title: albumSongTitle,
      cover_image: albumImg,
      audio_file: albumSongAudio,
      // album_id: albumId
    }
    axios.post(`https://api.2geda.net/api/stereo/artist/albums/${albumId}/upload-song/`, payload, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": 'multipart/form-data',
        "X-CSRFToken": process.env.REACT_TOKEN,
      },
    }).then(res=>{
      setLoading(false)
      // setAlbumId(res.data?.data?.id)
      console.log(res)
      if (res.status === 200) {
        setLoading(false)
        toast.success(res.data.message)
        onOpenModal()
        // setUpload1(false)
        // setUpload2(false)
        // setUpload3(false)
        // setUpload4(false)
        // setUpload5(false)
      } else {
        toast.error("Something went wrong")
        setLoading(false)
      }
    }).catch(err=>{
      console.log(err)
      setLoading(false)
    })
  }


  const handleItemClick = (index, tracks) => {
    console.log('Clicked index:', index);
    // setCurrentSong(quickpicks[clickedIndex]);
    setIndex(index); // Set the clicked index to state
    setModalOpen(!modalOpen)
    setTracks(tracks)
  };
  

  const GetRecentUploads = () => {
    setRecentLoading(true)
    axios
      .get(`https://api.2geda.net/api/stereo/artist/songs/recent_upload/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setRecentLoading(false)
        setRecentUpload(res?.data?.data);
        console.log(recentUpload + "recent upload state===");
        console.log(JSON.stringify(res.data) + "recentUpload====");
      }).catch(e=>{setRecentLoading(false);console.log(e)});
  };

  useEffect(()=>{
    GetRecentUploads()
    GetCategories()
  },[])
  return (
    <>
      <UploadLayout2>
        <UploadSongHeader title={"Recent uploads"} />
        <div className="pt-20 sm:mt-0 bg-white">
          <div className="bg-white w-full pb-10 sm:mx-5">
            <div
              id="container"
              className="sm:flex hidden mx-3 pt-6 justify-between align-middle items-center bg-white">
              <div
                id="iconwrapper"
                className="flex align-middle items-center space-x-2">
                <button style={{ widows: 24, height: 24 }}>
                  <img src={Arrow} />
                </button>
                <span
                  style={{ fontSize: 20, fontWeight: "700" }}
                  fontSize={"20px"}>
                  Recent uploads
                </span>
              </div>
            </div>
            <section className="mx-5 relative my-4 mt-10">
              <input
                type="text"
                className="w-full bg-[#F5F5F5] py-2 rounded-xl pl-12 pr-2"
              />
              <img src={Search} className="absolute top-1 left-3" />
            </section>

            <section className="mx-5">
              {/* <RecentUploadCard />
              <RecentUploadCard />
              <RecentUploadCard />
              <RecentUploadCard />
              <RecentUploadCard />
              <RecentUploadCard />
              <RecentUploadCard /> */}
              {!recentLoading?recentUpload?.length>0?recentUpload?.map((res, index)=>{
                  return (
                    <RecentUploadCard onClick={()=>handleItemClick(index, recentUpload)} img={res.cover_image} title={res.title} artist={res?.artist.artist_name} plays={res?.plays.toString()} likes={res?.likes.toString()} downloads={res?.downloads.toString()} entries={artistData?.playlists?.length} />
                  )
                }):<div className="flex justify-center items-center">
                <span>Nothing here</span>
              </div>:<div className="flex justify-center items-center"><Lottie
                animationData={NothingHere}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}

            </section>
            {/* ad */}
            <div className="mt-4 mx-5">
              <img
                src={Ad}
                alt="Advertisement"
                style={{
                  height: "80px",
                  objectFit: "cover",
                  width: "100%",
                }}
              />
            </div>
          </div>

        </div>
        <div class="fixed bottom-40 right-4 flex lg:hidden xl:hidden">
    <button onClick={()=>setUpload1(!upload1)} class="bg-[#FF8A15] hover:bg-orange-600 text-white font-bold py-3 px-4 rounded-full shadow-lg">
      
      <span className="text-4xl font-light text-white">+</span>
    </button>
  </div>

  {/* upload modal */}
  <ModalWrapper2 header={false} isOpen={upload1} closeModal={() => setUpload1(!upload1)}>
        <div className="flex items-center justify-between px-2 py-4">
          <span className="font-bold text-xl text-black">Upload</span>
          <button onClick={()=>{setUpload1(!upload1); closeAllUploadModals()}} className="w-[16] h-[16]"><HiXMark size={16}/></button>
        </div>
        <div className="flex flex-col px-2 mt-4 mb-2 pb-10 gap-2">
          <span className="text-base font-normal">Select a mode</span>
          <span className="text-sm font-light">Choose between uploading a single or an entire album, and let your creativity flow.</span>
          <div className="mt-3 flex justify-between items-center">
          <main className="flex flex-col gap-2">
            <span className="text-base font-normal">Single</span>
            <span className="text-base font-normal">Album/EP</span>
            {/* <input type="radio" name="upload" value={"Single"} className="flex items-end justify-end"/> */}
          </main>
          <main className="flex flex-col gap-2">
            <input type="radio" name="upload" value={"Single"} className="flex items-end justify-end" onChange={()=>{setUpload2(true); setUpload1(false); setUploadRadio("Single")}}/>
            <input type="radio" name="upload" value={"Album"} className="flex items-end justify-end" onChange={()=>{setUpload5(true); setUpload1(false); setUploadRadio("Album")}}/>
          </main>
        </div>
        </div>
        
      </ModalWrapper2>
      <ModalWrapper2 header={false} isOpen={upload2} closeModal={() => setUpload2(!upload2)}>
      <div className="flex items-center justify-between px-2 py-4">
        <div className="flex gap-2 items-center">
          <button className="h-[24] w-[24]" onClick={()=>{setUpload2(!upload2); setUpload1(!upload1)}}>
            <BiArrowBack size={24}/>
          </button>
          <span className="font-bold text-xl text-black">Upload</span>
          </div>
          <button onClick={()=>{setUpload1(!upload1); closeAllUploadModals()}} className="w-[16] h-[16]"><HiXMark size={16}/></button>
        </div>
        <div className="px-2 flex flex-col gap-2 mt-3">
          <span className="text-base font-normal">Select music file</span>
          <span className="text-base font-light">Choose a music file you want to upload. Supported file types include MP3, WAV, and M4A. </span>
        </div>
        <div>
        <div class="relative mx-2 my-4">
    <label title="Click to upload" for="button2" class="cursor-pointer flex flex-col items-center justify-center gap-4 px-6 py-4 before:border-gray-400/60 hover:before:border-gray-300 group before:bg-gray-100 before:absolute before:inset-0 before:rounded-3xl before:border before:border-dashed before:transition-transform before:duration-300 active:duration-75 active:before:scale-95">
      
      <div className="relative">
        <div className="flex flex-col justify-center items-center">
        {audio?<audio controls>
            <source src={URL.createObjectURL(audio)} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>:<div className="rounded-full mb-4 bg-[#FF8A15] flex justify-center items-center w-[62px] h-[62px]">
        <span className="text-4xl font-light text-white">+</span>
        </div>}
        <span className="font-medium text-sm">Tap here to select a file</span>
        </div>
      </div>
     </label>
    <input hidden="" type="file" ref={fileInput} name="button2" id="button2" onChange={(e)=>setAudio(e.target.files[0])}/>
</div>
        </div>
        <div className="flex justify-center items-center pb-4">
        <button onClick={()=>{setUpload2(!upload2); setUpload4(!upload4)}} className="bg-[#4F0DA3] rounded-md py-2 w-1/2 text-white">Next</button>
        </div>
      </ModalWrapper2>

      <ModalWrapper2 header={false} isOpen={upload4} closeModal={() => setUpload4(!upload4)}>
      <div className="flex items-center justify-between px-2 py-4">
        <div className="flex gap-2 items-center">
          <button className="h-[24] w-[24]" onClick={()=>{setUpload4(!upload4); setUpload1(!upload1)}}>
            <BiArrowBack size={24}/>
          </button>
          <span className="font-bold text-xl text-black">Upload</span>
          </div>
          <button onClick={()=>{setUpload4(!upload4); closeAllUploadModals()}} className="w-[16] h-[16]"><HiXMark size={16}/></button>
        </div>
        <div className="flex flex-col items-center justify-center mt-8 gap-3">
          <span className="font-medium text-base">Your song has been uploaded!</span>
          <span className="font-medium text-sm text-gray-400">Follow these steps to complete your upload</span>
        </div>

        <div className="flex flex-col items-center justify-center px-10 mt-5">
          <div class="file-upload-content">
    <label>Profile Picture</label>
    <div class="form-group custom-drop-file text-center mb-3">
      <input type="file" class="form-control" id="img-upload" placeholder="Upload a picture" onChange={(e)=>setPhoto(e.target.files[0])}/>
      <main className="flex flex-col justify-center items-center">
      {photo?<img src={URL.createObjectURL(photo)}/>:<div className="rounded-full mb-4 bg-[#FF8A15] flex justify-center items-center w-[62px] h-[62px]">
          <span className="text-4xl font-light text-white">+</span>
        </div>}
        <span className="font-medium text-sm">Tap here to select a file</span>
        </main>
      {/* <p>Upload Picture</p> */}
    </div>
  </div>
        <input
        onChange={(e)=>setArtist(e.target.value)}
                  type="text"
                  placeholder="Artist Name"
                  className="max-w-[351px] px-3 mb-2 text-black rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]"
                />
                <input
                onChange={(e)=>setSong(e.target.value)}
                  type="text"
                  placeholder="Song Title"
                  className="max-w-[351px] px-3 mb-2 text-black rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]"
                />
                
                <select value={categoryId} onChange={(e)=>setCategoryId(e.target.value)} placeholder={"Category"} className="max-w-[351px] px-3 text-black mb-2 rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]">
                  {category.map(res=>{
                    return(
                    <option className="text-black" value={res.id}>{res.name}</option>
                    )
                  })}
                </select>
        </div>
        <div className="flex justify-center items-center pb-4 mt-5">
        {loading ?<Lottie
              animationData={preloader}
              style={{
                width: "300px",
                height: "100px",
              }}
            />:<button onClick={()=>UploadSong()} className="bg-[#4F0DA3] rounded-md py-2 w-1/2 text-white">Upload</button>}
        </div>
      </ModalWrapper2>

      {/* upload album */}

      <ModalWrapper2 header={false} isOpen={upload3} closeModal={() => setUpload3(!upload3)}>
      <div className="flex items-center justify-between px-2 py-4">
        <div className="flex gap-2 items-center">
          <button className="h-[24] w-[24]" onClick={()=>{setUpload3(!upload3); setUpload1(!upload1)}}>
            <BiArrowBack size={24}/>
          </button>
          <span className="font-bold text-xl text-black">Upload</span>
          </div>
          <button onClick={()=>{setUpload3(!upload3); setUpload1(!upload1); closeAllUploadModals()}} className="w-[16] h-[16]"><HiXMark size={16}/></button>
        </div>
        
        <div className="flex flex-col justify-center items-center text-center gap-4 mx-2">
          <span className="text-2xl font-medium">Before you upload.</span>
          <span className="text-xl font-normal text-[#403F3F]">Should this album include any music you’ve already uploaded?</span>
        </div>

        <div className="flex flex-col justify-center items-center my-5">
          <button onClick={()=>{setUpload6(!upload6); setUpload3(!upload3)}} className="font-normal rounded-md text-sm px-4 py-2 text-white bg-[#4F0DA3]">Yes, add songs</button>
          <button onClick={()=>{setUpload8(!upload8); setUpload3(!upload3)}} className="font-normal rounded-md text-sm px-4 py-2 text-black border mt-3 mb-3 border-[#4F0DA3]">No, start upload</button>
        </div>
    
        
      </ModalWrapper2>

      <ModalWrapper2 header={false} isOpen={upload6} closeModal={() => setUpload6(!upload6)}>
      <div className="flex items-center justify-between px-2 py-4">
        <div className="flex gap-2 items-center">
          <button className="h-[24] w-[24]" onClick={()=>{setUpload6(!upload6); setUpload3(!upload3)}}>
            <BiArrowBack size={24}/>
          </button>
          <span className="font-bold text-xl text-black">Add existing songs</span>
          </div>
          <button onClick={()=>{setUpload6(!upload6); setUpload3(!upload3); closeAllUploadModals()}} className="w-[16] h-[16]"><HiXMark size={16}/></button>
        </div>
        
        <div className="mx-3">
          <span className="text-sm font-normal text-[#403F3F]">Select your pre-existing songs to add to this album</span>
        </div>
        {/* {JSON.stringify(selectedSongs)}
        {selectedSongs.map(res=>{
          return <p>{res?.title}</p>
        })} */}

        <div className="relative mx-3 mt-3 mb-3">
        <input
          type="text"
          className="w-full bg-[#F5F5F5] py-2 rounded-3xl pl-10"
        />
        <img src={Search} className="absolute top-2 left-2" />
      </div>

      <div className="mx-3">
      {songs?.length>0?songs?.map(res=>{
                  return (
                  <SongListModalCard onCheckboxChange={()=>handleCheckboxChange(res.id)} title={res.title} img={res.cover_image} artist={res?.artist.artist_name} audio={res.audio_file?res.audio_file:null} value={res.id}/>
                  )
                }):<div className="flex justify-center items-center"><Lottie
                animationData={preloader}
                style={{
                  width: "263.38px",
                  height: "100%",
                }}
              /></div>}
        {/* <SongListModalCard/> */}
      </div>
      <div className="flex justify-center items-center">
        <button onClick={()=>{setUpload6(!upload6); setUpload5(!upload5)}} className="text-sm font-normal bg-[#4F0DA3] text-[#F5F5F5] px-9 rounded-md py-3">Add {selectedSongs.length} track(s)</button>
      </div>
        
      </ModalWrapper2>

      <ModalWrapper2 header={false} isOpen={upload7} closeModal={() => setUpload7(!upload7)}>
      <div className="flex items-center justify-between px-2 py-4">
        <div className="flex gap-2 items-center">
          <button className="h-[24] w-[24]" onClick={()=>{setUpload7(!upload7); setUpload3(!upload3)}}>
            <BiArrowBack size={24}/>
          </button>
          <span className="font-bold text-xl text-black">Upload</span>
          </div>
          <button onClick={()=>{setUpload3(!upload3); setUpload7(!upload7); closeAllUploadModals()}} className="w-[16] h-[16]"><HiXMark size={16}/></button>
        </div>
        <div className="flex flex-col items-start justify-start mt-8 gap-3 mx-3">
          <span className="font-medium text-base">Select music file</span>
          <span className="font-medium text-sm text-gray-400">Choose a music file you want to upload. Supported file types include MP3, WAV, and M4A. </span>
        </div>

        <div className="flex flex-col items-center justify-center px-10 mt-5">
          <div class="file-upload-content w-full">
    {/* <label>Profile Picture</label> */}
    <div class="form-group custom-drop-file text-center mb-3 w-full">
      <input type="file" class="form-control" id="img-upload" placeholder="Upload a picture" accept="audio/*" multiple onChange={handleFileChange}/>
      <main className="flex flex-col justify-center items-center">
        <div className="rounded-full mb-4 bg-[#FF8A15] flex justify-center items-center w-[62px] h-[62px]">
          <span className="text-4xl font-light text-white">+</span>
        </div>
        <span className="font-medium text-sm">Tap here to select a file</span>
        </main>
      {/* <p>Upload Picture</p> */}
    </div>
  </div>
  </div>
  {selectedSongs.map((file, index) => (
        <div key={index} className="flex justify-between items-center mx-3 my-4">
          <div>
          <h5 className="mb-2">{file.name}</h5>
          
          <audio controls>
            <source src={URL.createObjectURL(file)} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          </div>
          <button onClick={() => removeAudioFile(index)}>Remove</button>
        </div>
      ))}

      <div className="flex justify-center items-center">
        <button onClick={()=>{setUpload7(!upload7); setUpload5(!upload5)}} className="py-3 px-14 bg-[#4F0DA3] text-sm text-gray-400 font-normal">Upload</button>
      </div>
  </ModalWrapper2>

  <ModalWrapper2 header={false} isOpen={upload8} closeModal={() => setUpload8(!upload8)}>
      <div className="flex items-center justify-between px-2 py-4">
        <div className="flex gap-2 items-center">
          <button className="h-[24] w-[24]" onClick={()=>{setUpload8(!upload8); setUpload3(!upload3)}}>
            <BiArrowBack size={24}/>
          </button>
          <span className="font-bold text-xl text-black">Upload</span>
          </div>
          <button onClick={()=>{setUpload8(!upload8); setUpload3(!upload3); closeAllUploadModals()}} className="w-[16] h-[16]"><HiXMark size={16}/></button>
        </div>
        <div className="px-2 flex flex-col gap-2 mt-3">
          <span className="text-base font-normal">Select music file</span>
          <span className="text-base font-light">Choose a music file you want to upload. Supported file types include MP3, WAV, and M4A. </span>
        </div>
        <div>
        <div class="relative mx-3 my-4">
    <label title="Click to upload" for="button2" class="cursor-pointer flex flex-col items-center justify-center gap-4 px-6 py-4 before:border-gray-400/60 hover:before:border-gray-300 group before:bg-gray-100 before:absolute before:inset-0 before:rounded-3xl before:border before:border-dashed before:transition-transform before:duration-300 active:duration-75 active:before:scale-95">
      
      <div className="relative">
        <div className="flex flex-col justify-center items-center">
        {albumSongAudio?<audio controls>
            <source src={URL.createObjectURL(albumSongAudio)} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>:<div className="rounded-full mb-4 bg-[#FF8A15] flex justify-center items-center w-[62px] h-[62px]">
          <span className="text-4xl font-light text-white">+</span>
        </div>}
        <span className="font-medium text-sm">Tap here to select a file</span>
        </div>
      </div>
     </label>
    <input hidden="" type="file" ref={fileInput} name="button2" id="button2" onChange={(e)=>setAlbumSongAudio(e.target.files[0])}/>
{/* </div> */}
{/* </div> */}
        </div>
        {/* <input
        onChange={(e)=>setArtist(e.target.value)}
                  type="text"
                  placeholder="Artist Name"
                  className="max-w-[351px] px-3 mb-2 text-black rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]"
                /> */}
                <div className="w-auto">

                <input
                onChange={(e)=>setAlbumSongTitle(e.target.value)}
                  type="text"
                  placeholder="Song Title"
                  className="mx-3 mb-2 text-black rounded-lg py-3 border border-[rgba(40, 40, 40, 0.15)]"
                />
                
                <select value={categoryId} onChange={(e)=>setCategoryId(e.target.value)} placeholder={"Category"} className="mx-3 text-black mb-2 rounded-lg py-3 border border-[rgba(40, 40, 40, 0.15)]">
                  {category.map(res=>{
                    return(
                    <option className="text-black" value={res.id}>{res.name}</option>
                    )
                  })}
                </select>
                </div>
        </div>
        <div className="flex justify-center items-center pb-4 mt-5">
        {loading ?<Lottie
              animationData={preloader}
              style={{
                width: "300px",
                height: "100px",
              }}
            />:<button onClick={()=>UploadAlbumSong()} className="bg-[#4F0DA3] rounded-md py-2 w-1/2 text-white">Upload Song</button>}
        </div>
      </ModalWrapper2>

      <ModalWrapper2 header={false} isOpen={upload5} closeModal={() => setUpload5(!upload5)}>
      <div className="flex items-center justify-between px-2 py-4">
        <div className="flex gap-2 items-center">
          <button className="h-[24] w-[24]" onClick={()=>{setUpload5(!upload5); setUpload1(!upload1)}}>
            <BiArrowBack size={24}/>
          </button>
          <span className="font-bold text-xl text-black">Upload</span>
          </div>
          <button onClick={()=>{setUpload1(!upload1); setUpload5(!upload5); closeAllUploadModals()}} className="w-[16] h-[16]"><HiXMark size={16}/></button>
        </div>
        <div className="flex flex-col items-center justify-center mt-8 gap-3">
          <span className="font-medium text-base">Your songs has been uploaded!</span>
          <span className="font-medium text-sm text-gray-400">Follow these steps to complete your upload</span>
        </div>

        <div className="flex flex-col items-center justify-center px-10 mt-5">
          <div class="file-upload-content">
    <label>Profile Picture</label>
    <div class="form-group custom-drop-file text-center mb-3">
      <input type="file" class="form-control" id="img-upload" placeholder="Upload a picture" onChange={(e)=>setAlbumImg(e.target.files[0])}/>
      <main className="flex flex-col justify-center items-center">
        {albumImg?<img src={URL.createObjectURL(albumImg)}/>:<div className="rounded-full mb-4 bg-[#FF8A15] flex justify-center items-center w-[62px] h-[62px]">
          <span className="text-4xl font-light text-white">+</span>
        </div>}
        <span className="font-medium text-sm">Tap here to select a file</span>
        </main>
      {/* <p>Upload Picture</p> */}
    </div>
  </div>
        <input
        onChange={(e)=>setAlbumName(e.target.value)}
                  type="text"
                  placeholder="Album Name"
                  className="max-w-[351px] px-3 mb-2 text-black rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]"
                />
                <input
                onChange={(e)=>setAboutAlbum(e.target.value)}
                  type="text"
                  placeholder="About Album"
                  className="max-w-[351px] px-3 mb-2 text-black rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]"
                />
                
                {/* <select value={categoryId} onChange={(e)=>setCategoryId(e.target.value)} placeholder={"Category"} className="max-w-[351px] px-3 text-black mb-2 rounded-lg py-3 w-full border border-[rgba(40, 40, 40, 0.15)]">
                  {category.map(res=>{
                    return(
                    <option className="text-black" value={res.id}>{res.name}</option>
                    )
                  })}
                </select> */}
        </div>
        <div className="flex justify-center items-center pb-4 mt-5">
        {loading ? <Lottie
              animationData={preloader}
              style={{
                width: "300px",
                height: "100px",
              }}
            />:<button onClick={()=>UploadAlbum()} className="bg-[#4F0DA3] rounded-md py-2 w-1/2 text-white">Upload</button>}
        </div>

        
      </ModalWrapper2>
      <Modal open={open} onClose={onCloseModal} center styles={{zIndex: 3000}}>
        <main className="flex flex-col justify-center items-center text-center mt-10 gap-3">
        <span className="text-2xl font-medium">Song added successfully</span>
        <span className="text-lg font-normal text-gray-600">Would you like to upload another song to your album?</span>
        </main>
        <main className="flex flex-row justify-center items-center gap-4 mt-4">
        <button className="bg-[#4F0DA3] py-3 px-10 text-gray-300 rounded-md" onClick={()=>continueAlbumAudioUpload()}>
        Yes
        </button>
        <button className="bg-red-500 py-3 px-10 text-gray-300 rounded-md" onClick={()=>closeAllUploadModals()}>No</button>
        </main>
      </Modal>
      {/* {modalOpen &&<AudioPlayer2 minimized1={minimized} setMinimized1={setMinimized} setModalOpen={setModalOpen} modalOpen={modalOpen} tracks={recentUpload} index={clickedIndex}/>} */}
      </UploadLayout2>
    </>
  );
}
