import { useQuery } from "@tanstack/react-query";
import { getChannelStats} from "api/services/tv";

export const useGetChannelStats = (id) => {
  const {
    isLoading: isChannelStatsLoading,
    data: channelStats,
    error: channelStatsError,
  } = useQuery({
    queryKey: ["channelStats", id],
    queryFn: () => getChannelStats(id),
    refetchOnWindowFocus: false,
    refetchOnMount: true, 
    refetchOnReconnect: false,
  });

  return {
    isChannelStatsLoading,
    channelStats,
    channelStatsError,
  };
};
