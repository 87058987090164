import moment from "moment";
import { Link } from "react-router-dom";
//import logo from "../../assets/logo.png";

const trimString = (str) => {
  return str?.slice(0, 7);
};

const trimTitle = (str) => {
  if (str?.length > 16) {
    return str?.slice(0, 16) + "...";
  }
  return str;
};

const demoImage =
  "https://img.freepik.com/free-photo/restaurant-hall-with-tables-decorated-with-tall-vases-with-roses_1304-4823.jpg";

const EventCard = ({ data }) => {
  return (
    <div className="p-2 grid gap-4 grid-flow-col lg:auto-cols-[28%] md:auto-cols-[40%] auto-cols-[80%] overflow-x-scroll overscroll-contain">
      {data?.map((item) => (
        <div key={item.id} className="h-[230px] shadow-md rounded py-2">
          <div className="h-[50%] w-full relative">
            <img
              src={item?.event_image ? item?.event_image : demoImage}
              alt={item?.title}
              className="w-full h-full object-cover rounded"
            />
            {/* <div className="w-[30px] h-[30px] absolute left-2 top-2">
              <img
                src={logo}
                alt="logo"
                className="w-full h-full object-cover rounded"
              />
            </div> */}
          </div>

          <div className="mt-2 px-2">
            <h4>{trimTitle(item?.title)}</h4>
            <p className="text-neutral-400">
              {moment(item?.start_date).format("DD MMM, YYYY")},{" "}
              {trimString(item?.venue_name)}...
            </p>

            <div className="w-full flex justify-center">
              <Link
                to={`/ticket/event/${item?.id}`}
                className="w-[60%] py-[9px] text-[#fff] text-[13px] rounded bg-[#4f0da3] hover:opacity-90 text-center  no-underline"
              >
                Get Ticket
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EventCard;
