import React, { useState, useEffect, useRef, useContext } from "react";
import AudioControls from "./AudioControls";
// import Backdrop from "./Backdrop";
import "./styles.css";
import "../Components/player.css"
import "../Components/style.css"
import { BiChevronDown } from "react-icons/bi";
import PlayerVideo from "../Assets/stereo_anim.mp4"
import { HiXMark } from "react-icons/hi2";
import { useStore } from "zustand";
import { audioPlayerStore } from "../../../zustand/store";
import { MusicPlayerContext } from "../context/MusicPlayerContext";
import axios from "axios";


const AudioPlayer2 = () => {
  // State
  const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loop, setLoop] = useState(false)
  const [totalDuration,setTotalDuration] = useState("0:00")
  const [currentDuration, setCurrentDuration] = useState("0:00")
  const setCurrentInstance = useStore(audioPlayerStore, (state)=>state.setCurrentInstance)
  const { tracks, setTracks, index, modalOpen, setModalOpen, minimized1, setMinimized1 } = useContext(MusicPlayerContext)
  const [trackIndex, setTrackIndex] = useState(index);
  const authToken = localStorage.getItem("authToken")
  const [plays, setPlays] = useState([])


  console.log(tracks, "current Track===")
  // Destructure for conciseness
  // const { title, artist, cover_image, audio_file } = tracks[trackIndex];
  const track = tracks[trackIndex];
const { title, artist, cover_image, audio_file, id } = track ? track : { title: '', artist: '', cover_image: '', audio_file: '' };
  console.log(audio_file, "audio_file===")
  const audioCache = new Map()

  const loadAudio = (url) => {
    if (audioCache.has(url)){
      return audioCache.get(url)
    }
    const audiou = new Audio(url)
    audioCache.set(url, audiou)
    return audiou
  }
  console.log(loadAudio(audio_file), "loadaudio===")

  // Refs
  const audioRef = useRef(loadAudio(audio_file));
  const intervalRef = useRef();
  const isReady = useRef(false);

  // Repeat and shuffle
  const [shuffle, setShuffle] = useState(false);
const [repeat, setRepeat] = useState(false);

const toNextTrack = () => {
  if (shuffle) {
    let nextTrackIndex;
    do {
      nextTrackIndex = Math.floor(Math.random() * tracks.length);
    } while (nextTrackIndex === trackIndex);
    setTrackIndex(nextTrackIndex);
  } else if (trackIndex < tracks.length - 1) {
    setTrackIndex(trackIndex + 1);
  } else if (repeat) {
    setTrackIndex(0);
  }
};

const toPrevTrack = () => {
  if (shuffle) {
    let prevTrackIndex;
    do {
      prevTrackIndex = Math.floor(Math.random() * tracks.length);
    } while (prevTrackIndex === trackIndex);
    setTrackIndex(prevTrackIndex);
  } else if (trackIndex > 0) {
    setTrackIndex(trackIndex - 1);
  } else if (repeat) {
    setTrackIndex(tracks.length - 1);
  }
};

const onScrub = (value) => {
  clearInterval(intervalRef.current);
  audioRef.current.currentTime = value;
  setTrackProgress(audioRef.current.currentTime);
  const minutes = Math.floor(audioRef.current.currentTime / 60);
  const seconds = Math.floor(audioRef.current.currentTime % 60);
  setCurrentDuration(`${minutes}:${seconds.toString().padStart(2, '0')}`);
};

const onScrubEnd = () => {
  if (!isPlaying) {
    setIsPlaying(true);
  }
  startTimer();
};

const toggleShuffle = () => {
  setShuffle(!shuffle);
};

const toggleRepeat = () => {
  setRepeat(!repeat);
};

  // Main Duration
  audioRef.current.onloadedmetadata = () => {
    const duration = audioRef.current.duration;
    const currentTime = audioRef.current.currentTime;
    const minutes = Math.floor(duration / 60);
    const minutes2 = Math.floor(currentTime / 60);
    const seconds = Math.floor(duration % 60);
    const seconds2 = Math.floor(currentTime % 60);
    setTotalDuration(`${minutes}:${seconds.toString().padStart(2, '0')}`);
    setCurrentDuration(`${minutes2}:${seconds2.toString().padStart(2, '0')}`);
};

// Current Time
// audioRef.current.onloadedmetadata = () => {
//   const currentTime = audioRef.current.currentTime;
//   const minutes2 = Math.floor(currentTime / 60);
//   const seconds2 = Math.floor(currentTime % 60);
//   setCurrentDuration(`${minutes2}:${seconds2.toString().padStart(2, '0')}`);
// };

  // Destructure for conciseness
  const { duration } = audioRef.current;

  const currentPercentage = duration
    ? `${(trackProgress / duration) * 100}%`
    : "0%";
  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
  `;

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        toNextTrack();
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  };
  const toggleLoop = () => {
    setLoop(!loop);
  };
  useEffect(() => {
    audioRef.current.loop = loop; // Set the loop property based on the state
  }, [loop]);

  // const onScrub = (value) => {
  //   // Clear any timers already running
  //   clearInterval(intervalRef.current);
  //   audioRef.current.currentTime = value;
  //   setTrackProgress(audioRef.current.currentTime);
  // };

  // const onScrubEnd = () => {
  //   // If not already playing, start
  //   if (!isPlaying) {
  //     setIsPlaying(true);
  //   }
  //   startTimer();
  // };

  // const toPrevTrack = () => {
  //   if (trackIndex - 1 < 0) {
  //     setTrackIndex(tracks.length - 1);
  //   } else {
  //     setTrackIndex(trackIndex - 1);
  //   }
  // };

  // const toNextTrack = () => {
  //   if (trackIndex < tracks.length - 1) {
  //     setTrackIndex(trackIndex + 1);
  //   } else {
  //     setTrackIndex(0);
  //   }
  // };

  useEffect(() => {
    
    if (isPlaying) {
      audioRef.current.play();
      setCurrentInstance(audioRef.current)
      startTimer();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  // useEffect(()=>{
  // if(!modalOpen){
  // audioRef.current.pause()
  // } else {
  // audioRef.current.play()
  // }
  // },[modalOpen])
  // Handles cleanup and setup when changing tracks
  const GetPlays = () => {
    axios
      .get(`https://api.2geda.net/api/stereo/songs/${id}/play/`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-CSRFToken": process.env.REACT_TOKEN,
        },
      })
      .then((res) => {
        setPlays(res?.data?.data);
        console.log(plays + "plays state===");
        console.log(JSON.stringify(res.data) + "plays====");
      }).catch(e=>console.log(e));
  };
  
  useEffect(() => {
    GetPlays()
    if (audioRef.current) {
      audioRef.current.pause();
    }
    

    audioRef.current = loadAudio(audio_file);
    setTrackProgress(audioRef.current.currentTime);

    if (isReady.current) {
      audioRef.current.play();
      setIsPlaying(true);
      startTimer();
    } else {
      // Set the isReady ref as true for the next pass
      isReady.current = true;
    }
  }, [trackIndex]);

  useEffect(() => {
    const updateTrackProgress = () => {
      if (audioRef.current.currentTime !== trackProgress) {
        setTrackProgress(audioRef.current.currentTime);
        const minutes = Math.floor(audioRef.current.currentTime / 60);
        const seconds = Math.floor(audioRef.current.currentTime % 60);
        setCurrentDuration(`${minutes}:${seconds.toString().padStart(2, '0')}`);
      }
    };
  
    // Call updateTrackProgress inside the interval
    const intervalId = setInterval(updateTrackProgress, 1000);
  
    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [isPlaying]); // Dependency array includes isPlaying
  
useEffect(() => {
  if (modalOpen === false) {
    setTracks([])
    audioRef.current.pause()
  }
}, [modalOpen, setModalOpen]);

  // useEffect(() => {
  //   // Pause and clean up on unmount
  //   return () => {
  //     audioRef.current.pause();
  //     clearInterval(intervalRef.current);
  //     console.log(audioRef, "hollo ref")
  //   };
    
  // }, []);

  return (
    <div>
      {modalOpen && (
          <div
          id="modelConfirm"
          className={!minimized1 ? "fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4":""}>
          <div className={!minimized1 ? "relative top-40 mx-auto shadow-xl bg-white max-w-[100%] lg:max-w-[30%] md:max-w-[30%] xl:max-w-[30%] rounded-xl overflow-x-hidden": "fixed bottom-5 lg:left-[30%] lg:right-[30%] xl:left-[30%] xl:right-[30%] left-0 right-0 z-50 w-full lg:w-[50%] xl:w-[50%] h-80 bg-white border-t border-gray-200"}>
             <main className="py-10 my-10">
    
    <div className="audio-playe">
      {!minimized1 ? <div className="track-inf">
        {/* <img
          className="artwork"
          src={`https://development.2geda.net${cover_image}`}
          alt={`track artwork for ${title} by ${artist.artist_name}`}
        /> */}

<div className='flex justify-start mb-10'>
            <button className='w-[24] h-[24]' 
            // onClick={()=>setModalOpen1(!modalOpen1)}
            onClick = {()=>{setMinimized1(!minimized1)}}
            ><BiChevronDown size={24} color='black'/></button>
            {/* <button className='w-[24] h-[24]'><MdMoreVert size={24}/></button> */}
            {/* <button className='w-[24] h-[24]' 
            // onClick={()=>setModalOpen1(!modalOpen1)}
            onClick = {()=>{setModalOpen(!modalOpen)}}
            ><HiXMark size={24} color='black'/></button>  */}
        </div>
        <div className="img-wra relative w-full h-[200px] overflow-hidden">
          {cover_image || cover_image != null ? <img src={cover_image} alt={title} className='w-full h-full' />:<video id="myVideo" width="100%" height="100%" autoPlay muted className='w-full h-full'>
      <source src={PlayerVideo} type="video/mp4" />
    </video>}
        </div>
    
        {/* <h2 className="title">{title}</h2>
        <h3 className="artist">{artist.artist_name}</h3> */}
        <div className='flex justify-start'>
            <div className='flex flex-col mx-2'>
        <span className="song-name text-black">{title?title:"UNKNOWN SONG"}</span>
        <span className="song-autor">{artist?.artist_name?artist?.artist_name:"UNKNOWN ARTIST"}</span>
        </div>
        </div>
        <main style={{backgroundImage: "linear-gradient(#4F0DA3, #FF8A15);" }} className='bg-down rounded-t-3xl px-4 pt-3 pb-3 mt-4 w-full'>
        {/* <input
          type="range"
          value={trackProgress}
          step="1"
          min="0"
          max={duration ? duration : `${duration}`}
          className="h-[0.1px] max-h-[0.1px]"
          onChange={(e) => onScrub(e.target.value)}
          onMouseUp={onScrubEnd}
          onKeyUp={onScrubEnd}
          style={{ background: currentPercentage>0?"blue":"#D0D5DD", height: "0.5px" }}
        /> */}
        <div className="h-[5px] bg-gray-400 w-full mt-5 mt-2 rounded-md">

<div
  className="h-[5px] bg-gray-200"
  onChange={(e) => onScrub(e.target.value)}
  style={{ width: `${(trackProgress / duration) * 100}%` }}
/>
</div>
<div className="flex items-center justify-between mt-2 mb-5">
  <span className="text-white">{currentDuration}</span>
  <span className="text-white">{totalDuration}</span>
</div>
        {/* // <p>{duration}</p> */}
        {/* <div className="navigation my-4">
  <div className="navigation_wrapper time" onClick={onScrubEnd} onMouseMove={onScrub}>
    <div className="seek_bar end-time text-white" style={{ width: `${(trackProgress / duration) * 100}%` }}></div>
  </div>
</div> */}


        <AudioControls
          isPlaying={isPlaying}
          onPrevClick={toPrevTrack}
          onNextClick={toNextTrack}
          minimized1 = {minimized1}
          onPlayPauseClick={setIsPlaying}
          loopfunc={toggleLoop}
          shuffleFunc={toggleShuffle}
          shuffle={shuffle}
          loop={loop}
        />
        </main>
        
      </div>: <div>
        <main className="flex justify-between">
          <div className="mx-2">
          <span className="song-name text-black">{title?title:"UNKNOWN SONG"}</span><br/>
          <span className="song-autor">{artist?.artist_name?artist?.artist_name:"UNKNOWN ARTIST"}</span>
          </div>
          <button className='w-[24] h-[24]' 
            // onClick={()=>setModalOpen1(!modalOpen1)}
            onClick = {()=>{setModalOpen(!modalOpen); audioRef.current.pause()}}
            ><HiXMark size={24} color='black'/></button>
        </main>
           
      <div className="h-[5px] bg-gray-400 w-auto mt-2 rounded-md ml-2 mr-2">
<div
  className="h-[5px] bg-gray-200 rounded-md"
  style={{ width: `${(trackProgress / duration) * 100}%` }}
/>
</div>
<div className="flex items-center justify-between mt-2 mb-2 mx-2">
  <span className="text-black">{currentDuration}</span>
  <span className="text-black">{totalDuration}</span>
</div>
      <AudioControls
          isPlaying={isPlaying}
          onPrevClick={toPrevTrack}
          onNextClick={toNextTrack}
          onPlayPauseClick={setIsPlaying}
          minimized1 = {minimized1}
          loopfunc={toggleLoop}
          shuffleFunc={toggleShuffle}
        shuffle={shuffle}
        loop={loop}
        />
        </div>}
      {/* <Backdrop
        trackIndex={trackIndex}
        activeColor={color}
        isPlaying={isPlaying}
      /> */}
    </div>
    </main>
            </div>
          </div>
        )}
    </div>
  );
};

export default AudioPlayer2;
